import React from "react";
import Login from "../components/Common/Login";


const LoginPage = () => {
 

  return (
    <React.Fragment>
      <div>
        <Login />
      </div>
    </React.Fragment>
  );
};

export default LoginPage;
