import {
  COMPANY_DETAIL_LIST_FAILURE,
  COMPANY_DETAIL_LIST_REQUEST,
  COMPANY_DETAIL_LIST_SUCCESS,
  META_LIST_FAILURE,
  META_LIST_REQUEST,
  META_LIST_SUCCESS,
  SOCIAL_LIST_FAILURE,
  SOCIAL_LIST_REQUEST,
  SOCIAL_LIST_SUCCESS,
} from "../Constatnts/MetaConstant";

export function SocialListReducer(state = {}, action) {
  switch (action.type) {
    case SOCIAL_LIST_REQUEST:
      return {
        loading: true,
      };
    case SOCIAL_LIST_SUCCESS:
      return {
        loading: false,
        socialListData: action.payload,
      };
    case SOCIAL_LIST_FAILURE:
      return {
        loading: false,
      };
    default:
      return state;
  }
}

export function CompanyDetailListReducer(state = {}, action) {
  switch (action.type) {
    case COMPANY_DETAIL_LIST_REQUEST:
      return {
        loading: true,
      };
    case COMPANY_DETAIL_LIST_SUCCESS:
      return {
        loading: false,
        companyDetailListData: action.payload,
      };
    case COMPANY_DETAIL_LIST_FAILURE:
      return {
        loading: false,
      };
    default:
      return state;
  }
}

export function MetaReducer(state = {}, action) {
  switch (action.type) {
    case META_LIST_REQUEST:
      return {
        loading: true,
      };
    case META_LIST_SUCCESS:
      return {
        loading: false,
        metaListData: action.payload,
      };
    case META_LIST_FAILURE:
      return {
        loading: false,
      };
    default:
      return state;
  }
}
