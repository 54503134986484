import React from "react";
import Container from "../components/Layouts/Container";
import TermsAndCondition from "../components/Common/TermsAndCondition";

const TermsAndConditionPage = () => {
  return (
    <>
      <Container>
        <TermsAndCondition />
      </Container>
    </>
  );
};

export default TermsAndConditionPage;
