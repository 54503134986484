// import React from "react";
// import { Link } from "react-router-dom";

// const AccountVerifiedPage = () => {
//   return (
//     <>
//       <div className="highlight-msg">
//         <Link to={"/"} className="login-form-logo">
//           <img src="assets/image/login-logo.png" alt="project logo" />
//         </Link>
//         <div className="text">
//           <h1 className="success-color" style={{ color: "green" }}>
//             Success !!
//           </h1>
//           <p>
//             Your account has been verified successfully, you can now login to
//             your account.
//           </p>
//           <div className="text-center mt-3">
//             {/* <Link to={"/login"} className="login-btn">
//               Login
//             </Link> */}
//             <button
//               className="login-btn"
//               onClick={() => {
//                 window.location.href = "/login";
//               }}
//             >
//               Login
//             </button>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default AccountVerifiedPage;

// import React from "react";
// import { Link, useLocation } from "react-router-dom";

// const AccountVerifiedPage = () => {
//   const location = useLocation();
//   const { message, success } = location.state || {};

//   return (
//     <div className="highlight-msg">
//       <Link to={"/"} className="login-form-logo">
//         <img src="assets/image/login-logo.png" alt="project logo" />
//       </Link>
//       <div className="text">
//         <h1
//           className={success ? "success-color" : "error-color"}
//           style={{ color: "green" }}
//         >
//           {success ? "Success !!" : "Already Activated"}
//         </h1>
//         <p>{message}</p>
//         <div className="text-center mt-3">
//           <button
//             className="login-btn"
//             onClick={() => {
//               window.location.href = "/login";
//             }}
//           >
//             Login
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default AccountVerifiedPage;

import React from "react";
import { Link, useLocation } from "react-router-dom";

const AccountVerifiedPage = () => {
  const location = useLocation();
  const { message, code } = location.state || {};



  return (
    <div className="highlight-msg">
      <Link to={"/"} className="login-form-logo">
        <img src="assets/image/login-logo.png" alt="project logo" />
      </Link>
      <div className="text">
        <h1 className="success-color" style={{ color: "green" }}>
          {code === 200 ? "Success !!" : "Already Activated"}
        </h1>
        <p>{message}</p>
        <div className="text-center mt-3">
          <button
            className="login-btn"
            onClick={() => {
              window.location.href = "/login";
            }}
          >
            Login
          </button>
        </div>
      </div>
    </div>
  );
};

export default AccountVerifiedPage;
