import React from 'react'

const Setting = () => {
  return (
    <div>
      <h1>Welcome to Setting Page !!</h1>
    </div>
  )
}

export default Setting
