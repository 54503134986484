import { SURVEY_BANNER_LIST_FAILURE, SURVEY_BANNER_LIST_REQUEST, SURVEY_BANNER_LIST_SUCCESS } from "../Constatnts/BannerConstant";


  export function surveyBannerReducer(state = {}, action) {
    switch (action.type) {
      case SURVEY_BANNER_LIST_REQUEST:
        return {
          loading: true,
        };
      case SURVEY_BANNER_LIST_SUCCESS:
        return {
          loading: false,
          bannerList: action.payload,
        };
      case SURVEY_BANNER_LIST_FAILURE:
        return {
          loading: false,
        };
      default:
        return state;
    }
  }


 