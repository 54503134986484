import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  getParticipantEntryList,
  getParticipantSkipEntryList,
  getSurveyPublicInviteList,
  ResetSurveyFormState,
  setUniqueCode,
} from "../../store/Action/SurveyAction";
import { Toast } from "primereact/toast";

const PublicSurveyForm = () => {
  const toast = React.useRef(null);
  const { uniquecode } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [skipLoading, setSkipLoading] = React.useState(false);

  const [nameError, setNameError] = React.useState("");
  const [emailError, setEmailError] = React.useState("");

  const { surveyPublicInviteData } = useSelector(
    (state) => state.surveyPublicInvite
  );

  const { participantEntryData } = useSelector(
    (state) => state.participantEntry
  );

  const { participantSkipEntryData } = useSelector(
    (state) => state.participantSkipEntry
  );

  const SurveyType = surveyPublicInviteData?.data?.eSurveyType;

  const handleNameChange = (event) => {
    const nameValue = event.target.value.trim();
    event.target.value.trim() !== "" && setNameError("");
    setName(event.target.value);
    if (nameValue.length === 0 && nameValue === "") {
      setNameError("Name is required.");
    }
  };

  const handleEmailChange = (event) => {
    const emailValue = event.target.value.trim();
    setEmail(emailValue);

    event.target.value.trim() !== "" && setEmailError("");

    // Regular expsion for basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (emailValue.trim() === "" && emailValue.length === 0) {
      setEmailError("Email is required.");
    } else if (!emailRegex.test(emailValue)) {
      setEmailError("Invalid email format.");
    } else {
      setEmailError("");
    }
  };

  const ValidDetails = React.useCallback(() => {
    let isValid = true;
    // Regular expression for basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    setNameError("");

    setEmailError("");

    if (name.length === 0 && name === "") {
      setNameError("Name is required.");
      isValid = false;
    }

    if (email.length === 0 && email === "") {
      setEmailError("Email is required.");
      isValid = false;
    } else if (!emailRegex.test(email)) {
      setEmailError("Invalid email format.");
      isValid = false;
    }

    if (!isValid) {
      return false;
    }
    return true;
  }, [name, setNameError, email, setEmailError]);

  React.useEffect(() => {
    dispatch(
      getSurveyPublicInviteList({
        vUniqueCode: uniquecode,
      })
    );
  }, [uniquecode, dispatch]);

  React.useEffect(() => {
    if (
      surveyPublicInviteData &&
      surveyPublicInviteData.code === 200 &&
      surveyPublicInviteData.data
    ) {
      // console.log("hiiiiiiiiiiiiiiiiiiiii");
    } else if (surveyPublicInviteData && surveyPublicInviteData.code === 404) {
      navigate("/error", {
        state: {
          code: surveyPublicInviteData?.code,
        },
      });
    }
  }, [surveyPublicInviteData, navigate]);

  const handleSubmit = React.useCallback(
    (e) => {
      e.preventDefault();

      const isValidData = ValidDetails();
      if (isValidData) {
        dispatch(
          getParticipantEntryList({
            vName: name,
            vEmail: email,
          })
        );
        setLoading(true);
      } else {
      }
    },
    [ValidDetails, name, email, dispatch]
  );

  React.useEffect(() => {
    if (participantEntryData && participantEntryData.code === 201) {
      // setLoading(true);
      dispatch(ResetSurveyFormState());
      toast.current.show({
        severity: "success",
        summary: participantEntryData.message,
        life: 2000,
        className: "custom-toast",
      });
      setTimeout(() => {
        setName("");
        setEmail("");
        setLoading(false);
        const participantUniqueCode = participantEntryData?.data?.vUniqueCode;

        // dispatch(setUniqueCode(participantUniqueCode));
        localStorage.setItem("participantUniqueCode", participantUniqueCode);

        navigate("/project-details", {
          state: { vUniqueCode: uniquecode, SurveyType: SurveyType },
        });
      }, 2000);
    } else if (participantEntryData && participantEntryData.code === 400) {
      setLoading(false);
      dispatch(ResetSurveyFormState());
      toast.current.show({
        severity: "error",
        summary: participantEntryData.message,
        life: 2000,
        className: "custom-toast-error",
      });
      setTimeout(() => {
        setEmail("");
        setName("");
      }, 2000);
    } else if (participantEntryData && participantEntryData.code === 401) {
      setLoading(false);
      dispatch(ResetSurveyFormState());
      toast.current.show({
        severity: "error",
        summary: participantEntryData.message,
        life: 2000,
        className: "custom-toast-error",
      });
      setTimeout(() => {
        setEmail("");
        setName("");
      }, 2000);
    }
  }, [participantEntryData, dispatch, navigate, uniquecode]);

  const handleSkip = React.useCallback(
    async (e) => {
      e.preventDefault();
      setSkipLoading(true);
      try {
        const response = await fetch("https://ipinfo.io/json");

        if (!response.ok) {
          switch (response.status) {
            case 400:
              toast.current.show({
                severity: "error",
                summary: "Bad request. Please try again later.",
                life: 2000,
                className: "custom-toast-error",
              });
              break;
            case 401:
              toast.current.show({
                severity: "error",
                summary: "Unauthorized access. Please check your credentials.",
                life: 2000,
                className: "custom-toast-error",
              });
              break;
            case 403:
              toast.current.show({
                severity: "error",
                summary:
                  "Access forbidden. You do not have permission to access this resource.",
                life: 2000,
                className: "custom-toast-error",
              });
              break;
            case 404:
              toast.current.show({
                severity: "error",
                summary: "Resource not found. Please try again later.",
                life: 2000,
                className: "custom-toast-error",
              });
              break;
            case 429:
              toast.current.show({
                severity: "error",
                summary: "API limit exceeded. Please try again later.",
                life: 2000,
                className: "custom-toast-error",
              });
              break;
            case 500:
              toast.current.show({
                severity: "error",
                summary: "Server error. Please try again later.",
                life: 2000,
                className: "custom-toast-error",
              });
              break;
            case 502:
              toast.current.show({
                severity: "error",
                summary: "Bad gateway. Please try again later.",
                life: 2000,
                className: "custom-toast-error",
              });
              break;
            case 503:
              toast.current.show({
                severity: "error",
                summary: "Service unavailable. Please try again later.",
                life: 2000,
                className: "custom-toast-error",
              });
              break;
            case 504:
              toast.current.show({
                severity: "error",
                summary: "Gateway timeout. Please try again later.",
                life: 2000,
                className: "custom-toast-error",
              });

              break;
            default:
              toast.current.show({
                severity: "error",
                summary: `Unexpected error: ${response.statusText}`,
                life: 2000,
                className: "custom-toast-error",
              });

              break;
          }
        } else {
          const data = await response.json();

          const [latitude, longitude] = data.loc
            .split(",")
            .map((coord) => parseFloat(coord));

          dispatch(
            getParticipantSkipEntryList({
              vIp: data?.ip,
              vRegion: data?.region,
              vCountryCode: data?.country,
              vCity: data?.city,
              vZip: data?.postal,
              vLatitude: latitude,
              vLongitude: longitude,
              vTimeZone: data?.timezone,
            })
          );
        }
      } catch (err) {
        if (err.name === "TypeError") {
          toast.current.show({
            severity: "error",
            summary:
              "Network error. Please check your connection and try again.",
            life: 2000,
            className: "custom-toast-error",
          });
        } else if (err.message.includes("429")) {
          toast.current.show({
            severity: "error",
            summary: "API limit exceeded. Please try again later.",
            life: 2000,
            className: "custom-toast-error",
          });
        } else {
          toast.current.show({
            severity: "error",
            summary: "An unexpected error occurred. Please try again later.",
            life: 2000,
            className: "custom-toast-error",
          });
        }
        // console.error("Error fetching IP API:", err.message);
      }
    },
    [dispatch]
  );

  React.useEffect(() => {
    if (participantSkipEntryData && participantSkipEntryData.code === 201) {
      setSkipLoading(false);
      const participantUniqueCode = participantSkipEntryData?.data?.vUniqueCode;
      dispatch(setUniqueCode(participantUniqueCode));
      localStorage.setItem("participantUniqueCode", participantUniqueCode);

      navigate("/project-details", {
        state: { vUniqueCode: uniquecode, SurveyType: SurveyType },
      });
    } else if (
      participantSkipEntryData &&
      participantSkipEntryData.data &&
      participantSkipEntryData.status === 400
    ) {
      toast.current.show({
        severity: "error",
        summary: "Bad request. Please try again later.",
        life: 2000,
        className: "custom-toast-error",
      });
    }
  }, [participantSkipEntryData, dispatch, navigate, uniquecode]);

  return (
    <React.Fragment>
      <Toast ref={toast} />
      {surveyPublicInviteData && surveyPublicInviteData.code === 200 ? (
        <div className="container">
          <div className="form-container" style={{ marginTop: "100px" }}>
            <div style={{ textAlign: "center" }}>
              <Link to="/" className="login-form-logo">
                <img src="../assets/image/login-logo.png" alt="project logo" />
              </Link>
            </div>
            <h5 style={{ marginTop: "20px", textAlign: "center" }}>
              Survey Invitation
            </h5>
            <p
              style={{
                marginTop: "10px",
                marginBottom: "30px",
                textAlign: "center",
                fontSize: "15px",
              }}
            >
              provide your name & email for better survey experience
            </p>
            <form style={{ marginTop: "20px" }} onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-12">
                  <div className="input-box">
                    <label htmlFor="exampleInputName" className="form-label">
                      Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Name"
                      value={name}
                      onChange={handleNameChange}
                    />
                    {nameError && (
                      <div
                        className="error-msg"
                        style={{
                          color: "red",
                          fontSize: "14px",
                          marginLeft: "5px",
                        }}
                      >
                        {nameError}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="input-box">
                    <label htmlFor="exampleInputEmail1" className="form-label">
                      Email address
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Enter Email Address"
                      value={email}
                      onChange={handleEmailChange}
                    />
                    {emailError && (
                      <div
                        className="error-msg"
                        style={{
                          color: "red",
                          fontSize: "14px",
                          marginLeft: "5px",
                        }}
                      >
                        {emailError}
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="login-btn-view">
                    {loading ? (
                      <>
                        {" "}
                        <button className="login-btn" type="button" disabled>
                          <span
                            className="spinner-border spinner-border-sm me-1"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          Loading...
                        </button>
                      </>
                    ) : (
                      <button type="submit" className="login-btn">
                        Submit
                      </button>
                    )}
                    {skipLoading ? (
                      <>
                        {" "}
                        <button className="login-btn" type="button" disabled>
                          <span
                            className="spinner-border spinner-border-sm me-1"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          Loading...
                        </button>
                      </>
                    ) : (
                      <button
                        type="button"
                        className="login-btn cancel-btn"
                        onClick={handleSkip}
                      >
                        Skip
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      ) : (
        <></>
      )}
    </React.Fragment>
  );
};

export default PublicSurveyForm;

///This code for academic survey properly //

// import React from "react";
// import Header from "../Layouts/Header";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   getSurveyAnswerList,
//   getSurveyQuestionList,
// } from "../../store/Action/SurveyAction";
// import { useLocation, useNavigate } from "react-router-dom";

// const QuestionThree = () => {
//   const dispatch = useDispatch();
//   const location = useLocation();
//   const navigate = useNavigate();
//   const { state } = location;
//   const vUniqueCode = state?.vUniqueCode || null;
//   const SurveyId = state?.SurveyId || null;
//   const participantvUniqueCodeNew = localStorage.getItem(
//     "participantvUniqueCodeNew"
//   );

//   const [buttonLoading, setButtonLoading] = React.useState(false);
//   const [pageNumber, setPageNumber] = React.useState(
//     // Initialize with the value from localStorage or default to 1
//     Number(localStorage.getItem("pageNumber")) || 1
//   );
//   const [errors, setErrors] = React.useState({});
//   const [email, setEmail] = React.useState("");
//   const [emailError, setEmailError] = React.useState("");
//   const [selectedCategories, setSelectedCategories] = React.useState(
//     JSON.parse(localStorage.getItem("selectedCategories")) || {}
//   );

//   const { surveyQuestionData, loading } = useSelector(
//     (state) => state.surveyQuestion
//   );
//   const participantSurveyId = surveyQuestionData?.data?.Data?.[0]?.iSurveyId;
//   const { surveyAnswerData } = useSelector((state) => state.surveyAnswer);
//   const userUniqueCode = localStorage.getItem("vUniqueCode");
//   // const { participantUniqueCode } = useSelector((state) => state.participant);
//   const participantUniqueCode = localStorage.getItem("participantUniqueCode");
//   // Save selected categories to local storage
//   React.useEffect(() => {
//     localStorage.setItem(
//       "selectedCategories",
//       JSON.stringify(selectedCategories)
//     );
//   }, [selectedCategories]);

//   // Load email from local storage
//   React.useEffect(() => {
//     const savedEmail = localStorage.getItem("email");
//     if (savedEmail) {
//       setEmail(savedEmail);
//     }
//   }, []);

//   React.useEffect(() => {
//     localStorage.setItem("pageNumber", pageNumber);
//   }, [pageNumber]);

//   // Fetch survey questions on page load or when pageNumber changes
//   React.useEffect(() => {
//     const cleanedUniqueCode = vUniqueCode && vUniqueCode.replace(/^"|"$/g, "");
//     dispatch(
//       getSurveyQuestionList({
//         vUniqueCode: cleanedUniqueCode,
//         iPageNo: pageNumber,
//         // vPage: 1,
//         // limit:2
//       })
//     );
//   }, [dispatch, vUniqueCode, pageNumber]);

//   const handleEmailChange = (event) => {
//     const emailValue = event.target.value.trim();
//     setEmail(emailValue);
//     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

//     if (!emailRegex.test(emailValue)) {
//       setEmailError("Invalid Email format.");
//     } else {
//       setEmailError("");
//       localStorage.setItem("email", emailValue);
//     }
//   };

//   const handleCategorySelection = (questionId, categoryId) => {
//     setSelectedCategories((prevSelected) => {
//       // Determine if the category is currently selected
//       const isCategoryCurrentlySelected = prevSelected[categoryId]?.selected;

//       // If unselecting the category, reset subcategories as well
//       const updatedCategories = {
//         ...prevSelected,
//         [categoryId]: {
//           ...prevSelected[categoryId],
//           selected: !isCategoryCurrentlySelected,
//           subCategories: isCategoryCurrentlySelected
//             ? {} // Clear subcategories if the category is unselected
//             : prevSelected[categoryId]?.subCategories || {},
//         },
//       };

//       const isAnyCategorySelected = Object.values(updatedCategories).some(
//         (category) => category.selected
//       );

//       // Handle errors for the specific question
//       setErrors((prevErrors) => ({
//         ...prevErrors,
//         [questionId]: {
//           ...prevErrors[questionId],
//           general: isAnyCategorySelected
//             ? ""
//             : "Please select at least one category.",
//         },
//       }));

//       return updatedCategories;
//     });
//   };

//   const handleOptionSelection = (questionId, optionId) => {
//     setSelectedCategories((prevSelected) => {
//       // Determine if the category is currently selected
//       const isCategoryCurrentlySelected = prevSelected[optionId]?.selected;

//       // If unselecting the category, reset subcategories as well
//       const updatedOptions = {
//         ...prevSelected,
//         [optionId]: {
//           ...prevSelected[optionId],
//           selected: !isCategoryCurrentlySelected,
//         },
//       };

//       console.log("updatedOptions", updatedOptions);

//       const isAnyOptionSelected = Object.values(updatedOptions).some(
//         (option) => option.selected
//       );

//       // Handle errors for the specific question
//       setErrors((prevErrors) => ({
//         ...prevErrors,
//         [questionId]: {
//           ...prevErrors[questionId],
//           general: isAnyOptionSelected
//             ? ""
//             : "Please select at least one Option.",
//         },
//       }));

//       return updatedOptions;
//     });
//   };

//   const handleSubCategorySelection = (
//     questionId,
//     categoryId,
//     subCategoryId
//   ) => {
//     setSelectedCategories((prevSelected) => {
//       const updatedSubCategories = {
//         ...prevSelected[categoryId],
//         subCategories: {
//           ...prevSelected[categoryId]?.subCategories,
//           [subCategoryId]:
//             !prevSelected[categoryId]?.subCategories?.[subCategoryId],
//         },
//       };

//       const isSubCategorySelected = Object.values(
//         updatedSubCategories.subCategories
//       ).some((isSelected) => isSelected);

//       const updatedCategories = {
//         ...prevSelected,
//         [categoryId]: {
//           ...updatedSubCategories,
//           isValid: isSubCategorySelected,
//         },
//       };

//       const isAllSelectedCategoriesValid = Object.values(
//         updatedCategories
//       ).every((category) => !category.selected || category.isValid);

//       // Update errors per question
//       setErrors((prevErrors) => ({
//         ...prevErrors,
//         [questionId]: {
//           ...prevErrors[questionId],
//           general: isAllSelectedCategoriesValid
//             ? ""
//             : "Please select at least one subcategory for each selected category.",
//         },
//       }));

//       return updatedCategories;
//     });
//   };

//   const handleNextQuestion = async () => {
//     // Validate all questions on the current page
//     let isValid = true;
//     let newErrors = {};
//     let iOptionId = [];

//     surveyQuestionData?.data?.Data.forEach((question) => {
//       let questionErrors = [];
//       let questionOptionArray = [];

//       // Check if any category is selected for the question
//       const isAnyCategorySelected = question?.categories?.some(
//         (category) => selectedCategories[category.iCategoryId]?.selected
//       );

//       if (!isAnyCategorySelected) {
//         isValid = false;
//         questionErrors.push("Please select at least one category.");
//       }

//       // Check if all selected categories have at least one subcategory selected
//       const isAllSelectedCategoriesValid = question?.categories?.every(
//         (category) => {
//           if (selectedCategories[category.iCategoryId]?.selected) {
//             const hasSelectedSubCategory = Object.values(
//               selectedCategories[category.iCategoryId]?.subCategories || {}
//             ).some((isSelected) => isSelected);
//             return hasSelectedSubCategory;
//           }
//           return true;
//         }
//       );

//       if (!isAllSelectedCategoriesValid) {
//         isValid = false;
//         questionErrors.push(
//           "Please select at least one subcategory for each selected category."
//         );
//       }

//       // Add question-specific errors to the `newErrors` object
//       if (questionErrors.length > 0) {
//         newErrors[question.iQuestionId] = questionErrors;
//       }

//       // Prepare the payload for the question
//       question?.categories?.forEach((category) => {
//         if (selectedCategories[category.iCategoryId]?.selected) {
//           const selectedSubCategoryIds = Object.keys(
//             selectedCategories[category.iCategoryId]?.subCategories || {}
//           ).filter(
//             (subCatId) =>
//               selectedCategories[category.iCategoryId].subCategories[subCatId]
//           );

//           if (selectedSubCategoryIds.length > 0) {
//             questionOptionArray.push({
//               [category.iCategoryId]: selectedSubCategoryIds.map(Number),
//             });
//           }
//         }
//       });

//       // Only add to `iOptionId` if there are selected categories and subcategories
//       if (questionOptionArray.length > 0) {
//         iOptionId.push(questionOptionArray);
//       }
//     });

//     // If there are any errors, update the state
//     if (!isValid) {
//       setErrors(newErrors);
//       return;
//     }

//     // Clear all errors if validation passes
//     setErrors({});

//     // Prepare the final payload
//     const payload = {
//       vUniqueCode:
//         participantUniqueCode || userUniqueCode || participantvUniqueCodeNew,
//       iQuestionId: surveyQuestionData?.data?.Data.map((question) => ({
//         [question.iQuestionId]: question.eType,
//       })),
//       iOptionId: iOptionId, // This now has the correct structure
//       iSurveyId: participantSurveyId || SurveyId,
//       iPageNo: pageNumber,
//     };

//     try {
//       setButtonLoading(true);
//       const response = await fetch(
//         `${process.env.REACT_APP_API_URL}/api/question/answer`,
//         {
//           method: "POST",
//           headers: { "Content-Type": "application/json" },
//           body: JSON.stringify(payload),
//         }
//       );

//       const responseData = await response.json();
//       setButtonLoading(false);

//       if (responseData && responseData.status === 200) {
//         if (responseData.data?.survey_status === "Completed") {
//           navigate("/survey/thank-you");
//           clearLocalStorage();
//         } else {
//           setPageNumber(responseData.data?.NextPageNo);
//           window.scrollTo(0, 0);
//         }
//       }
//     } catch (error) {
//       setButtonLoading(false);
//       // Handle error
//     }
//   };

//   const handleBackQuestion = () => {
//     if (pageNumber > 1) {
//       setPageNumber((prevPageNumber) => prevPageNumber - 1);
//       window.scrollTo(0, 0);
//     } else {
//       navigate("/general-info", {
//         state: { vUniqueCode, SurveyId, participantvUniqueCodeNew },
//       });
//     }
//   };

//   // Helper function to clear local storage
//   const clearLocalStorage = () => {
//     localStorage.removeItem("pageNumber");
//     localStorage.removeItem("selectedCategories");
//     localStorage.removeItem("education");
//     localStorage.removeItem("researchOption");
//     localStorage.removeItem("Other");
//     localStorage.removeItem("agree");
//     localStorage.removeItem("disagree");
//     localStorage.removeItem("participantvUniqueCodeNew");
//     localStorage.removeItem("participantUniqueCode");
//     localStorage.removeItem("email");
//   };

//   const TotalPage = surveyQuestionData?.data?.totalPages;

//   return (
//     <>
//       <Header />
//       <div className="questions-wrp survey-category survey-page">
//         {loading ? (
//           <>
//             <div
//               style={{
//                 display: "flex",
//                 justifyContent: "center",
//                 alignItems: "center",
//                 marginTop: "50px",
//                 marginBottom: "50px",
//               }}
//             >
//               {loading && (
//                 <img
//                   src="/assets/gif/350.gif"
//                   className="loading-gif"
//                   alt="Loading..."
//                 />
//               )}
//             </div>
//           </>
//         ) : (
//           <>
//             {surveyQuestionData &&
//             surveyQuestionData?.code === 200 &&
//             surveyQuestionData?.data?.Data &&
//             surveyQuestionData?.data?.Data.length > 0 ? (
//               <>
//                 <div className="container">
//                   {surveyQuestionData?.data?.Data.map((item, itemIndex) => (
//                     <div className="main-category" key={itemIndex}>
//                       <>
//                         <h2 className="qus-title">{item.vQuestion}</h2>
//                         {item?.categories?.map((category) => (
//                           <div
//                             className="category-list border-0 pt-0"
//                             key={category.iCategoryId}
//                           >
//                             <div className="form-check">
//                               <input
//                                 type="checkbox"
//                                 id={`specific-${category.iCategoryId}`}
//                                 className="form-check-input"
//                                 onChange={() =>
//                                   handleCategorySelection(
//                                     item.iQuestionId,
//                                     category.iCategoryId
//                                   )
//                                 }
//                                 checked={
//                                   selectedCategories[category.iCategoryId]
//                                     ?.selected || false
//                                 }
//                               />
//                               <label
//                                 htmlFor={`specific-${category.iCategoryId}`}
//                                 className="category-title"
//                               >
//                                 {category.vCategory}
//                               </label>
//                             </div>

//                             {selectedCategories[category.iCategoryId]
//                               ?.selected && (
//                               <div className="sub-category-list">
//                                 <div className="row g-3">
//                                   {category?.subCategories?.map(
//                                     (subCategory) => (
//                                       <div
//                                         className="col-md-6 col-lg-4"
//                                         key={subCategory.iSubCategoryId}
//                                       >
//                                         <div className="form-check">
//                                           <input
//                                             type="checkbox"
//                                             id={`sub-${category.iCategoryId}-${subCategory.iSubCategoryId}`}
//                                             className="form-check-input"
//                                             onChange={() =>
//                                               handleSubCategorySelection(
//                                                 item.iQuestionId,
//                                                 category.iCategoryId,
//                                                 subCategory.iSubCategoryId
//                                               )
//                                             }
//                                             checked={
//                                               selectedCategories[
//                                                 category.iCategoryId
//                                               ]?.subCategories?.[
//                                                 subCategory.iSubCategoryId
//                                               ] || false
//                                             }
//                                           />
//                                           <label
//                                             htmlFor={`sub-${category.iCategoryId}-${subCategory.iSubCategoryId}`}
//                                           >
//                                             {subCategory.vSubCategory}
//                                           </label>
//                                         </div>
//                                       </div>
//                                     )
//                                   )}
//                                 </div>
//                               </div>
//                             )}
//                           </div>
//                         ))}
//                         {/* Display Errors for Each Question */}
//                         {errors[item.iQuestionId] &&
//                           Array.isArray(errors[item.iQuestionId]) && (
//                             <div>
//                               {errors[item.iQuestionId].map((error, index) => (
//                                 <div
//                                   key={index}
//                                   style={{
//                                     color: "red",
//                                     marginBottom: "10px",
//                                   }}
//                                   className="error-message"
//                                 >
//                                   {error}
//                                 </div>
//                               ))}
//                             </div>
//                           )}
//                       </>
//                     </div>
//                   ))}

//                   <div>
//                     {pageNumber === 2 && (
//                       <div>
//                         <h3>
//                           If you are interested in gaining access to updates on
//                           this research, please enter your contact information
//                           below. We will contact you concerning publication of
//                           the results:
//                         </h3>
//                         <div className="row">
//                           <div className="col-md-6 col-lg-4">
//                             <div className="form-group">
//                               <input
//                                 type="text"
//                                 className="form-control"
//                                 placeholder="Email Address"
//                                 value={email}
//                                 onChange={handleEmailChange}
//                               />
//                               {emailError && (
//                                 <div
//                                   className="error-msg"
//                                   style={{
//                                     color: "red",
//                                     marginLeft: "5px",
//                                     fontSize: "14px",
//                                   }}
//                                 >
//                                   {" "}
//                                   {emailError}
//                                 </div>
//                               )}
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                     )}
//                   </div>
//                   <div>
//                     {buttonLoading ? (
//                       <button
//                         className="next-page login-btn"
//                         type="button"
//                         disabled
//                       >
//                         <span
//                           className="spinner-border spinner-border-sm me-1"
//                           role="status"
//                           aria-hidden="true"
//                         ></span>
//                         {pageNumber === TotalPage
//                           ? "Submitting..."
//                           : "Loading..."}
//                       </button>
//                     ) : (
//                       <button
//                         className="next-page login-btn"
//                         onClick={handleNextQuestion}
//                       >
//                         {pageNumber === TotalPage ? "Submit" : "Next"}
//                       </button>
//                     )}

//                     {/* Conditionally show the Back button */}
//                     {/* {surveyQuestionData.data.Data.length - 1 && (
//                       <button
//                         style={{ marginLeft: "20px" }}
//                         className="next-page back-btn"
//                         onClick={handleBackQuestion}
//                       >
//                         Back
//                       </button>
//                     )} */}
//                     {pageNumber === TotalPage ? (
//                       ""
//                     ) : (
//                       <button
//                         className="next-page back-btn"
//                         type="submit"
//                         style={{ marginLeft: "20px" }}
//                         onClick={handleBackQuestion}
//                       >
//                         Back
//                       </button>
//                     )}
//                   </div>
//                 </div>
//               </>
//             ) : (
//               <>
//                 <div
//                   className="no-data"
//                   style={{
//                     display: "flex",
//                     justifyContent: "center",
//                     alignItems: "center",
//                     padding: "10px 0",
//                     marginTop: "50px",
//                   }}
//                 >
//                   No Data Found
//                 </div>
//               </>
//             )}
//           </>
//         )}
//       </div>
//     </>
//   );
// };

// export default QuestionThree;
