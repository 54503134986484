import React from "react";

import Questiontwo from "../components/Question/Questiontwo";

const QuestionTwoPage = () => {
  return (
    <>
      <div>
        <Questiontwo />
      </div>
    </>
  );
};

export default QuestionTwoPage;
