import React from "react";
import ChangePassword from "../components/ChangePassword/ChangePassword";
import Header from "../components/Layouts/Header";
import { useNavigate } from "react-router-dom";

const ChangePasswordPage = () => {
  const navigate = useNavigate();
  const vUniqueCode = localStorage.getItem("vUniqueCode");

  React.useEffect(() => {
    if (!vUniqueCode) {
      navigate("/");
    }
  }, [vUniqueCode,navigate]);

  return (
    <React.Fragment>
      <Header />
      <ChangePassword />
    </React.Fragment>
  );
};

export default ChangePasswordPage;
