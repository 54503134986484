import React from "react";
import { Link } from "react-router-dom";

const SuccessfullRegistrationPage = () => {
  return (
    <div className="highlight-msg">
      <div className="login-form-logo">
        <Link to="/">
          <img
            src="assets/image/login-logo.png"
            alt="loginlogoimage"
            width="296"
            height="68"
          />
        </Link>
      </div>
      <div className="text">
        <h1>Thank You</h1>
        <p>
          Your account is successfully created , please check your email and
          activate your account.
        </p>
        <div className="text-center mt-3">
          <Link to={"/"} className="login-btn">
            Continue
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SuccessfullRegistrationPage;
