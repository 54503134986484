import React from "react";
import Header from "../Layouts/Header";

const Organisation4 = () => {
  return (
    <>
      <Header />
      <div className="questions-wrp survey-category questions2 survey-page">
        <div className="container">
          <h2 className="main-title" style={{ fontWeight: "bold" }}>
            EXPERIENCE OF RESEARCH
          </h2>
          <div className="questions2-body questions4 survey-content">
            <div className="main-category">
              <h2 className="qus-title">
                Which of the following does your organisation participate in?
              </h2>
              <div className="category-list border-0 pt-0">
                <div className="form-check">
                  <input
                    type="checkbox"
                    id="specific-349"
                    className="form-check-input"
                  />
                  <label htmlFor="specific-349" className="category-title">
                    Insight
                  </label>
                </div>
                <div className="form-check">
                  <input
                    type="checkbox"
                    id="specific-348"
                    className="form-check-input"
                  />
                  <label htmlFor="specific-348" className="category-title">
                    Research
                  </label>
                </div>
                <div className="form-check">
                  <input
                    type="checkbox"
                    id="specific-348"
                    className="form-check-input"
                  />
                  <label htmlFor="specific-348" className="category-title">
                    Intelligence gathering
                  </label>
                </div>
                <div className="form-check">
                  <input
                    type="checkbox"
                    id="specific-348"
                    className="form-check-input"
                  />
                  <label htmlFor="specific-348" className="category-title">
                    None of the above
                  </label>
                </div>
              </div>
            </div>
            <div className="main-category">
              <h2 className="qus-title">
                What tools does your organisation use to carry out research?
              </h2>
              <div className="category-list border-0 pt-0">
                <div className="form-check">
                  <input
                    type="checkbox"
                    id="specific-349"
                    className="form-check-input"
                  />
                  <label htmlFor="specific-349" className="category-title">
                    Qualiitative analysis software (e.g. Nvivo, ATLAS)
                  </label>
                </div>
                <div className="form-check">
                  <input
                    type="checkbox"
                    id="specific-348"
                    className="form-check-input"
                  />
                  <label htmlFor="specific-348" className="category-title">
                    Quantitative analysis software (e.g. STATA; SPSS)
                  </label>
                </div>
                <div className="form-check">
                  <input
                    type="checkbox"
                    id="specific-348"
                    className="form-check-input"
                  />
                  <label htmlFor="specific-348" className="category-title">
                    Manual analysis (e.g. Word document and Excel)
                  </label>
                </div>
                <div className="form-check">
                  <input
                    type="checkbox"
                    id="specific-348"
                    className="form-check-input"
                  />
                  <label htmlFor="specific-348" className="category-title">
                    Transacription software
                  </label>
                </div>
                <div className="form-check">
                  <input
                    type="checkbox"
                    id="specific-348"
                    className="form-check-input"
                  />
                  <label htmlFor="specific-348" className="category-title">
                    None of the above
                  </label>
                </div>
              </div>
            </div>
            <div className="main-category">
              <h2 className="qus-title">
                His there a named research lead or person responsible for
                research in your organisation?
              </h2>
              <div className="category-list border-0 pt-0">
                <div className="form-check">
                  <input
                    type="radio"
                    id="specific-351"
                    name="question-35"
                    className="form-check-input radio-check"
                  />
                  <label htmlFor="specific-351" className="category-title">
                    Yes
                  </label>
                </div>
                <div className="form-check">
                  <input
                    type="radio"
                    id="specific-350"
                    name="question-35"
                    className="form-check-input radio-check"
                  />
                  <label htmlFor="specific-350" className="category-title">
                    No
                  </label>
                </div>
                <div className="form-check">
                  <input
                    type="radio"
                    id="specific-350"
                    name="question-35"
                    className="form-check-input radio-check"
                  />
                  <label htmlFor="specific-350" className="category-title">
                    Don’t know
                  </label>
                </div>
              </div>
            </div>
            <div className="main-category">
              <h2 className="qus-title">
                How long have you been involved in the following research
                processes?{" "}
              </h2>
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Weekly</th>
                      <th>Monthly</th>
                      <th>Quarterly</th>
                      <th>Annually</th>
                      <th>Less than once a year</th>
                      <th>Never</th>
                      <th>Don't Know</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Study design</td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Ethical review</td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Producing consent forms</td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Producing participant information sheets</td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Recruitment (e.g actively finding people to participate)
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Collecting data (e.g. interviews ,surveys)</td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Analysing data</td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Dissemination (e.g. sharing study details with
                        communities)
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="main-category">
              <h2 className="qus-title">
                How long have you been involved in the following research
                processes?{" "}
              </h2>
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Very confident</th>
                      <th>Confident</th>
                      <th>Neutral</th>
                      <th>Somewhat not confident</th>
                      <th>Not at all confident</th>
                      <th>N/A</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Applying for reasearch funding</td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>

                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Identifyinf collabrates for reasearch activities</td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Study design</td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Reasearch ethics</td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Consent processes</td>

                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>Recruitment</td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Collecting data</td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Analysing data</td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Storing data</td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Dissemination</td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Needs assessment</td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Programme evaluation</td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Impact assessment</td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Policy analysis</td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id="specific-350"
                            className="form-check-input"
                            name="question-35"
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="main-category">
              <h2 className="qus-title">What types of insight do you collect?</h2>
              <div className="category-list border-0 pt-0">
                <div className="form-check">
                  <input
                    type="checkbox"
                    id="specific-349"
                    className="form-check-input"
                  />
                  <label htmlFor="specific-349" className="category-title">
                    Information through surveys
                  </label>
                </div>
                <div className="form-check">
                  <input
                    type="checkbox"
                    id="specific-348"
                    className="form-check-input"
                  />
                  <label htmlFor="specific-348" className="category-title">
                    Qualitative information collected via targeted projects
                  </label>
                </div>
                <div className="form-check">
                  <input
                    type="checkbox"
                    id="specific-348"
                    className="form-check-input"
                  />
                  <label htmlFor="specific-348" className="category-title">
                    that have a specific research question
                  </label>
                </div>
                <div className="form-check">
                  <input
                    type="checkbox"
                    id="specific-348"
                    className="form-check-input"
                  />
                  <label htmlFor="specific-348" className="category-title">
                    Informal gathering of insight collected through pre-existing
                    or unrelated activity
                  </label>
                </div>
                <div className="form-check">
                  <input
                    type="checkbox"
                    id="specific-348"
                    className="form-check-input"
                  />
                  <label htmlFor="specific-348" className="category-title">
                    Other
                  </label>
                </div>
              </div>
            </div>

            <div className="main-category">
              <h2 className="qus-title">How do you share your findings? </h2>
              <div className="category-list border-0 pt-0">
                <div className="form-check">
                  <input
                    type="checkbox"
                    id="specific-349"
                    className="form-check-input"
                  />
                  <label htmlFor="specific-349" className="category-title">
                    Reports & publications (not peer reviewed)
                  </label>
                </div>
                <div className="form-check">
                  <input
                    type="checkbox"
                    id="specific-348"
                    className="form-check-input"
                  />
                  <label htmlFor="specific-348" className="category-title">
                    Workshops & seminars
                  </label>
                </div>
                <div className="form-check">
                  <input
                    type="checkbox"
                    id="specific-348"
                    className="form-check-input"
                  />
                  <label htmlFor="specific-348" className="category-title">
                    Webinars
                  </label>
                </div>
                <div className="form-check">
                  <input
                    type="checkbox"
                    id="specific-348"
                    className="form-check-input"
                  />
                  <label htmlFor="specific-348" className="category-title">
                    Social media
                  </label>
                </div>
                <div className="form-check">
                  <input
                    type="checkbox"
                    id="specific-348"
                    className="form-check-input"
                  />
                  <label htmlFor="specific-348" className="category-title">
                    Newsletters
                  </label>
                </div>
                <div className="form-check">
                  <input
                    type="checkbox"
                    id="specific-348"
                    className="form-check-input"
                  />
                  <label htmlFor="specific-348" className="category-title">
                    Other
                  </label>
                </div>
              </div>
            </div>

            <button className="next-page login-btn">Submit</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Organisation4;
