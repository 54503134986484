import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { getPageList } from "../../store/Action/PageAction";

const HowItWorkSection = () => {
  const dispatch = useDispatch();

  const { pageListData, loading } = useSelector((state) => state.pageList);

  React.useEffect(() => {
    dispatch(
      getPageList({
        vSlug: "how-it-work",
        eStatus: "Active",
        eDelete: "No",
      })
    );
  }, [dispatch]);

  let decodedString = "";

  if (pageListData && pageListData.code === 200 && pageListData.data) {
    const base64Strings = pageListData.data.map((about) => about.tDescription);

    decodedString = atob(base64Strings);
  }
  return (
    <React.Fragment>
      {/* <section className='how-it-work py-100'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-6'>
            <div className='how-it-work-content pr-100'>
              <h1  className='main-title'>
                How It Works
              </h1>
              <div className='how-it-work-desc'>
                <p>
                  We believe that the best way to create successful marketing campaigns is to work closely with our clients to understand their goals and challenges.
                </p>
              </div>
              <div className='how-it-work-img'>
                <img src="assets/image/how-it-work-img.jpg" alt="howitworkimage"
                  width="590" height="390" />
              </div>
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='how-it-right-view'>
              <div className='how-it-detail'>
                <div className='how-it-box'>
                  <img src="assets/image/how-it-1.png" alt="howitboximage"
                    width="46" height="46" />
                </div>
                <div className='how-it-right-content'>
                  <h3 className='title'>
                    Lorem ipsum dolor sit amet
                  </h3>
                  <div>
                    <p className='desc'>
                      We meet with you to learn about your business, your goals, and your target audience.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className='how-it-right-view'>
              <div className='how-it-detail'>
                <div className='how-it-box'>
                  <img src="assets/image/how-it-2.png" alt="howitboximage"
                    width="46" height="46" />
                </div>
                <div className='how-it-right-content'>
                  <h3 className='title'>
                    Lorem ipsum dolor sit amet
                  </h3>
                  <div>
                    <p className='desc'>
                      We meet with you to learn about your business, your goals, and your target audience.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className='how-it-right-view'>
              <div className='how-it-detail'>
                <div className='how-it-box'>
                  <img src="assets/image/how-it-3.png" alt="howitboximage"
                    width="46" height="46" />
                </div>
                <div className='how-it-right-content'>
                  <h3 className='title'>
                    Lorem ipsum dolor sit amet
                  </h3>
                  <div>
                    <p className='desc'>
                      We meet with you to learn about your business, your goals, and your target audience.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className='how-it-right-view'>
              <div className='how-it-detail'>
                <div className='how-it-box'>
                  <img src="assets/image/how-it-4.png" alt="howitboximage"
                    width="46" height="46" />
                </div>
                <div className='how-it-right-content'>
                  <h3 className='title'>
                    Lorem ipsum dolor sit amet
                  </h3>
                  <div>
                    <p className='desc'>
                      We meet with you to learn about your business, your goals, and your target audience.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> */}

      {loading ? (
        <div style={{ height: "800px" }}></div>
      ) : pageListData && pageListData?.code === 200 && pageListData?.data ? (
        <div dangerouslySetInnerHTML={{ __html: decodedString }} />
      ) : (
        <div
          className="col-12"
          style={{ marginTop: "10%", marginBottom: "20%" }}
        >
          <p
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "10px 0",
            }}
          >
            No Data Found
          </p>
        </div>
      )}
    </React.Fragment>
  );
};

export default HowItWorkSection;
