import React from "react";
import PublicSurveyForm from "../components/PublicSurvey/PublicSurveyForm";

const PublicSurveyFormPage = () => {
  return (
    <div>
      <PublicSurveyForm />
    </div>
  );
};

export default PublicSurveyFormPage;
