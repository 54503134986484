import React from "react";
import Header from "../Layouts/Header";

const Organisation3 = () => {
  return (
    <>
      <Header />
      <div className="questions-wrp survey-category questions2 survey-page">
        <div className="container">
          <h2 className="main-title" style={{ fontWeight: "bold" }}>
            THE COMMUNITIES YOU WORK WITH
          </h2>
          <div className="questions2-body questions4 survey-content">
            <div className="main-category">
              <h2 className="qus-title">
                Roughly how many people does your organisation support in a
                year?
              </h2>
              <div className="input-box">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Type Answer Here..."
                  value=""
                />
              </div>
            </div>
            <div className="main-category">
              <h2 className="qus-title">How do you engage with your community?</h2>
              <div className="category-list border-0 pt-0">
                <div className="form-check">
                  <input
                    type="checkbox"
                    id="specific-349"
                    className="form-check-input"
                  />
                  <label htmlFor="specific-349" className="category-title">
                    Workshops & training
                  </label>
                </div>
                <div className="form-check">
                  <input
                    type="checkbox"
                    id="specific-348"
                    className="form-check-input"
                  />
                  <label htmlFor="specific-348" className="category-title">
                    Events & gatherings
                  </label>
                </div>
                <div className="form-check">
                  <input
                    type="checkbox"
                    id="specific-348"
                    className="form-check-input"
                  />
                  <label htmlFor="specific-348" className="category-title">
                    Events & gatherings
                  </label>
                </div>
                <div className="form-check">
                  <input
                    type="checkbox"
                    id="specific-348"
                    className="form-check-input"
                  />
                  <label htmlFor="specific-348" className="category-title">
                    Social media
                  </label>
                </div>
                <div className="form-check">
                  <input
                    type="checkbox"
                    id="specific-348"
                    className="form-check-input"
                  />
                  <label htmlFor="specific-348" className="category-title">
                    Surveys & feedback
                  </label>
                </div>
                <div className="form-check">
                  <input
                    type="checkbox"
                    id="specific-348"
                    className="form-check-input"
                  />
                  <label htmlFor="specific-348" className="category-title">
                    Volunteer programmes
                  </label>
                </div>
                <div className="form-check">
                  <input
                    type="checkbox"
                    id="specific-348"
                    className="form-check-input"
                  />
                  <label htmlFor="specific-348" className="category-title">
                    Outreach programmes
                  </label>
                </div>
                <div className="form-check">
                  <input
                    type="checkbox"
                    id="specific-348"
                    className="form-check-input"
                  />
                  <label htmlFor="specific-348" className="category-title">
                    Collaboration with other organisations
                  </label>
                </div>
                <div className="form-check">
                  <input
                    type="checkbox"
                    id="specific-348"
                    className="form-check-input"
                  />
                  <label htmlFor="specific-348" className="category-title">
                    None of the above
                  </label>
                </div>
              </div>
            </div>
            <div className="main-category">
              <h2 className="qus-title">
                Does your organisation offer services in languages other than
                English?
              </h2>
              <div className="input-box">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Type Answer Here..."
                  value=""
                />
              </div>
            </div>
            <div className="main-category">
              <h2 className="qus-title">
                What languages do you offer services in?
              </h2>
              <div className="category-list border-0 pt-0">
                <div className="form-check">
                  <input
                    type="checkbox"
                    id="specific-349"
                    className="form-check-input"
                  />
                  <label htmlFor="specific-349" className="category-title">
                    Bulgarian
                  </label>
                </div>
                <div className="form-check">
                  <input
                    type="checkbox"
                    id="specific-348"
                    className="form-check-input"
                  />
                  <label htmlFor="specific-348" className="category-title">
                    Chinese dialect
                  </label>
                </div>
                <div className="form-check">
                  <input
                    type="checkbox"
                    id="specific-348"
                    className="form-check-input"
                  />
                  <label htmlFor="specific-348" className="category-title">
                    Hungarian
                  </label>
                </div>
                <div className="form-check">
                  <input
                    type="checkbox"
                    id="specific-348"
                    className="form-check-input"
                  />
                  <label htmlFor="specific-348" className="category-title">
                    Italian
                  </label>
                </div>
                <div className="form-check">
                  <input
                    type="checkbox"
                    id="specific-348"
                    className="form-check-input"
                  />
                  <label htmlFor="specific-348" className="category-title">
                    Nepalese
                  </label>
                </div>
                <div className="form-check">
                  <input
                    type="checkbox"
                    id="specific-348"
                    className="form-check-input"
                  />
                  <label htmlFor="specific-348" className="category-title">
                    Polish
                  </label>
                </div>
                <div className="form-check">
                  <input
                    type="checkbox"
                    id="specific-348"
                    className="form-check-input"
                  />
                  <label htmlFor="specific-348" className="category-title">
                    Portuguese
                  </label>
                </div>
                <div className="form-check">
                  <input
                    type="checkbox"
                    id="specific-348"
                    className="form-check-input"
                  />
                  <label htmlFor="specific-348" className="category-title">
                    Romanian
                  </label>
                </div>
                <div className="form-check">
                  <input
                    type="checkbox"
                    id="specific-348"
                    className="form-check-input"
                  />
                  <label htmlFor="specific-348" className="category-title">
                    Spanish
                  </label>
                </div>
                <div className="form-check">
                  <input
                    type="checkbox"
                    id="specific-348"
                    className="form-check-input"
                  />
                  <label htmlFor="specific-348" className="category-title">
                    Urdu
                  </label>
                </div>
                <div className="form-check">
                  <input
                    type="checkbox"
                    id="specific-348"
                    className="form-check-input"
                  />
                  <label htmlFor="specific-348" className="category-title">
                    Other
                  </label>
                </div>
              </div>
            </div>
            <button className="next-page login-btn">Submit</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Organisation3;
