import axios from "axios";
import {
  SURVEY_BANNER_LIST_FAILURE,
  SURVEY_BANNER_LIST_REQUEST,
  SURVEY_BANNER_LIST_SUCCESS,
} from "../Constatnts/BannerConstant";

export const getSurveyBannerList = (data) => async (dispatch) => {
  try {
    dispatch({ type: SURVEY_BANNER_LIST_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/banner/get_all_data`,

      {
        headers: {
          "content-type": "application/json",
        },
      },
      data
    );
    dispatch({ type: SURVEY_BANNER_LIST_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: SURVEY_BANNER_LIST_FAILURE });
  }
};
