import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { getSurveyBannerList } from "../../store/Action/BannerAction";
import { useDispatch, useSelector } from "react-redux";

const HomeBanner = () => {
  const dispatch = useDispatch();
  const { bannerList } = useSelector((state) => state.banner);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    if (!bannerList) {
      dispatch(getSurveyBannerList());
    }
  }, [bannerList, dispatch]);

  React.useEffect(() => {
    if (bannerList) {
      setLoading(false);
    }
  }, [bannerList]);

  return (
    <React.Fragment>
      {loading ? (
        <div style={{ height: "400px" }}></div>
      ) : bannerList &&
        bannerList.code === 200 &&
        bannerList.data &&
        bannerList.data.length > 0 ? (
        bannerList.data.map((banner, bannerIndex) => (
          <section className="home-banner-slide" key={bannerIndex}>
            <OwlCarousel className="owl-theme" loop margin={10} items={1} nav>
              {bannerList.data.map((bannerItem, index) => (
                <div
                  key={index}
                  className="banner mx-45"
                  style={{ backgroundImage: `url(${bannerItem.vImage})` }}
                >
                  <div className="container">
                    <div className="row">
                      <div className="col-xl-10 col-lg-11 m-auto">
                        <div className="banner-content">
                          <h2 className="banner-title">
                            {bannerItem.vHeading
                              .split(" ")
                              .slice(0, 2)
                              .join(" ")}{" "}
                            <span>{bannerItem.vHeading.split(" ")[2]}</span>{" "}
                            {bannerItem.vHeading.split(" ").slice(3).join(" ")}
                          </h2>
                          <p className="banner-desc">
                            {bannerItem.vSubHeading}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </OwlCarousel>
          </section>
        ))
      ) : (
        <div className="col-12">
          <p
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "10px 0",
            }}
          >
            No Data Found
          </p>
        </div>
      )}
    </React.Fragment>
  );
};

export default HomeBanner;
