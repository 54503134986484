import React, { useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { getUserLogin, ResetState } from "../../store/Action/UserAction";
import { useDispatch, useSelector } from "react-redux";
import { Toast } from "primereact/toast";

const Login = () => {
  const captchaRef = useRef(null);
  const toast = React.useRef(null);
  const navigate = useNavigate();
  const googleSiteKey = "6LeYe08pAAAAAPJnjWVDU47L949QpyoidOH1PkZO";

  const dispatch = useDispatch();
  const { state } = useLocation();



  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  const [emailError, setEmailError] = React.useState("");
  const [passwordError, setPasswordError] = React.useState("");
  const [recaptchaError, setRecaptchaError] = React.useState("");

  const [recaptchaValue, setRecaptchaValue] = React.useState("");

  const [showPassword, setShowPassword] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const { userLoginData } = useSelector((state) => state.userLogin);

  React.useEffect(() => {
    const executeRecaptcha = async () => {
      try {
        const token = await captchaRef.current.executeAsync();
        setRecaptchaValue(token);
      } catch (error) {
        // console.log(error);
      }
    };

    executeRecaptcha();
  }, []);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleEmailChange = (event) => {
    const emailValue = event.target.value.trim();
    setEmail(emailValue);

    event.target.value.trim() !== "" && setEmailError("");

    // Regular expsion for basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (emailValue.trim() === "" && emailValue.length === 0) {
      setEmailError("Email is required.");
    } else if (!emailRegex.test(emailValue)) {
      setEmailError("Invalid email format.");
    } else {
      setEmailError("");
    }
  };

  const handlePasswordChange = (event) => {
    const passwordValue = event.target.value.trim();
    setPassword(passwordValue);

    event.target.value.trim() !== "" && setPasswordError("");

    if (passwordValue.trim() === "" && passwordValue.length === 0) {
      setPasswordError("Password is required.");
    } else if (passwordValue.length < 6) {
      setPasswordError("Password must be at least 6 characters.");
    } else {
      setPasswordError("");
    }
  };

  const handleCaptchaChange = (token) => {
    setRecaptchaValue(token);
  };

  const isValidEmail = React.useCallback((email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  }, []);

  const validateSignInForm = React.useCallback(() => {
    let isValid = true;

    setEmailError("");
    setPasswordError("");
    setRecaptchaError("");

    if (email.length === 0) {
      setEmailError("Email is required.");
      isValid = false;
    } else if (!isValidEmail(email)) {
      setEmailError("Invalid Email Format.");
      isValid = false;
    }

    if (password.length === 0) {
      setPasswordError("Pasword is Required.");
      isValid = false;
    } else if (password.length < 6) {
      setPasswordError("Password must be at least 6 characters.");
      isValid = false;
    }

    if (!recaptchaValue) {
      setRecaptchaError("ReCAPTCHA verification failed. Please try again.");
      isValid = false;
    }

    return isValid;
  }, [email, password, isValidEmail, recaptchaValue]);

  const signInUser = React.useCallback(
    async (e) => {
      e.preventDefault();

      const isValideData = validateSignInForm();

      let newToken;
      try {
        if (captchaRef.current) {
          newToken = await captchaRef.current.executeAsync();
          setRecaptchaValue(newToken);
        } else {
          setRecaptchaError("ReCAPTCHA failed to load. Please try again.");
        }
      } catch (error) {
        setRecaptchaError("ReCAPTCHA verification failed. Please try again.");
        setLoading(false);

        // console.error("ReCAPTCHA error:", error);
        return;
      }

      if (isValideData) {
        dispatch(
          getUserLogin({
            vEmail: email,
            vPassword: password,
            vGoogleRecaptchaResponse: newToken,
          })
        );
        setLoading(true);
      } else {
        setLoading(false);
      }
    },
    [dispatch, validateSignInForm, email, password]
  );

  React.useEffect(() => {
    if (state) {
      if (userLoginData && userLoginData.code === 200) {
        // setLoading(true)
        dispatch(ResetState());
        toast.current.show({
          severity: "success",
          summary: "User Login Successfully.",
          life: 2000,
          className: "custom-toast",
        });
        setTimeout(() => {
          setEmail("");
          setPassword("");
          setRecaptchaValue("");
          setLoading(false);

          navigate("/project-details", { state: state });
          if (captchaRef.current) {
            captchaRef.current.reset();
            captchaRef.current.executeAsync().then((token) => {
              setRecaptchaValue(token);
            });
          }
        }, 2000);
      }
    } else if (!state && userLoginData && userLoginData.code === 200) {
      // setLoading(true)
      dispatch(ResetState());
      toast.current.show({
        severity: "success",
        summary: "User Login Successfully.",
        life: 2000,
        className: "custom-toast",
      });
      setTimeout(() => {
        setEmail("");
        setPassword("");
        setRecaptchaValue("");
        setLoading(false);

        navigate("/dashboard");
        if (captchaRef.current) {
          captchaRef.current.reset();
          captchaRef.current.executeAsync().then((token) => {
            setRecaptchaValue(token);
          });
        }
      }, 2000);
    } else if (userLoginData && userLoginData.code === 400) {
      setLoading(false);
      dispatch(ResetState());
      toast.current.show({
        severity: "error",
        summary: userLoginData.message,
        life: 2000,
        className: "custom-toast-error",
      });
      setTimeout(() => {
        setEmail("");
        setPassword("");
        setRecaptchaValue("");

        if (captchaRef.current) {
          captchaRef.current.reset();
          captchaRef.current.executeAsync().then((token) => {
            setRecaptchaValue(token);
          });
        }
      }, 2000);
    } else if (userLoginData && userLoginData.code === 401) {
      setLoading(false);
      dispatch(ResetState());
      toast.current.show({
        severity: "error",
        summary: userLoginData.message,
        life: 2000,
        className: "custom-toast-error",
      });
      setTimeout(() => {
        setEmail("");
        setPassword("");
        setRecaptchaValue("");

        if (captchaRef.current) {
          captchaRef.current.reset();
          captchaRef.current.executeAsync().then((token) => {
            setRecaptchaValue(token);
          });
        }
      }, 2000);
    }
  }, [userLoginData, dispatch, navigate, state]);

  return (
    <React.Fragment>
      <section className="login">
        <Toast ref={toast} />
        <div className="container">
          <div className="row g-0">
            <div className="col-lg-6">
              <div className="login-img">
                <img
                  src="assets/image/login.jpg"
                  alt="loginimage"
                  width="800"
                  height="791"
                />
                <div className="login-img-content">
                  <h1 className="title">Welcome to Our Website</h1>
                  <p className="desc">
                    Lorem ipsum dolor sit amet consectetur. Sapien sed in tempor
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="login-right-view">
                <div className="login-form-logo">
                  <Link to="/">
                    <img
                      src="assets/image/login-logo.png"
                      alt="loginlogoimage"
                      width="296"
                      height="68"
                    />
                  </Link>
                </div>
                <div className="login-form-content">
                  <h2 className="title">Log In</h2>
                  <p className="desc">Please Sign in to Your Account.</p>
                </div>
                <form onSubmit={signInUser}>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="input-box">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="form-label"
                        >
                          Email address
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Enter Email Address"
                          value={email}
                          onChange={handleEmailChange}
                        />
                        {emailError && (
                          <div
                            className="error-msg"
                            style={{
                              color: "red",
                              fontSize: "14px",
                              marginLeft: "5px",
                            }}
                          >
                            {emailError}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="input-box">
                        <label
                          htmlFor="exampleInputPassword1"
                          className="form-label"
                        >
                          Password
                        </label>
                        <input
                          type={showPassword ? "text" : "password"}
                          className="form-control"
                          placeholder="Enter Password"
                          value={password}
                          onChange={handlePasswordChange}
                        />
                        <i
                          className={`toggle-password far ${
                            showPassword ? "fa-eye" : "fa-eye-slash"
                          }`}
                          onClick={togglePasswordVisibility}
                        ></i>
                        {passwordError && (
                          <div
                            className="error-msg"
                            style={{
                              color: "red",
                              fontSize: "14px",
                              marginLeft: "5px",
                            }}
                          >
                            {passwordError}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="input-box form-check forgate">
                        <Link className="forgate-pass" to="/forgot-password">
                          Forgot Password ?
                        </Link>
                      </div>
                    </div>

                    <ReCAPTCHA
                      ref={captchaRef}
                      className="g-recaptcha"
                      sitekey={googleSiteKey}
                      size="invisible"
                      onChange={handleCaptchaChange}
                    />

                    {recaptchaError && (
                      <div
                        className="error-msg"
                        style={{
                          color: "red",
                          fontSize: "14px",
                          marginLeft: "5px",
                          marginBottom: "10px",
                        }}
                      >
                        {recaptchaError}
                      </div>
                    )}
                    <div className="col-md-12">
                      <div className="login-btn-view">
                        {loading ? (
                          <>
                            {" "}
                            <button
                              className="login-btn"
                              type="button"
                              disabled
                            >
                              <span
                                className="spinner-border spinner-border-sm me-1"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              Loading...
                            </button>
                          </>
                        ) : (
                          <>
                            {" "}
                            <button type="submit" className="login-btn">
                              Log In
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <p className="create-account">
                    Don’t have an account ?
                    <Link className="text" to="/register">
                      Sign Up
                    </Link>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Login;
