// import React from "react";

// import { useNavigate } from "react-router";

// const LogoutPage = () => {
//   const navigate = useNavigate();

//   React.useEffect(() => {
//     if (
//       !localStorage.getItem("vUniqueCode") ||
//       localStorage.getItem("vUniqueCode") === ""
//     ) {
//       navigate("/");
//     }
//   });

//   return <div>{localStorage.clear()}</div>;
// };

// export default LogoutPage;

import React from "react";
import { useNavigate } from "react-router";

const LogoutPage = () => {
  const navigate = useNavigate();

  React.useEffect(() => {
    // Remove the specific item from localStorage
    localStorage.removeItem("vUniqueCode");
    // localStorage.clear();

    // Navigate to the home page
    navigate("/");
  }, [navigate]);

  return null; // No need to return anything here
};

export default LogoutPage;
