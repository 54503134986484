import React, { Suspense } from "react";
import Header from "./Header";
import Footer from "./Footer";

function Container(props) {
  return (
    <React.Fragment>
      {/* <Suspense fallback={<div className="loeader-box"></div>}> */}
      <Header />
      {props.children}
      <Footer />
      {/* </Suspense> */}
    </React.Fragment>
  );
}

export default Container;
