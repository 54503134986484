import {
  COMPANY_DETAIL_LIST_FAILURE,
  COMPANY_DETAIL_LIST_REQUEST,
  COMPANY_DETAIL_LIST_SUCCESS,
  META_LIST_FAILURE,
  META_LIST_REQUEST,
  META_LIST_SUCCESS,
  SOCIAL_LIST_FAILURE,
  SOCIAL_LIST_REQUEST,
  SOCIAL_LIST_SUCCESS,
} from "../Constatnts/MetaConstant";
import axios from "axios";

export const getSocialList = (data) => async (dispatch) => {
  try {
    dispatch({ type: SOCIAL_LIST_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/setting/social`,

      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    dispatch({ type: SOCIAL_LIST_SUCCESS, payload: resData.data });
  } catch (err) {
    // console.log(err);
    dispatch({ type: SOCIAL_LIST_FAILURE });
  }
};

export const getCompanyDetailList = (data) => async (dispatch) => {
  try {
    dispatch({ type: COMPANY_DETAIL_LIST_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/setting/company`,

      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    dispatch({ type: COMPANY_DETAIL_LIST_SUCCESS, payload: resData.data });
  } catch (err) {
    // console.log(err);
    dispatch({ type: COMPANY_DETAIL_LIST_FAILURE });
  }
};

export const getMetaList = (data) => async (dispatch) => {
  try {
    dispatch({ type: META_LIST_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/meta/get_by_id`,

      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    dispatch({ type: META_LIST_SUCCESS, payload: resData.data });
  } catch (err) {
    // console.log(err);
    dispatch({ type: META_LIST_FAILURE });
  }
};
