import React from "react";
import Container from "../components/Layouts/Container";
import PrivacyPolicy from "../components/Common/PrivacyPolicy";

const PrivacyPolicyPage = () => {
  return (
    <React.Fragment>
      <Container>
        <PrivacyPolicy />
      </Container>
    </React.Fragment>
  );
};

export default PrivacyPolicyPage;
