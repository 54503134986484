import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getPageList } from "../../store/Action/PageAction";

const PrivacyPolicy = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const aboutSlug = location.pathname.split("/")[1];
  const { pageListData } = useSelector((state) => state.pageList);

  React.useEffect(() => {
    dispatch(
      getPageList({
        vSlug: aboutSlug,
        eStatus: "Active",
        eDelete: "No",
      })
    );
  }, [dispatch, aboutSlug]);

  let decodedString = "";

  if (pageListData && pageListData.code === 200 && pageListData.data) {
    const base64Strings = pageListData.data.map((about) => about.tDescription);

    decodedString = atob(base64Strings);
  }

  return (
    <React.Fragment>
      <section className="static-page">
        <div className="container">
          <div className="row">
            <div
              className="col-md-10 mx-auto"
            >
              <div dangerouslySetInnerHTML={{ __html: decodedString }} />
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default PrivacyPolicy;
