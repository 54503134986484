import React from "react";
import Header from "../Layouts/Header";

const Organisation2 = () => {
  return (
    <>
    <Header/>
      <div className="questions-wrp survey-category questions2 survey-page">
        <div className="container">
          <div className="questions2-body survey-content">
          <div className="main-category">
            <div className="row">
            <div class="col-lg-6">
            <h2 class="qus-title">Name of your organisation</h2>
              <div class="input-box">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Type Answer Here..."
                  value=""
                />
              </div>
            </div>
            <div class="col-lg-6">
            <h2 class="qus-title">Organisation postcode</h2>
            <div class="input-box">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Type Answer Here..."
                  value=""
                />                                                                  
              </div>
            </div>
            <div class="col-lg-6">
            <h2 class="qus-title">Organisation contact email address</h2>
            <div class="input-box">                                                                                                       
                <input
                  type="text"
                  class="form-control"
                  placeholder="Type Answer Here..."
                  value=""
                />
              </div>
              </div>
          </div>
          </div>
    
          <div class="main-category">
            <div className="row">
            <div class="col-lg-6">
              <h2 class="qus-title">Your role in the organisation</h2>
                <div class="other-select-wrp">
                  <div class="select-dropdown">
                    <select id="dropdown-38" name="dropdown-38">
                      <option value="">Select Any Option</option>
                      <option value="354">Volunteer</option>
                      <option value="353">Trustee</option>
                      <option value="352">CEO</option>
                      <option value="354">Accounts/Finance</option>
                      <option value="353">HR officer</option>
                      <option value="352">Other</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <h2 class="qus-title">What type of organisation are you?</h2>
              <div class="other-select-wrp">
                  <div class="select-dropdown">
                    <select id="dropdown-38" name="dropdown-38">
                      <option value="">Select Any Option</option>
                      <option value="Registered charity">Registered charity</option>
                      <option value="353"> Public service mutual</option>
                      <option value="352">Social enterprise</option>
                      <option value="353"> Community group</option>
                      <option value="352">Faith-based organisation</option>
                      <option value="352"> Other</option>
                    </select>
                  </div>
                </div>
            </div>
            </div>
          </div>
          <button class="next-page login-btn mt-0">Submit</button>
        </div>
      </div>
      </div>
    </>
  );
};

export default Organisation2;
