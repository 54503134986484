import React from "react";
import { Link } from "react-router-dom";

const SuccessResetPasswordPage = () => {
  return (
    <>
      <div className="highlight-msg">
        <Link to={"/"} className="login-form-logo">
          <img src="assets/image/login-logo.png" alt="project logo" />
        </Link>
        <div className="text">
          <h1 className="success-color" style={{ color: "green" }}>
            Success !!
          </h1>
          <p>
            We have sent you email with reset password link. Please check your
            email for reset password. If you don't see email, check spam folder
            once.
          </p>
          <div className="text-center mt-3">
            <Link to={"/login"} className="login-btn">
              Continue
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default SuccessResetPasswordPage;
