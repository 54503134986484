import React from "react";
import DashboardMenu from "../Dashboard/DashboardMenu";
import {
  getInvitationCountList,
  getInvitationList,
  getSurveyCountList,
} from "../../store/Action/SurveyAction";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const moment = require("moment");

  const { surveyListData } = useSelector((state) => state.surveyList);
  const { totalSurveyCountListData } = useSelector(
    (state) => state.totalSurveyCountList
  );
  const { totalInvitationCountListData } = useSelector(
    (state) => state.totalInvitationCountList
  );

  const UniqueCode = localStorage.getItem("vUniqueCode");

  const defaultImage = "../assets/image/about-count-3.png";

  React.useEffect(() => {
    if (!totalSurveyCountListData) {
      dispatch(getSurveyCountList());
    }
  }, [dispatch, totalSurveyCountListData]);

  React.useEffect(() => {
    if (!totalInvitationCountListData) {
      dispatch(
        getInvitationCountList({
          vUniqueCode: UniqueCode,
        })
      );
    }
  }, [dispatch, totalInvitationCountListData, UniqueCode]);

  const options = {
    chart: {
      type: "column",
    },
    title: {
      text: "Survey Bar Chart",
    },
    xAxis: {
      categories: [
        "Survey1",
        "Survey2",
        "Survey3",
        "Survey4",
        "Survey5",
        "Survey6",
        "Survey7",
        "Survey8",
        "Survey9",
        "Survey10",
        "Survey11",
        "Survey12",
      ],
    },
    yAxis: {
      title: {
        text: "Values",
      },
    },
    series: [
      {
        name: "Survey Data",
        data: [10, 30, 20, 40, 25, 35, 15, 45, 20, 30, 40, 50],
      },
    ],
  };

  //Invitation List Code //
  const { invitationListData, loading } = useSelector(
    (state) => state.invitationList
  );

  React.useEffect(() => {
    dispatch(
      getInvitationList({
        vUniqueCode: UniqueCode,
      })
    );
  }, [dispatch, UniqueCode]);

  React.useEffect(() => {
    if (invitationListData && invitationListData.code === 200) {
      // console.log("data Updated");
    }
  }, [invitationListData]);

  const handelProceed = (code, Id, Name) => {
    navigate(`/project-details`, {
      state: {
        surveyCode: code,
        surveyId: Id,
        SurveyType: Name,
      },
    });
  };

  return (
    <section className="my-profile">
      <div className="dashboard-main-content">
        <DashboardMenu />

        <div className="profile-right-view-bg dashboard-right-view">
          <div className="mb-30">
            <div className="dashbox-list">
              {totalSurveyCountListData &&
              totalSurveyCountListData.code === 200 &&
              totalSurveyCountListData?.data?.totalSurvey === 0 ? (
                ""
              ) : (
                <div className="survey-box-main-wrap">
                  <div className="survey-box">
                    <h1 className="title">Survey</h1>
                    <div className="survey-total">
                      {totalSurveyCountListData?.data?.totalSurvey}
                    </div>
                  </div>
                </div>
              )}
              {totalInvitationCountListData &&
                totalInvitationCountListData.code === 200 &&
                (totalInvitationCountListData?.data?.Pending === 0 ? (
                  ""
                ) : (
                  <div className="survey-box-main-wrap">
                    <div className="survey-box">
                      <h2 className="title">Invitation</h2>
                      <div className="survey-total">
                        {" "}
                        {totalInvitationCountListData?.data?.Pending}
                      </div>
                    </div>
                  </div>
                ))}
              {totalInvitationCountListData &&
                totalInvitationCountListData.code === 200 &&
                (totalInvitationCountListData?.data?.Completed === 0 ? (
                  ""
                ) : (
                  <div className="survey-box-main-wrap">
                    <div className="survey-box">
                      <h3 className="title">Completed Survey</h3>
                      <div className="survey-total">
                        {" "}
                        {totalInvitationCountListData?.data?.Completed}
                      </div>
                    </div>
                  </div>
                ))}

              {totalInvitationCountListData &&
                totalInvitationCountListData.code === 200 &&
                (totalInvitationCountListData?.data?.Pending === 0 ? (
                  ""
                ) : (
                  <div className="survey-box-main-wrap">
                    <div className="survey-box">
                      <h3 className="title">Pending Survey</h3>
                      <div className="survey-total">
                        {" "}
                        {totalInvitationCountListData?.data?.Pending}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>

          <div className="row g-3">
            <div className="col-lg-12">
              <div className="survey-result">
                <h3 className="title">Invitation</h3>
                <div className="table-responsive">
                  {loading ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "50px",
                        marginBottom: "50px",
                      }}
                    >
                      {loading && (
                        <img
                          src="/assets/gif/350.gif"
                          className="loading-gif"
                          alt="Loading..."
                        />
                      )}
                    </div>
                  ) : (
                    <>
                      {invitationListData &&
                      invitationListData.code === 200 &&
                      invitationListData.data ? (
                        <table className="table">
                          <thead>
                            <tr>
                              <th className="text-center">No</th>
                              <th className="text-center">Image</th>
                              <th className="text-center">Name</th>
                              <th className="text-center">Date</th>
                              <th className="text-center">Status</th>
                              <th className="text-center">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {invitationListData &&
                              invitationListData.data &&
                              invitationListData.data &&
                              invitationListData.data.Data &&
                              invitationListData.data.Data.inviteSurvey.map(
                                (invitation, invitationIndex) =>
                                  invitation?.survey !== null && (
                                    <tr key={invitationIndex}>
                                      <td className="text-center">
                                        {invitationIndex + 1}
                                      </td>
                                      <td className="text-center">
                                        <div className="table-img">
                                          <img
                                            src={
                                              invitation &&
                                              invitation?.survey &&
                                              invitation?.survey?.vWebpImage
                                                ?.split("/")
                                                ?.pop() === "null"
                                                ? defaultImage
                                                : invitation?.survey?.vWebpImage
                                            }
                                            alt={invitation?.survey?.vImageAlt}
                                            width="40"
                                            height="40"
                                          />
                                        </div>
                                      </td>
                                      <td className="text-center">
                                        {invitation?.survey?.vSurvey}
                                      </td>
                                      <td className="text-center">
                                        {" "}
                                        {moment(invitation?.dtAddedDate).format(
                                          "DD-MM-YYYY"
                                        )}
                                      </td>
                                      <td className="text-center">
                                        {invitation?.eStatus}
                                      </td>
                                      {invitation &&
                                      invitation.eStatus === "Completed" ? (
                                        <>
                                          <td className="text-center">
                                            <div className="table-btn">
                                              <button
                                                className="show-result"
                                                style={{
                                                  backgroundColor: "green",
                                                  border: "none",
                                                }}
                                              >
                                                Completed
                                              </button>
                                            </div>
                                          </td>
                                        </>
                                      ) : invitation &&
                                        invitation.eStatus === "Pending" ? (
                                        <>
                                          <td className="text-center">
                                            <div className="table-btn">
                                              <button
                                                onClick={() =>
                                                  handelProceed(
                                                    invitation &&
                                                      invitation?.survey &&
                                                      invitation?.survey
                                                        ?.vUniqueCode,
                                                    invitation?.iSurveyId,
                                                    invitation &&
                                                      invitation?.survey &&
                                                      invitation?.survey
                                                        ?.eSurveyType
                                                  )
                                                }
                                                className="show-result"
                                              >
                                                Proceed
                                              </button>
                                            </div>
                                          </td>
                                        </>
                                      ) : null}
                                    </tr>
                                  )
                              )}
                          </tbody>
                        </table>
                      ) : (
                        <div className="col-12">
                          <p
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              padding: "10px 0",
                              marginTop: "10px",
                            }}
                          >
                            No Data Found
                          </p>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="survey-result">
                <h3 className="title">Chart (Data Visualizaton)</h3>
                <div className="dashboard-chart">
                  {/* <p> Dashboard Chart</p> */}

                  <HighchartsReact highcharts={Highcharts} options={options} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Dashboard;
