import React from "react";
import Header from "../Layouts/Header";
import { useDispatch, useSelector } from "react-redux";
import { getSurveyQuestionList } from "../../store/Action/SurveyAction";

const QuestionFour = () => {
  const dispatch = useDispatch();
  const [showpurposes, setShowPurposes] = React.useState(false);
  const [showeducation, setShowEducation] = React.useState(false);
  const [showadvancement, setShowAdvancement] = React.useState(false);
  const [showinternational, setShowInternational] = React.useState(false);
  const [showprevention, setShowPrevention] = React.useState(false);
  const [showhousing, setShowHousing] = React.useState(false);
  const [showculture, setShowCulture] = React.useState(false);
  const [showamateur, setShowAmateur] = React.useState(false);

  const [showenvironment, setShowEnvironment] = React.useState(false);

  const [showarmed, setShowArmed] = React.useState(false);

  const [showharmony, setShowHarmony] = React.useState(false);

  const [showrecreation, setShowRecreation] = React.useState(false);
  const [showcharitable, setShowCharitable] = React.useState(false);


  const { surveyQuestionData } = useSelector((state) => state.surveyQuestion);

  // console.log("surveyQuestionData", surveyQuestionData?.data);

  React.useEffect(() => {
    if (!surveyQuestionData) {
      dispatch(
        getSurveyQuestionList({
          vUniqueCode:
            "8666683506aacd900bbd5a74ac4edf68fa8ec206a7257dfe8e51ef497c582755023a2ad965162b072e7a100eb39f57bc",
        })
      );
    }
  }, [surveyQuestionData, dispatch]);


  return (
    <>
      <Header />
      <div className="questions-wrp survey-category survey-page">
        <div className="container">
          <div className="main-category">
            <h2 className="qus-title">
              What was your target focal areas for research with the
              organisations?
            </h2>
            <div className="category-list border-0 pt-0">
              <div className="form-check">
                <input
                  type="checkbox"
                  id="purposes"
                  className="form-check-input"
                  onChange={() => setShowPurposes(!showpurposes)}
                />
                <label htmlFor="purposes" className="category-title">
                  General charitable purposes
                </label>
              </div>

              {/* sub category */}
              {showpurposes && (
                <div className="sub-category-list">
                  <div className="row g-3">
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Education"
                          className="form-check-input"
                        />
                        <label htmlFor="Education">Education</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Advocacy"
                          className="form-check-input"
                        />
                        <label htmlFor="Advocacy">Advocacy</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Information"
                          className="form-check-input"
                        />
                        <label htmlFor="Information">Advice/Information</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="equipment"
                          className="form-check-input"
                        />
                        <label htmlFor="equipment">Provides equipment</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="goods"
                          className="form-check-input"
                        />
                        <label htmlFor="goods">goods</label>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="category-list">
              <div className="form-check">
                <input
                  type="checkbox"
                  id="EducationTraining"
                  className="form-check-input"
                  onChange={() => setShowEducation(!showeducation)}
                />
                <label htmlFor="EducationTraining" className="category-title">
                  General Education/Training
                </label>
              </div>

              {/* sub category */}
              {showeducation && (
                <div className="sub-category-list">
                  <div className="row g-3">
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="school"
                          className="form-check-input"
                        />
                        <label htmlFor="school">Pre-school</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Primary"
                          className="form-check-input"
                        />
                        <label htmlFor="Primary">Primary</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Secondary"
                          className="form-check-input"
                        />
                        <label htmlFor="Secondary">Secondary</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Further"
                          className="form-check-input"
                        />
                        <label htmlFor="Further">Further education</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Higher"
                          className="form-check-input"
                        />
                        <label htmlFor="Higher">Higher education</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="vocational"
                          className="form-check-input"
                        />
                        <label htmlFor="vocational">
                          Technical vocational training
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Employability"
                          className="form-check-input"
                        />
                        <label htmlFor="Employability">Employability</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="training"
                          className="form-check-input"
                        />
                        <label htmlFor="training">Other education/training</label>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="category-list">
              <div className="form-check">
                <input
                  type="checkbox"
                  id="Advancement"
                  className="form-check-input"
                  onChange={() => setShowAdvancement(!showadvancement)}
                />
                <label htmlFor="Advancement" className="category-title">
                  Advancement of health
                </label>
              </div>

              {/* sub category */}
              {showadvancement && (
                <div className="sub-category-list">
                  <div className="row g-3">
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Cancer"
                          className="form-check-input"
                        />
                        <label htmlFor="Cancer">Cancer</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="disease"
                          className="form-check-input"
                        />
                        <label htmlFor="disease">Heart disease</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Diabetes"
                          className="form-check-input"
                        />
                        <label htmlFor="Diabetes">Diabetes</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Dementia"
                          className="form-check-input"
                        />
                        <label htmlFor="Dementia">Dementia</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Mental"
                          className="form-check-input"
                        />
                        <label htmlFor="Mental">Mental Health</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Addiction"
                          className="form-check-input"
                        />
                        <label htmlFor="Addiction">
                          Addiction (Including substance abuse)
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Organ"
                          className="form-check-input"
                        />
                        <label htmlFor="Organ">Organ and tissue donation</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="wellbeing"
                          className="form-check-input"
                        />
                        <label htmlFor="wellbeing">
                          Promotion of health and wellbeing
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="prevention"
                          className="form-check-input"
                        />
                        <label htmlFor="prevention">
                          Public health and other sickness prevention
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="sickness"
                          className="form-check-input"
                        />
                        <label htmlFor="sickness">
                          Relief of sickness generally
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="category-list">
              <div className="form-check">
                <input
                  type="checkbox"
                  id="Prevention"
                  className="form-check-input"
                  onChange={() => setShowPrevention(!showprevention)}
                />
                <label htmlFor="Prevention" className="category-title">
                  Prevention or relief of poverty
                </label>
              </div>

              {/* sub category */}
              {showprevention && (
                <div className="sub-category-list">
                  <div className="row g-3">
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Unemployment"
                          className="form-check-input"
                        />
                        <label htmlFor="Unemployment">Unemployment support</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Food"
                          className="form-check-input"
                        />
                        <label htmlFor="Food">Food back</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Homelessness"
                          className="form-check-input"
                        />
                        <label htmlFor="Homelessness">Homelessness support</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Campaigning"
                          className="form-check-input"
                        />
                        <label htmlFor="Campaigning">Campaigning</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Refugee"
                          className="form-check-input"
                        />
                        <label htmlFor="Refugee">Refugee support</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="education"
                          className="form-check-input"
                        />
                        <label htmlFor="education">Education</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Other1"
                          className="form-check-input"
                        />
                        <label htmlFor="Other1">Other</label>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="category-list">
              <div className="form-check">
                <input
                  type="checkbox"
                  id="International"
                  className="form-check-input"
                  onChange={() => setShowInternational(!showinternational)}
                />
                <label htmlFor="International" className="category-title">
                  International development/Humanitarian aid
                </label>
              </div>

              {/* sub category */}
              {showinternational && (
                <div className="sub-category-list">
                  <div className="row g-3">
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="donation"
                          className="form-check-input"
                        />
                        <label htmlFor="donation">Food donation</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="aid"
                          className="form-check-input"
                        />
                        <label htmlFor="aid">Medical aid</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Capacity"
                          className="form-check-input"
                        />
                        <label htmlFor="Capacity">Capacity Building</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Provides"
                          className="form-check-input"
                        />
                        <label htmlFor="Provides">Provides equipment/goods,</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="sanitation"
                          className="form-check-input"
                        />
                        <label htmlFor="sanitation">Water/sanitation</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="programmes"
                          className="form-check-input"
                        />
                        <label htmlFor="programmes">
                          Exchange/Cultural programmes
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Other2"
                          className="form-check-input"
                        />
                        <label htmlFor="Other2">Other</label>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="category-list">
              <div className="form-check">
                <input
                  type="checkbox"
                  id="Housing"
                  className="form-check-input"
                  onChange={() => setShowHousing(!showhousing)}
                />
                <label htmlFor="Housing" className="category-title">
                  Housing
                </label>
              </div>

              {/* sub category */}
              {showhousing && (
                <div className="sub-category-list">
                  <div className="row g-3">
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Association"
                          className="form-check-input"
                        />
                        <label htmlFor="Association">Housing Association</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="accommodation"
                          className="form-check-input"
                        />
                        <label htmlFor="accommodation">
                          Temporary accommodation
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Other3"
                          className="form-check-input"
                        />
                        <label htmlFor="Other3">Other</label>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="category-list">
              <div className="form-check">
                <input
                  type="checkbox"
                  id="Culture"
                  className="form-check-input"
                  onChange={() => setShowCulture(!showculture)}
                />
                <label htmlFor="Culture" className="category-title">
                  Arts/Culture/Heritage/Science
                </label>
              </div>

              {/* sub category */}
              {showculture && (
                <div className="sub-category-list">
                  <div className="row g-3">
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Preservation"
                          className="form-check-input"
                        />
                        <label htmlFor="Preservation">
                          Preservation of historical or cultural traditions
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="historical"
                          className="form-check-input"
                        />
                        <label htmlFor="historical">
                          Preservation of historical buildings or ancient sites
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="monuments"
                          className="form-check-input"
                        />
                        <label htmlFor="monuments">
                          Preservation of monuments, Preservation of historical
                          or cultural artefacts
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Festivals"
                          className="form-check-input"
                        />
                        <label htmlFor="Festivals">Festivals</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Museum"
                          className="form-check-input"
                        />
                        <label htmlFor="Museum">Museum of gallery</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="other"
                          className="form-check-input"
                        />
                        <label htmlFor="other">other</label>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="category-list">
              <div className="form-check">
                <input
                  type="checkbox"
                  id="Amateur"
                  className="form-check-input"
                  onChange={() => setShowAmateur(!showamateur)}
                />
                <label htmlFor="Amateur" className="category-title">
                  Amateur sport
                </label>
              </div>

              {/* sub category */}
              {showamateur && (
                <div className="sub-category-list">
                  <div className="row g-3">
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="sport-governing-body"
                          className="form-check-input"
                        />
                        <label htmlFor="sport-governing-body">Sport governing body</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="venue"
                          className="form-check-input"
                        />
                        <label htmlFor="venue">Sorts venue</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Sports"
                          className="form-check-input"
                        />
                        <label htmlFor="Sports">Sports Clum/Association</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Other4"
                          className="form-check-input"
                        />
                        <label htmlFor="Other4">Other</label>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="category-list">
              <div className="form-check">
                <input
                  type="checkbox"
                  id="Environment"
                  className="form-check-input"
                  onChange={() => setShowEnvironment(!showenvironment)}
                />
                <label for="Environment" className="category-title">
                  Environment/Conservation
                </label>
              </div>

              {/* sub category */}
              {showenvironment && (
                <div className="sub-category-list">
                  <div className="row g-3">
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Conservation"
                          className="form-check-input"
                        />
                        <label for="Conservation">
                          Conservation of environment
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="geographic"
                          className="form-check-input"
                        />
                        <label for="geographic">
                          Conservation of particular geographic area
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="species"
                          className="form-check-input"
                        />
                        <label for="species">
                          Conservation of particular species
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="resources"
                          className="form-check-input"
                        />
                        <label for="resources">
                          Conserving natural resources
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Other5"
                          className="form-check-input"
                        />
                        <label for="Other5">Other</label>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="category-list">
              <div className="form-check">
                <input
                  type="checkbox"
                  id="Armed"
                  className="form-check-input"
                  onChange={() => setShowArmed(!showarmed)}
                />
                <label for="Armed" className="category-title">
                  Armed Forces/Emergency services
                </label>
              </div>

              {/* sub category */}
              {showarmed && (
                <div className="sub-category-list">
                  <div className="row g-3">
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Memorials"
                          className="form-check-input"
                        />
                        <label for="Memorials">Memorials</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Veterans"
                          className="form-check-input"
                        />
                        <label for="Veterans">Veterans support</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="personnel"
                          className="form-check-input"
                        />
                        <label for="personnel">Service personnel support</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Convalescence"
                          className="form-check-input"
                        />
                        <label for="Convalescence">Convalescence</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Other6"
                          className="form-check-input"
                        />
                        <label for="Other6">Other</label>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="category-list">
              <div className="form-check">
                <input
                  type="checkbox"
                  id="Harmony"
                  className="form-check-input"
                  onChange={() => setShowHarmony(!showharmony)}
                />
                <label for="Harmony" className="category-title">
                  Human Rights/Religious Harmony, Racial Harmony, Equality or
                  Diversity
                </label>
              </div>

              {/* sub category */}
              {showharmony && (
                <div className="sub-category-list">
                  <div className="row g-3">
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Human"
                          className="form-check-input"
                        />
                        <label for="Human">Human Rights</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="reconciliation"
                          className="form-check-input"
                        />
                        <label for="reconciliation">
                          Conflict resolution/reconciliation
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Religious"
                          className="form-check-input"
                        />
                        <label for="Religious">Religious harmony</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Racial"
                          className="form-check-input"
                        />
                        <label for="Racial">Racial harmony</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Equality"
                          className="form-check-input"
                        />
                        <label for="Equality">Equality and diversity</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Civil"
                          className="form-check-input"
                        />
                        <label for="Civil">Civil rights</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Other7"
                          className="form-check-input"
                        />
                        <label for="Other7">Other</label>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="category-list">
              <div className="form-check">
                <input
                  type="checkbox"
                  id="Recreation"
                  className="form-check-input"
                  onChange={() => setShowRecreation(!showrecreation)}
                />
                <label for="Recreation" className="category-title">
                  Recreation
                </label>
              </div>

              {/* sub category */}
              {showrecreation && (
                <div className="sub-category-list">
                  <div className="row g-3">
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Village"
                          className="form-check-input"
                        />
                        <label for="Village">Village hall,</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="centre"
                          className="form-check-input"
                        />
                        <label for="centre">community centre</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="recreation-ground"
                          className="form-check-input"
                        />
                        <label for="recreation-ground">Recreation ground</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="space"
                          className="form-check-input"
                        />
                        <label for="space">Open space</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Leisure"
                          className="form-check-input"
                        />
                        <label for="Leisure">Leisure centre</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Youth"
                          className="form-check-input"
                        />
                        <label for="Youth">Youth centre</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Other8"
                          className="form-check-input"
                        />
                        <label for="Other8">Other</label>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="category-list">
              <div className="form-check">
                <input
                  type="checkbox"
                  id="charitable"
                  className="form-check-input"
                  onChange={() => setShowCharitable(!showcharitable)}
                />
                <label for="charitable" className="category-title">
                  Other charitable purposes
                </label>
              </div>

              {/* sub category */}
              {showcharitable && (
                <div className="sub-category-list">
                  <div className="row g-3">
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="transport"
                          className="form-check-input"
                        />
                        <label for="transport">Community transport</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Agricultural"
                          className="form-check-input"
                        />
                        <label for="Agricultural">Agricultural show</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="respite"
                          className="form-check-input"
                        />
                        <label for="respite">Break and respite care</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Residential"
                          className="form-check-input"
                        />
                        <label for="Residential">Residential care</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="living"
                          className="form-check-input"
                        />
                        <label for="living">Supported living</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Day"
                          className="form-check-input"
                        />
                        <label for="Day">Day Care</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="social"
                          className="form-check-input"
                        />
                        <label for="social">Other social care</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="volunteering"
                          className="form-check-input"
                        />
                        <label for="volunteering">
                          Promotion of volunteering/Voluntary sector
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="effectiveness"
                          className="form-check-input"
                        />
                        <label for="effectiveness">
                          Promotion of efficiency/effectiveness of charities
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Uniformed"
                          className="form-check-input"
                        />
                        <label for="Uniformed">Uniformed youth groups</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="groups"
                          className="form-check-input"
                        />
                        <label for="groups">Non-uniformed youth groups</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Other9"
                          className="form-check-input"
                        />
                        <label for="Other9">Other</label>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <h3>
            If you are interested in gaining access to updates on this research,
            please enter your contact information below. We will contact you
            concerning publication of the results:
          </h3>
          <div className="row">
            <div className="col-md-6 col-lg-4">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Email Address"
                />
              </div>
            </div>
          </div>
          <button className="next-page login-btn">Submit</button>
        </div>
      </div>
    </>
  );
};

export default QuestionFour;





///first code for academic survey //
// import React from "react";
// import Header from "../Layouts/Header";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   getSurveyAnswerList,
//   getSurveyQuestionList,
// } from "../../store/Action/SurveyAction";
// import { useLocation, useNavigate } from "react-router-dom";

// const QuestionThree = () => {
//   const dispatch = useDispatch();
//   const location = useLocation();
//   const navigate = useNavigate();
//   const { state } = location;
//   const vUniqueCode = state?.vUniqueCode || null;

//   const SurveyId = state?.SurveyId || null;

//   const participantvUniqueCodeNew = localStorage.getItem(
//     "participantvUniqueCodeNew"
//   );

//   const [buttonLoading, setButtonLoading] = React.useState(false);

//   const [currentQuestionIndex, setCurrentQuestionIndex] = React.useState(() => {
//     const savedIndex = localStorage.getItem("currentQuestionIndex");
//     return savedIndex ? parseInt(savedIndex, 10) : 0;
//   });

//   const [errors, setErrors] = React.useState({});
//   const [email, setEmail] = React.useState("");
//   const [pageNumber, setPageNumber] = React.useState(1);
//   const [emailError, setEmailError] = React.useState("");
//   const [selectedCategories, setSelectedCategories] = React.useState(
//     JSON.parse(localStorage.getItem("selectedCategories")) || {}
//   );

//   const { surveyQuestionData, loading } = useSelector(
//     (state) => state.surveyQuestion
//   );

//   const participantSurveyId = surveyQuestionData?.data?.Data?.[0]?.iSurveyId;

//   const { surveyAnswerData } = useSelector((state) => state.surveyAnswer);

//   const userUniqueCode = localStorage.getItem("vUniqueCode");

//   const { participantUniqueCode } = useSelector((state) => state.participant);

//   // Save currentQuestionIndex to localStorage when it changes
//   React.useEffect(() => {
//     localStorage.setItem("currentQuestionIndex", currentQuestionIndex);
//   }, [currentQuestionIndex]);

//   // Retrieve email from localStorage when the component mounts
//   React.useEffect(() => {
//     const savedEmail = localStorage.getItem("email");
//     if (savedEmail) {
//       setEmail(savedEmail);
//     }
//   }, []);

//   const handleEmailChange = (event) => {
//     const emailValue = event.target.value.trim();
//     setEmail(emailValue);
//     event.target.value.trim() !== "" && setEmailError("");

//     // Regular expsion for basic email validation
//     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//     if (!emailRegex.test(emailValue)) {
//       setEmailError("Invalid Email format.");
//     } else {
//       setEmailError("");
//       localStorage.setItem("email", emailValue);
//     }
//   };

//   React.useEffect(() => {
//     const cleanedUniqueCode = vUniqueCode && vUniqueCode.replace(/^"|"$/g, "");
//     dispatch(
//       getSurveyQuestionList({
//         vUniqueCode: cleanedUniqueCode,
//         // iPageNo:1 ,
//       })
//     );
//   }, [dispatch, vUniqueCode]);

//   React.useEffect(() => {
//     localStorage.setItem(
//       "selectedCategories",
//       JSON.stringify(selectedCategories)
//     );
//   }, [selectedCategories]);

//   const handleCategorySelection = (categoryId) => {
//     setSelectedCategories((prevSelected) => {
//       const updatedCategories = {
//         ...prevSelected,
//         [categoryId]: {
//           ...prevSelected[categoryId],
//           selected: !prevSelected[categoryId]?.selected,
//           subCategories: prevSelected[categoryId]?.subCategories || {},
//           isValid: prevSelected[categoryId]?.isValid || false,
//         },
//       };

//       const isAnyCategorySelected = Object.values(updatedCategories).some(
//         (category) => category.selected
//       );

//       // Update errors for current question index
//       setErrors((prevErrors) => ({
//         ...prevErrors,
//         [currentQuestionIndex]: isAnyCategorySelected
//           ? ""
//           : "Please select at least one category.",
//       }));

//       return updatedCategories;
//     });
//   };

//   const handleSubCategorySelection = (categoryId, subCategoryId) => {
//     setSelectedCategories((prevSelected) => {
//       const updatedSubCategories = {
//         ...prevSelected[categoryId],
//         subCategories: {
//           ...prevSelected[categoryId]?.subCategories,
//           [subCategoryId]:
//             !prevSelected[categoryId]?.subCategories?.[subCategoryId],
//         },
//       };

//       const isSubCategorySelected = Object.values(
//         updatedSubCategories.subCategories
//       ).some((isSelected) => isSelected);

//       const updatedCategories = {
//         ...prevSelected,
//         [categoryId]: {
//           ...updatedSubCategories,
//           isValid: isSubCategorySelected,
//         },
//       };

//       const isAllSelectedCategoriesValid = Object.values(
//         updatedCategories
//       ).every((category) => !category.selected || category.isValid);

//       // Update errors for current question index
//       setErrors((prevErrors) => ({
//         ...prevErrors,
//         [currentQuestionIndex]: isAllSelectedCategoriesValid
//           ? ""
//           : "Please select at least one subcategory for each selected category.",
//       }));

//       return updatedCategories;
//     });
//   };

//   // Handle the next question navigation
//   const handleNextQuestion = async () => {
//     const currentQuestion =
//       surveyQuestionData?.data?.Data[currentQuestionIndex];

//     const currentQuestionId =
//       surveyQuestionData?.data?.Data[currentQuestionIndex].iQuestionId;
//     const currentQuestionType =
//       surveyQuestionData?.data?.Data[currentQuestionIndex].eType;

//     const isAnyCategorySelected = currentQuestion?.categories?.some(
//       (category) => selectedCategories[category.iCategoryId]?.selected
//     );

//     if (!isAnyCategorySelected) {
//       setErrors((prevErrors) => ({
//         ...prevErrors,
//         [currentQuestionIndex]: "Please select at least one category.",
//       }));
//       return;
//     }

//     const isAllSelectedCategoriesValid = currentQuestion?.categories?.every(
//       (category) => {
//         if (selectedCategories[category.iCategoryId]?.selected) {
//           const hasSelectedSubCategory = Object.values(
//             selectedCategories[category.iCategoryId]?.subCategories || {}
//           ).some((isSelected) => isSelected);
//           return hasSelectedSubCategory;
//         }
//         return true;
//       }
//     );

//     if (!isAllSelectedCategoriesValid) {
//       setErrors((prevErrors) => ({
//         ...prevErrors,
//         [currentQuestionIndex]:
//           "Please select at least one subcategory for each selected category.",
//       }));
//       return;
//     }

//     // Clear error for the current question when moving to the next
//     setErrors((prevErrors) => ({
//       ...prevErrors,
//       [currentQuestionIndex]: "",
//     }));

//     const iOptionId = currentQuestion?.categories
//       .map((category) => {
//         if (selectedCategories[category.iCategoryId]?.selected) {
//           const selectedSubCategoryIds = Object.keys(
//             selectedCategories[category.iCategoryId]?.subCategories || {}
//           ).filter(
//             (subCatId) =>
//               selectedCategories[category.iCategoryId].subCategories[subCatId]
//           );

//           return {
//             [category.iCategoryId]: selectedSubCategoryIds.map(Number),
//           };
//         }
//         return null;
//       })
//       .filter(Boolean);

//     const payload = {
//       vUniqueCode:
//         participantUniqueCode || userUniqueCode || participantvUniqueCodeNew,
//       iQuestionId: [{ [currentQuestionId]: currentQuestionType }],
//       iOptionId: [iOptionId],
//       iSurveyId: participantSurveyId || SurveyId,
//       iPageNo: pageNumber,
//     };

//     try {
//       setButtonLoading(true);
//       const response = await fetch(
//         `${process.env.REACT_APP_API_URL}/api/question/answer`,
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify(payload),
//         }
//       );

//       const responseData = await response.json();
//       if (responseData && responseData.status === 200) {
//         setButtonLoading(false);
//         if (currentQuestionIndex < surveyQuestionData?.data?.Data.length - 1) {
//           setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
//           window.scroll(0, 0);
//         } else {
//           // Final submission or next step logic here
//         }
//       }
//       setPageNumber(pageNumber + 1);

//       if (
//         responseData &&
//         responseData.data &&
//         responseData.data.survey_status === "Completed"
//       ) {
//         navigate("/survey/thank-you");
//         localStorage.removeItem("selectedCategories");
//         localStorage.removeItem("education");
//         localStorage.removeItem("researchOption");
//         localStorage.removeItem("Other");
//         localStorage.removeItem("agree");
//         localStorage.removeItem("disagree");
//         localStorage.removeItem("currentQuestionIndex");
//         localStorage.removeItem("participantvUniqueCodeNew");
//         localStorage.removeItem("email");
//       }
//     } catch (err) {
//       // Handle error
//     }
//   };

//   // Handle the back question navigation
//   const handleBackQuestion = () => {
//     if (currentQuestionIndex > 0) {
//       // Clear error for the previous question when going back
//       setErrors((prevErrors) => ({
//         ...prevErrors,
//         [currentQuestionIndex - 1]: "",
//       }));
//       setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
//     }
//     setPageNumber(pageNumber - 1);
//   };

//   // Handle the back navigation to a previous page
//   const handleBack = () => {
//     navigate("/general-info", {
//       state: { vUniqueCode, SurveyId, participantvUniqueCodeNew },
//     });
//   };

//   return (
//     <>
//       <Header />
//       <div className="questions-wrp survey-category survey-page">
//         {loading ? (
//           <>
//             <div
//               style={{
//                 display: "flex",
//                 justifyContent: "center",
//                 alignItems: "center",
//                 marginTop: "50px",
//                 marginBottom: "50px",
//               }}
//             >
//               {loading && (
//                 <img
//                   src="/assets/gif/350.gif"
//                   className="loading-gif"
//                   alt="Loading..."
//                 />
//               )}
//             </div>
//           </>
//         ) : (
//           <>
//             {surveyQuestionData &&
//             surveyQuestionData?.code === 200 &&
//             surveyQuestionData?.data?.Data &&
//             surveyQuestionData?.data?.Data.length > 0 ? (
//               <>
//                 <div className="container">
//                   <div className="main-category">
//                     <h2 className="qus-title">
//                       {
//                         surveyQuestionData?.data?.Data[currentQuestionIndex]
//                           .vQuestion
//                       }
//                     </h2>
//                     {surveyQuestionData?.data?.Data[
//                       currentQuestionIndex
//                     ]?.categories?.map((category) => (
//                       <div
//                         className="category-list border-0 pt-0"
//                         key={category.iCategoryId}
//                       >
//                         <div className="form-check">
//                           <input
//                             type="checkbox"
//                             id={`specific-${category.iCategoryId}`}
//                             className="form-check-input"
//                             onChange={() =>
//                               handleCategorySelection(category.iCategoryId)
//                             }
//                             checked={
//                               selectedCategories[category.iCategoryId]
//                                 ?.selected || false
//                             }
//                           />
//                           <label
//                             htmlFor={`specific-${category.iCategoryId}`}
//                             className="category-title"
//                           >
//                             {category.vCategory}
//                           </label>
//                         </div>

//                         {selectedCategories[category.iCategoryId]?.selected && (
//                           <div className="sub-category-list">
//                             <div className="row g-3">
//                               {category?.subCategories?.map((subCategory) => (
//                                 <div
//                                   className="col-md-6 col-lg-4"
//                                   key={subCategory.iSubCategoryId}
//                                 >
//                                   <div className="form-check">
//                                     <input
//                                       type="checkbox"
//                                       id={`sub-${category.iCategoryId}-${subCategory.iSubCategoryId}`}
//                                       className="form-check-input"
//                                       onChange={() =>
//                                         handleSubCategorySelection(
//                                           category.iCategoryId,
//                                           subCategory.iSubCategoryId
//                                         )
//                                       }
//                                       checked={
//                                         selectedCategories[category.iCategoryId]
//                                           ?.subCategories?.[
//                                           subCategory.iSubCategoryId
//                                         ] || false
//                                       }
//                                     />
//                                     <label
//                                       htmlFor={`sub-${category.iCategoryId}-${subCategory.iSubCategoryId}`}
//                                     >
//                                       {subCategory.vSubCategory}
//                                     </label>
//                                   </div>
//                                 </div>
//                               ))}
//                             </div>
//                           </div>
//                         )}
//                       </div>
//                     ))}
//                     {/* {error && (
//                       <div style={{ color: "red", marginBottom: "10px" }}>
//                         {error}
//                       </div>
//                     )} */}
//                     {errors[currentQuestionIndex] && (
//                       <div
//                         style={{ color: "red", marginBottom: "10px" }}
//                         className="error-message"
//                       >
//                         {errors[currentQuestionIndex]}
//                       </div>
//                     )}
//                   </div>

//                   {currentQuestionIndex === 1 && (
//                     <div>
//                       <h3>
//                         If you are interested in gaining access to updates on
//                         this research, please enter your contact information
//                         below. We will contact you concerning publication of the
//                         results:
//                       </h3>
//                       <div className="row">
//                         <div className="col-md-6 col-lg-4">
//                           <div className="form-group">
//                             <input
//                               type="text"
//                               className="form-control"
//                               placeholder="Email Address"
//                               value={email}
//                               onChange={handleEmailChange}
//                             />
//                             {emailError && (
//                               <div
//                                 className="error-msg"
//                                 style={{
//                                   color: "red",
//                                   marginLeft: "5px",
//                                   fontSize: "14px",
//                                 }}
//                               >
//                                 {" "}
//                                 {emailError}
//                               </div>
//                             )}
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   )}

//                   {buttonLoading ? (
//                     <button
//                       className="next-page login-btn"
//                       type="button"
//                       disabled
//                     >
//                       <span
//                         className="spinner-border spinner-border-sm me-1"
//                         role="status"
//                         aria-hidden="true"
//                       ></span>
//                       {currentQuestionIndex ===
//                       surveyQuestionData.data.Data.length - 1
//                         ? "Submitting..."
//                         : "Loading..."}
//                     </button>
//                   ) : (
//                     <button
//                       className="next-page login-btn"
//                       onClick={handleNextQuestion}
//                     >
//                       {currentQuestionIndex <
//                       surveyQuestionData.data.Data.length - 1
//                         ? "Next"
//                         : "Submit"}
//                     </button>
//                   )}

//                   {/* Conditionally show the Back button */}
//                   {currentQuestionIndex > 0 &&
//                     currentQuestionIndex <
//                       surveyQuestionData.data.Data.length - 1 && (
//                       <button
//                         style={{ marginLeft: "20px" }}
//                         className="next-page back-btn"
//                         onClick={handleBackQuestion}
//                       >
//                         Back
//                       </button>
//                     )}

//                   {currentQuestionIndex === 0 &&
//                     surveyQuestionData.data.Data.length > 1 && (
//                       <button
//                         className="next-page back-btn"
//                         type="submit"
//                         style={{ marginLeft: "20px" }}
//                         onClick={handleBack}
//                       >
//                         Back
//                       </button>
//                     )}
//                 </div>
//               </>
//             ) : (
//               <>
//                 <div
//                   className="no-data"
//                   style={{
//                     display: "flex",
//                     justifyContent: "center",
//                     alignItems: "center",
//                     padding: "10px 0",
//                     marginTop: "50px",
//                   }}
//                 >
//                   No Data Found
//                 </div>
//               </>
//             )}
//           </>
//         )}
//       </div>
//     </>
//   );
// };

// export default QuestionThree;