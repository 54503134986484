import React from "react";
import QuestionThree from "../components/Question/QuestionThree";

const QuestionThreePage = () => {
  return (
    <>
      <div>
        <QuestionThree />
      </div>
    </>
  );
};

export default QuestionThreePage;
