import React from 'react'

const WhatWeDoSection = () => {
  return (
    // <section className='what-we-do bg-img pt-100' style={{ backgroundImage: "url(assets/image/at-we-do.png )" }}>
    //   <div className='container'>
    //     <div className='row  align-items-center'>
    //       <div className='col-lg-6'>
    //         <div className="what-we-do-content pr-100">
    //           <h2 className="main-title">What We Do</h2>
    //           <div className="what-we-do-desc">
    //             <p>Lorem ipsum dolor sit amet consectetur. Mi tellus ut et erat bibendum amet in facilisis ut. Dictumst nulla aenean sit ornare nulla. Lacus potenti ut hendrerit sapien sed faucibus. Pharetra libero est fames vel id pellentesque et. Imperdiet vestibulum augue adipiscing iaculis sed a condimentum gravida. Quam aliquam eu auctor accumsan montes eget iaculis interdum augue. Pharetra velit morbi condimentum enim aliquet facilisi. Lacus magna non morbi diam tristique facilisis a tortor. Id id nisl at non. At in semper in ullamcorper vel tortor aenean nisi suscipit. Tincidunt imperdiet porttitor ut vitae mattis gravida ornare.
    //             </p>
    //           </div>
    //         </div>
    //       </div>
    //       <div className='col-lg-6'>
    //         <div className='what-we-do-img'>
    //           <img src="assets/image/what-we-do.jpg" alt="image"
    //             width="637" height="578" />
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </section>
    <div className='d-none'>What We Do</div>
  )
}

export default WhatWeDoSection
