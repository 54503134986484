import React from "react";

import Container from "../components/Layouts/Container";
import About from "../components/About/About";
// import NewsLetter from "../components/Common/NewsLetter";

const AboutUsPage = () => {
  return (
    <div>
      <Container>
        <About />
        {/* <NewsLetter /> */}
      </Container>
    </div>
  );
};

export default AboutUsPage;
