import React from "react";
import Container from "../components/Layouts/Container";
import ContactUs from "../components/Contact/ContactUs";

const ContactPage = () => {
  return (
    <Container>
        <ContactUs />
    </Container>
  );
};

export default ContactPage;
