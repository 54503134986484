import React from 'react'

const NewsLetter = () => {
  return (
    // <section className='news-letter bg-img what-we-do pt-100'>
    //   <div className='container'>
    //     <div className='news-letter-view bg-img' >
    //       <div className='row'>
    //         <div className='col-lg-6'>
    //           <div className='news-girl'>
    //             <img className='img-fluid' src="assets/image/news-girl.png" alt="image"
    //               width="419" height="450" />
    //           </div>
    //         </div>
    //         <div className='col-lg-6'>
    //           <div>
    //             <div className="what-we-do-content">
    //               <h2 className="main-title">News Letter</h2>
    //               <div className="what-we-do-desc">
    //                 <p>Lorem ipsum dolor sit amet consectetur. Mi tellus ut et erat bibendum amet in facilisis ut. Dictumst nulla aenean sit ornare nulla. Lacus potenti ut hendrerit sapien sed faucibus. Pharetra libero est fames vel id pellentesque et. Imperdiet vestibulum augue </p>
    //               </div>
    //               <div className="newsletter-form">
    //                 <input type="text" placeholder="Enter your email"/>
    //                   <a href='javascript:;' className="page-btn" type="button">Submit</a></div>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </section>
    <div className='d-none'>NewsLetter</div>
  )
}

export default NewsLetter
