// import React from "react";
// import DashboardMenu from "../Dashboard/DashboardMenu";
// import { useDispatch, useSelector } from "react-redux";
// import { getSurveyList } from "../../store/Action/SurveyAction";
// import { Link, useNavigate } from "react-router-dom";

const MySurvey = () => {
  // const dispatch = useDispatch();
  // const navigate = useNavigate();
  // const moment = require("moment");

  // const { surveyListData, loading } = useSelector((state) => state.surveyList);
  // // const [loading, setLoading] = React.useState(true);

  // const defaultImage = "../assets/image/about-count-3.png";

  // React.useEffect(() => {
  //   dispatch(getSurveyList());
  // }, [dispatch]);

  // // React.useEffect(() => {
  // //   if (surveyListData) {
  // //     setLoading(false);
  // //   }
  // // }, [surveyListData]);

  // const handelProceed = (code, Id) => {
  //   navigate(`/survey`, {
  //     state: {
  //       surveyCode: code,
  //       surveyId: Id,
  //     },
  //   });
  // };
  // return (
  //   <section className="my-profile">
  //     <div className="dashboard-main-content">
  //       <DashboardMenu />

  //       <div className="profile-right-view-bg dashboard-right-view">
  //         <div className="row g-3">
  //           <div className="col-lg-12">
  //             <div className="survey-result">
  //               <h3 className="title">My Survey</h3>
  //               <div className="table-responsive">
  //                 {loading ? (
  //                   <div
  //                     style={{
  //                       display: "flex",
  //                       justifyContent: "center",
  //                       alignItems: "center",
  //                       marginTop: "50px",
  //                       marginBottom: "50px",
  //                     }}
  //                   >
  //                     {loading && (
  //                       <img
  //                         src="/assets/gif/350.gif"
  //                         className="loading-gif"
  //                         alt="Loading..."
  //                       />
  //                     )}
  //                   </div>
  //                 ) : (
  //                   <>
  //                     {surveyListData &&
  //                     surveyListData.code === 200 &&
  //                     surveyListData.data ? (
  //                       <table className="table">
  //                         <thead>
  //                           <tr>
  //                             <th className="text-center">No</th>
  //                             <th className="text-center">Image</th>
  //                             <th className="text-center">Name</th>
  //                             <th className="text-center">Date</th>
  //                             <th className="text-center">Score</th>
  //                             <th className="text-center">Action</th>
  //                           </tr>
  //                         </thead>
  //                         <tbody>
  //                           {surveyListData &&
  //                             surveyListData.data &&
  //                             surveyListData.data &&
  //                             surveyListData.data.Data.map(
  //                               (survey, surveyIndex) => (
  //                                 <tr key={surveyIndex}>
  //                                   <td className="text-center">
  //                                     {surveyIndex + 1}
  //                                   </td>
  //                                   <td className="text-center">
  //                                     <div className="table-img">
  //                                       <img
  //                                         src={
  //                                           survey &&
  //                                           survey?.vWebpImage
  //                                             ?.split("/")
  //                                             ?.pop() === "null"
  //                                             ? defaultImage
  //                                             : survey.vWebpImage
  //                                         }
  //                                         // src={
  //                                         //   survey &&
  //                                         //   survey.vWebpImage ===
  //                                         //     "https://admin-survey.demo-available.com/uploads/survey/survey_small/"
  //                                         //     ? defaultImage
  //                                         //     : survey.vWebpImage
  //                                         // }
  //                                         alt={survey.vImageAlt}
  //                                         width="40"
  //                                         height="40"
  //                                       />
  //                                     </div>
  //                                   </td>
  //                                   <td className="text-center">
  //                                     {survey.vSurvey}
  //                                   </td>
  //                                   <td className="text-center">
  //                                     {" "}
  //                                     {moment(survey.dtAddedDate).format(
  //                                       "DD-MM-YYYY"
  //                                     )}
  //                                   </td>
  //                                   <td className="text-center">99/100</td>
  //                                   <td className="text-center">
  //                                     <div className="table-btn">
  //                                       {/* <button
  //                                         // to={{
  //                                         //   pathname: "/survey",
  //                                         //   newState: {
  //                                         //     vUniqueCodeSurvey:
  //                                         //       survey.vUniqueCode,
  //                                         //   },
  //                                         // }}
  //                                         onClick={() =>
  //                                           handelProceed(
  //                                             survey && survey?.vUniqueCode,

  //                                             survey && survey?.iSurveyId
  //                                           )
  //                                         }
  //                                         className="show-result"
  //                                       >
  //                                         Proceed
  //                                       </button> */}
  //                                       <Link to="#" className="show-result">
  //                                         Proceed
  //                                       </Link>
  //                                     </div>
  //                                   </td>
  //                                 </tr>
  //                               )
  //                             )}
  //                         </tbody>
  //                       </table>
  //                     ) : (
  //                       <div className="col-12">
  //                         <p
  //                           style={{
  //                             display: "flex",
  //                             justifyContent: "center",
  //                             alignItems: "center",
  //                             padding: "10px 0",
  //                             marginTop: "10px",
  //                           }}
  //                         >
  //                           No Data Found
  //                         </p>
  //                       </div>
  //                     )}
  //                   </>
  //                 )}
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </section>
  // );
};

export default MySurvey;


