export const SOCIAL_LIST_REQUEST = "SOCIAL_LIST_REQUEST";
export const SOCIAL_LIST_SUCCESS = "SOCIAL_LIST_SUCCESS";
export const SOCIAL_LIST_FAILURE = "SOCIAL_LIST_FAILURE";


export const COMPANY_DETAIL_LIST_REQUEST = "COMPANY_DETAIL_LIST_REQUEST";
export const COMPANY_DETAIL_LIST_SUCCESS = "COMPANY_DETAIL_LIST_SUCCESS";
export const COMPANY_DETAIL_LIST_FAILURE = "COMPANY_DETAIL_LIST_FAILURE";


export const META_LIST_REQUEST = "META_LIST_REQUEST";
export const META_LIST_SUCCESS = "META_LIST_SUCCESS";
export const META_LIST_FAILURE = "META_LIST_FAILURE";