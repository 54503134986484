import React from "react";
import Header from "../Layouts/Header";
import { useNavigate } from "react-router-dom";

const Organisation6 = () => {
  const navigate = useNavigate();

  const handleSubmitClick = () => {
    navigate("/survey/thank-you");
  };
  return (
    <>
      <Header />
      <div className="questions-wrp survey-category questions2 survey-page">
        <div className="container">
          <div className="survey-content questions6">
            <p>
              At this time, we are interested in receiving feedback from
              researchers who have engaged with community-based organisations in
              the past 5 years . If you do intend to conduct such research in
              the near future and/or are interested in joining our mailing list,
              please enter your contact email address below
            </p>
            <div className="row g-4 mt-1">
              <div className="col-md-6">
                <div className="form-group">
                  <label className="qus-title mb-2" htmlFor="email">
                    Your email
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Email Address"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="qus-title mb-2" htmlFor="email">
                    Your Address
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Your Address"
                  />
                </div>
              </div>
            </div>
            <button
              className="login-btn mt-4"
              type="submit"
              onClick={handleSubmitClick}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Organisation6;
