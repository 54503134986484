import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";

import AboutUsPage from "../pages/AboutUsPage";
import HomePage from "../pages/HomePage";
import ContactPage from "../pages/ContactPage";
import LoginPage from "../pages/LoginPage";
import RegisterPage from "../pages/RegisterPage";
import ForgotPasswordPage from "../pages/ForgotPasswordPage";
import DashboardPage from "../pages/DashboardPage";
import ProfilePage from "../pages/ProfilePage";
import ChangePasswordPage from "../pages/ChangePasswordPage";
import SettingPage from "../pages/SettingPage";
import ResetPasswordPage from "../pages/ResetPasswordPage";
import SuccessfullRegistrationPage from "../pages/SuccessfullRegistrationPage";
import AccountVerifiedPage from "../pages/AccountVerifiedPage";
import ErrorPage from "../pages/ErrorPage";
import AccountActivationPage from "../pages/AccountActivationPage";
import SuccessResetPasswordPage from "../pages/SuccessResetPasswordPage";
import LogoutPage from "../pages/LogoutPage";
import { useDispatch, useSelector } from "react-redux";
import { getMetaList } from "../store/Action/MetaAction";
// import { Helmet } from "react-helmet";

import InvitationPage from "../pages/InvitationPage";
import PrivacyPolicyPage from "../pages/PrivacyPolicyPage";
import TermsAndConditionPage from "../pages/TermsAndConditionPage";
import MySurveyPage from "../pages/MySurveyPage";
import PublicSurveyFormPage from "../pages/PublicSurveyFormPage";

import SurveyPage from "../pages/SurveyPage";

import QuestionTwoPage from "../pages/QuestionTwoPage";
import QuestionThreePage from "../pages/QuestionThreePage";
import QuestionFourPage from "../pages/QuestionFourPage";
// import ThankYouPage from "../pages/ThankYouPage";
import OrganisationPage2 from "../pages/OrganisationPage2";

import OrganisationPage4 from "../pages/OrganisationPage4";
import OrganisationPage5 from "../pages/OrganisationPage5";
import OrganisationPage6 from "../pages/OrganisationPage6";
import OrganisationPage3 from "../pages/OrganisationPage3";
import ThankYouPage from "../pages/ThankYouPage";



function Index() {
  const dispatch = useDispatch();

  const location = useLocation();

  const { metaListData } = useSelector((state) => state.metaList);

  const [pageData, setPageData] = React.useState({
    title: "VCSE Observatory",
    description: "VCSE Observatory",
    keywords: "VCSE Observatory",
  });

  const pageUrlSlug =
    location && location.pathname && location.pathname.split("/")[1];

  React.useEffect(() => {
    const pathWithoutSlash = location && location.pathname.slice(1);

    const vpath = location.pathname === "/" ? "Home" : pathWithoutSlash;

    const formattedTitle = vpath.charAt(0).toLowerCase() + vpath.slice(1);

    dispatch(
      getMetaList({
        vSlug: pageUrlSlug || formattedTitle,
      })
    );
  }, [dispatch, pageUrlSlug, location]);

  React.useEffect(() => {
    if (metaListData) {
      updateMetaTags(metaListData);
    }
  }, [metaListData]);

  function updateMetaTags(metaListData) {
    if (metaListData && metaListData?.data) {
      const { vTitle, tDescription, tKeyword } = metaListData.data;

      if (vTitle) {
        document.title = `${vTitle} | VCSE Observatory`;
      }

      // Update or create description meta tag
      let descriptionMetaTag = document.querySelector(
        'meta[name="description"]'
      );
      if (descriptionMetaTag) {
        descriptionMetaTag.setAttribute("content", tDescription || "");
      } else {
        descriptionMetaTag = document.createElement("meta");
        descriptionMetaTag.name = "description";
        descriptionMetaTag.content = tDescription || "";
        document.head.appendChild(descriptionMetaTag);
      }

      // Update or create keywords meta tag
      let keywordsMetaTag = document.querySelector('meta[name="keywords"]');
      if (keywordsMetaTag) {
        keywordsMetaTag.setAttribute("content", tKeyword || "");
      } else {
        keywordsMetaTag = document.createElement("meta");
        keywordsMetaTag.name = "keywords";
        keywordsMetaTag.content = tKeyword || "";
        document.head.appendChild(keywordsMetaTag);
      }

      // Update or create og:title meta tag
      let ogTitleMetaTag = document.querySelector('meta[property="og:title"]');
      if (ogTitleMetaTag) {
        ogTitleMetaTag.setAttribute("content", `${vTitle} | VCSE Observatory`);
      } else {
        ogTitleMetaTag = document.createElement("meta");
        ogTitleMetaTag.setAttribute("property", "og:title");
        ogTitleMetaTag.content = `${vTitle} | VCSE Observatory`;
        document.head.appendChild(ogTitleMetaTag);
      }

      // Update or create og:description meta tag
      let ogDescriptionMetaTag = document.querySelector(
        'meta[property="og:description"]'
      );
      if (ogDescriptionMetaTag) {
        ogDescriptionMetaTag.setAttribute("content", tDescription || "");
      } else {
        ogDescriptionMetaTag = document.createElement("meta");
        ogDescriptionMetaTag.setAttribute("property", "og:description");
        ogDescriptionMetaTag.content = tDescription || "";
        document.head.appendChild(ogDescriptionMetaTag);
      }
    }
  }

  React.useEffect(() => {
    if (metaListData && metaListData.code === 200 && metaListData.data) {
      setPageData({
        title: metaListData.data.vTitle,
        description: metaListData.data.tDescription,
        keywords: metaListData.data.tKeyword,
      });
    }
  }, [metaListData]);

  const ScrollToTopOnRouteChange = () => {
    const location = useLocation();

    React.useEffect(() => {
      window.scrollTo(0, 0);
    }, [location.pathname]);

    return null;
  };

  return (
    <React.Fragment>
      {/* <Helmet> */}
      {/* <title>{`${pageData.title} | VCSE Observatory`}</title>
        <meta property="og:description" content={pageData.description} />
        <meta name="keywords" content={pageData.keywords} />
        <meta property="og:title" content={pageData.title} /> */}
      {/* <meta charSet="utf-8" />
        <meta
          property="og:description"
          content={
            metaListData ? `${pageData.description}` : "VCSE Observatory"
          }
        />
        <meta
          name="keywords"
          content={metaListData ? `${pageData.keywords}` : "VCSE Observatory"}
        />
        <title>
          {metaListData
            ? `${pageData.title} | VCSE Observatory`
            : "VCSE Observatory"}
        </title> */}
      {/* </Helmet> */}
      <ScrollToTopOnRouteChange />

      <Routes>
        <Route exact path="/" element={<HomePage />} title="Home" />

        <Route
          exact
          path="/about-us"
          element={<AboutUsPage />}
          title="About-Us"
        ></Route>

        <Route
          exact
          path="/contact-us"
          element={<ContactPage />}
          title="Contact-us"
        />

        <Route exact path="/login" element={<LoginPage />} title="Login" />
        <Route
          exact
          path="/register"
          element={<RegisterPage />}
          title="Register"
        />

        <Route
          exact
          path="/account-verified"
          element={<AccountVerifiedPage />}
          title="Accountverified-page"
        />

        <Route
          exact
          path="/account-activation/:vUniqueCode"
          element={<AccountActivationPage />}
          title="accountactivation-page"
        />

        <Route exact path="/error" element={<ErrorPage />} title="Error-page" />

        <Route
          exact
          path="/success-registration"
          element={<SuccessfullRegistrationPage />}
          title="Successfulregistration-page"
        />
        <Route
          exact
          path="/forgot-password"
          element={<ForgotPasswordPage />}
          title="Forgot-password"
        />

        <Route
          exact
          path="/success-reset-password"
          element={<SuccessResetPasswordPage />}
          title="Success-reset-password-page"
        />

        <Route
          exact
          path="/reset-password/:vUniqueCode"
          element={<ResetPasswordPage />}
          title="Reset-password"
        />

        <>
          <Route
            exact
            path="/dashboard"
            element={
              // <Suspense fallback={<Loader />}>
              <DashboardPage />
              // </Suspense>
            }
            title="Dashboard"
          />

          <Route
            exact
            path="/profile"
            element={<ProfilePage />}
            title="Profile"
          />

          <Route exact path="/logout" element={<LogoutPage />} title="Logout" />

          <Route
            exact
            path="/change-password"
            element={<ChangePasswordPage />}
            title="Change-password"
          />

          <Route
            exact
            path="/my-survey"
            element={<MySurveyPage />}
            title="My-survey"
          />
          <Route
            exact
            path="/invitation"
            element={<InvitationPage />}
            title="Invitation"
          />

          <Route
            exact
            path="/settings"
            element={<SettingPage />}
            title="Setting"
          />

          <Route
            exact
            path="/privacy-policy"
            element={<PrivacyPolicyPage />}
            title="Privacy-policy"
          />
          <Route
            exact
            path="/terms-and-condition"
            element={<TermsAndConditionPage />}
            title="Terms-and-Condition"
          />

          <Route
            exact
            path="/public-invite/:uniquecode"
            element={<PublicSurveyFormPage />}
            title="Public-invite"
          />

          <Route
            exact
            path="/project-details"
            element={<SurveyPage />}
            title="Project-details"
          />

          <Route
            exact
            path="/survey/:slug1/:slug2"
            element={<SurveyPage />}
            title="Survey"
          />

          <Route
            exact
            path="/general-info"
            element={<QuestionTwoPage />}
            title="General-info"
          />

          <Route
            exact
            path="/survey"
            element={<QuestionThreePage />}
            title="Survey"
          />

          <Route
            exact
            path="/survey/questions/page-4"
            element={<QuestionFourPage />}
            title="Survey"
          />

          <Route
            exact
            path="/organisation/questions/page-2"
            element={<OrganisationPage2 />}
            title="Survey"
          />

          <Route
            exact
            path="/organisation/questions/page-3"
            element={<OrganisationPage3/>}
            title="Survey"
          />

          <Route
            exact
            path="/organisation/questions/page-4"
            element={<OrganisationPage4 />}
            title="Survey"
          />

          <Route
            exact
            path="/organisation/questions/page-5"
            element={<OrganisationPage5 />}
            title="Survey"
          />

          <Route
            exact
            path="/organisation/questions/page-5"
            element={<OrganisationPage5 />}
            title="Survey"
          />

          <Route
            exact
            path="/organisation/questions/page-6"
            element={<OrganisationPage6 />}
            title="Survey"
          />
 <Route
            exact
            path="/survey/thank-you"
            element={<ThankYouPage />}
            title="Thank-you"
          />

        </>
      </Routes>
    </React.Fragment>
  );
}

export default Index;
