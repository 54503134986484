import React from "react";

import { Provider } from "react-redux";
import Index from "./routes/Index";
import { store } from "./store/Reducer/Index";

const App = () => {
  return (
    <div>
      <Provider store={store}>
        <Index />
      </Provider>
    </div>
  );
};

export default App;
