import { createStore, applyMiddleware, combineReducers } from "redux";
import { thunk } from "redux-thunk";
import { surveyBannerReducer } from "./BannerReducer";
import {
  UserChangePasswordReducer,
  userForgetPasswordReducer,
  UserLoginReducer,
  UserProfileReducer,
  UserProfileUpdateReducer,
  UserRegisterReducer,
  userResetPasswordReducer,
} from "./UserReducer";
import { contactUsReducer } from "./ContactReducer";
import {
  CompanyDetailListReducer,
  MetaReducer,
  SocialListReducer,
} from "./MetaReducer";
import { PageReducer } from "./PageReducer";
import {
  checkInvitationTypeReducer,
  invitationReducer,
  // paricipantDataReducer,
  participantEnrtyReducer,
  participantReducer,
  participantSkipEnrtyReducer,
  // participantSurveyReducer,
  surveyAnswerReducer,
  SurveyPublicInviteReducer,
  surveyQuestionReducer,
  surveyReducer,
  totalInvitationCountReducer,
  totalOrganisationCountReducer,
  totalReasercherCountReducer,
  totalSurveyCountReducer,
} from "./SurveyReducer";

const rootReducer = combineReducers({
  banner: surveyBannerReducer,
  userLogin: UserLoginReducer,
  userRegister: UserRegisterReducer,
  forgetPassword: userForgetPasswordReducer,
  resetPassword: userResetPasswordReducer,
  ContactUs: contactUsReducer,
  userProfile: UserProfileReducer,
  socialList: SocialListReducer,
  companyDetailList: CompanyDetailListReducer,
  pageList: PageReducer,
  metaList: MetaReducer,
  changePassword: UserChangePasswordReducer,
  userProfileUpdate: UserProfileUpdateReducer,
  surveyList: surveyReducer,
  invitationList: invitationReducer,
  totalSurveyCountList: totalSurveyCountReducer,
  totalInvitationCountList: totalInvitationCountReducer,
  totalOrganisationCountList: totalOrganisationCountReducer,
  totalResearcherCountList: totalReasercherCountReducer,
  surveyPublicInvite: SurveyPublicInviteReducer,
  participantEntry: participantEnrtyReducer,
  participantSkipEntry: participantSkipEnrtyReducer,
  surveyQuestion: surveyQuestionReducer,
  surveyAnswer: surveyAnswerReducer,
  participant: participantReducer,
  checkInvitationType: checkInvitationTypeReducer,
});

const middleware = applyMiddleware(thunk);

export const store = createStore(rootReducer, middleware);
