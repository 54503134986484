import React from "react";
import QuestionFour from "../components/Question/QuestionFour";
import ThankYou from "../components/Question/ThankYou";

const ThankYouPage = () => {
  return (
    <>
      <div>
        {/* <QuestionFour /> */}
        <ThankYou />
      </div>
    </>
  );
};

export default ThankYouPage;
