import React from "react";
import Profile from "../components/Profile/Profile";
import Header from "../components/Layouts/Header";
import { useNavigate } from "react-router-dom";

const ProfilePage = () => {
  const navigate = useNavigate();
  const vUniqueCode = localStorage.getItem("vUniqueCode");

  React.useEffect(() => {
    if (!vUniqueCode) {
      navigate("/");
    }
  },[vUniqueCode,navigate]);
  return (
    <React.Fragment>
      <Header />
      <Profile />
    </React.Fragment>
  );
};

export default ProfilePage;
