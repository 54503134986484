import React from "react";
import Container from "../components/Layouts/Container";
import Setting from "../components/Setting/Setting";
import { useNavigate } from "react-router-dom";

const SettingPage = () => {
  const navigate = useNavigate();
  const vUniqueCode = localStorage.getItem("vUniqueCode");

  React.useEffect(() => {
    if (!vUniqueCode) {
      navigate("/");
    }
  }, [vUniqueCode, navigate]);
  return (
    <React.Fragment>
      <div>
        <Container>
          <Setting />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default SettingPage;
