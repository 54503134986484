import React from "react";
import QuestionFour from "../components/Question/QuestionFour";

const QuestionFourPage = () => {
  return (
    <>
      <div>
        <QuestionFour />
      </div>
    </>
  );
};

export default QuestionFourPage;
