import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile } from "../../store/Action/UserAction";

const Header = () => {
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const isLoggedIn = localStorage.getItem("vUniqueCode");

  const [activelink, setActiveLink] = React.useState("");
  const location = useLocation();
  const noImage = "/assets/image/profile.jpg";

  const { userProfileData } = useSelector((state) => state.userProfile);

  const handleContactClick = (linkName) => {
    setActiveLink(linkName);
  };

  const handleHomeClick = (linkName) => {
    setActiveLink(linkName);
  };

  const handleAboutUsClick = (linkName) => {
    setActiveLink(linkName);
  };

  const [isSticky, setIsSticky] = React.useState(false);

  const isHomePage = location.pathname.startsWith("/");
  const isAboutPage = location.pathname.startsWith("/about-us");
  const isConatactUsPage = location.pathname.startsWith("/contact-us");

  const isDashboardPage = location.pathname.startsWith("/dashboard");
  const isProfilePage = location.pathname.startsWith("/profile");
  const isChangePasswordPage = location.pathname.startsWith("/change-password");
  const isSurveyPage = location.pathname.startsWith("/my-survey");
  const isInvitationPage = location.pathname.startsWith("/invitation");

  const isStickyHeader = isProfilePage
    ? "main-header navbar navbar-expand-lg after-login-header"
    : isHomePage
    ? "main-header navbar navbar-expand-lg after-login-header"
    : isAboutPage
    ? "main-header navbar navbar-expand-lg after-login-header"
    : isConatactUsPage
    ? "main-header navbar navbar-expand-lg after-login-header"
    : isProfilePage
    ? "main-header navbar navbar-expand-lg after-login-header"
    : isChangePasswordPage
    ? "main-header navbar navbar-expand-lg after-login-header"
    : isDashboardPage
    ? "main-header navbar navbar-expand-lg after-login-header"
    : isSurveyPage
    ? "main-header navbar navbar-expand-lg after-login-header"
    : isInvitationPage
    ? "main-header navbar navbar-expand-lg after-login-header"
    : "main-header navbar navbar-expand-lg";

  const isHeaderAfterLogin = isProfilePage
    ? "main-header navbar navbar-expand-lg after-login-header"
    : isProfilePage
    ? "main-header navbar navbar-expand-lg after-login-header"
    : isChangePasswordPage
    ? "main-header navbar navbar-expand-lg after-login-header"
    : isDashboardPage
    ? "main-header navbar navbar-expand-lg after-login-header"
    : isSurveyPage
    ? "main-header navbar navbar-expand-lg after-login-header"
    : isInvitationPage
    ? "main-header navbar navbar-expand-lg after-login-header"
    : "main-header navbar navbar-expand-lg";

  React.useEffect(() => {
    if (!userProfileData && isLoggedIn) {
      dispatch(
        getUserProfile({
          vUniqueCode: isLoggedIn,
        })
      );
    }
  }, [userProfileData, dispatch, isLoggedIn]);

  return (
    <>
      <header>
        <nav className={isSticky ? isStickyHeader : isHeaderAfterLogin}>
          <div className="container-fluid p-0">
            <div className="mobile-wrapper">
              <Link className="logo navbar-brand" to="/">
                <img
                  src="../../assets/image/login-logo.png"
                  alt="logo"
                  width="298"
                  height="68"
                />
              </Link>
              <div className="nav-mobile-login">
                {!isLoggedIn && (
                  <div className="login-icon">
                    <Link to="/register" className="login-icon-link">
                      {" "}
                      <img
                        src="../../assets/image/register.png"
                        alt="registerimage"
                        width="50"
                        height="50"
                      />
                    </Link>
                    <Link to="/login" className="login-icon-link">
                      {" "}
                      <img
                        src="../../assets/image/login.png"
                        alt="loginiconimage"
                        width="50"
                        height="50"
                      />
                    </Link>
                  </div>
                )}
                {isLoggedIn && (
                  <ul className="nav-btn">
                    <li>
                      <div className="after-login-menu">
                        <div className="dropdown">
                          <a
                            href="javascript:;"
                            className="dropdown-toggle"
                            type="text"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <div className="after-login-img">
                              <img
                                src={
                                  userProfileData &&
                                  userProfileData?.data &&
                                  userProfileData?.data?.vWebpImage
                                    ?.split("/")
                                    ?.pop() === "null"
                                    ? noImage
                                    : userProfileData?.data?.vWebpImage
                                }
                                alt={userProfileData?.data?.vImageAlt}
                                width="40"
                                height="40"
                                style={{ borderRadius: "50%" }}
                              />
                            </div>
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <Link className="dropdown-item" to="/dashboard">
                                Dashboard
                              </Link>
                            </li>
                            <li>
                              <Link className="dropdown-item" to="/profile">
                                Profile
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                to="/change-password"
                              >
                                Change Passowrd
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                href="javascript:;"
                              >
                                Settings
                              </Link>
                            </li>
                            <li>
                              <Link className="dropdown-item" to="/logout">
                                Log Out
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                  </ul>
                )}
                <button className="navbar-toggler" onClick={handleShow}>
                  <span className="navbar-toggler-icon">
                    <i class="fa-solid fa-bars"></i>
                  </span>
                </button>
              </div>
            </div>
            <div
              className="nav-menu collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                <li
                  className={`nav-item ${
                    activelink === "Home" ? "active" : ""
                  }`}
                >
                  <Link
                    className={`nav-link ${
                      location.pathname === "/" ? "active" : ""
                    }`}
                    aria-current="page"
                    to="/"
                    onClick={() => handleHomeClick("Home")}
                  >
                    Home
                  </Link>
                </li>
                <li
                  className={`nav-item ${
                    activelink === "About Us" ? "active" : ""
                  }`}
                >
                  <Link
                    className={`nav-link ${
                      location.pathname === "/about-us" ? "active" : ""
                    }`}
                    to="/about-us"
                    onClick={() => handleAboutUsClick("About Us")}
                  >
                    About Us
                  </Link>
                </li>
                <li
                  className={`nav-item ${
                    activelink === "Contact" ? "active" : ""
                  }`}
                >
                  <Link
                    className={
                      location.pathname === "/contact-us"
                        ? "nav-link active"
                        : "nav-link"
                    }
                    aria-current="page"
                    to="/contact-us"
                    onClick={() => handleContactClick("Contact")}
                  >
                    Contact
                  </Link>
                </li>
              </ul>
              {!isLoggedIn && (
                <ul className="nav-btn">
                  <li>
                    <Link className="Register-btn" to="/register">
                      Register{" "}
                    </Link>
                  </li>
                  <li>
                    <Link className="Log-in Register-btn" to="/login">
                      Log in{" "}
                    </Link>
                  </li>
                </ul>
              )}
              {isLoggedIn && (
                <ul className="nav-btn">
                  <li>
                    <div className="after-login-menu">
                      <div className="dropdown">
                        <a
                          href="javascript:;"
                          className="dropdown-toggle"
                          type="text"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <div className="after-login-img">
                            <img
                              src={
                                userProfileData &&
                                userProfileData?.data &&
                                userProfileData?.data?.vWebpImage
                                  ?.split("/")
                                  ?.pop() === "null"
                                  ? noImage
                                  : userProfileData?.data?.vWebpImage
                              }
                              alt={userProfileData?.data?.vImageAlt}
                              width="40"
                              height="40"
                              style={{ borderRadius: "50%" }}
                            />
                          </div>
                        </a>
                        <ul className="dropdown-menu">
                          <li>
                            <Link className="dropdown-item" to="/dashboard">
                              Dashboard
                            </Link>
                          </li>

                          <li>
                            <Link className="dropdown-item" to="/profile">
                              Profile
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to="/change-password"
                            >
                              Change Passowrd
                            </Link>
                          </li>
                          <li>
                            <Link className="dropdown-item" to="javascript:;">
                              Settings
                            </Link>
                          </li>
                          <li>
                            <Link className="dropdown-item" to="/logout">
                              Log Out
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                </ul>
              )}
            </div>
          </div>
        </nav>
      </header>

      {/* mobile view */}
      <Offcanvas show={show} onHide={handleClose} className="header-offcanvas ">
        <Offcanvas.Header closeButton></Offcanvas.Header>
        <Offcanvas.Body>
          <div className="nav-menu" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li
                className={`nav-item ${activelink === "Home" ? "active" : ""}`}
              >
                <Link
                  className={`nav-link ${
                    location.pathname === "/" ? "active" : ""
                  }`}
                  aria-current="page"
                  to="/"
                  onClick={() => handleHomeClick("Home")}
                >
                  Home
                </Link>
              </li>
              <li
                className={`nav-item ${
                  activelink === "About Us" ? "active" : ""
                }`}
              >
                <Link
                  className={`nav-link ${
                    location.pathname === "/about-us" ? "active" : ""
                  }`}
                  to="/about-us"
                  onClick={() => handleAboutUsClick("About Us")}
                >
                  About Us
                </Link>
              </li>
              <li
                className={`nav-item ${
                  activelink === "Contact" ? "active" : ""
                }`}
              >
                <Link
                  className={
                    location.pathname === "/contact-us"
                      ? "nav-link active"
                      : "nav-link"
                  }
                  aria-current="page"
                  to="/contact-us"
                  onClick={() => handleContactClick("Contact")}
                >
                  Contact
                </Link>
              </li>
            </ul>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default Header;
