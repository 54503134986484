import axios from "axios";
import {
  CONTACT_US_FAILURE,
  CONTACT_US_REQUEST,
  CONTACT_US_SUCCESS,
  STORE_CONTACT_FORM_RESET,
} from "../Constatnts/ContactConstant";

export const getContactUs = (data) => async (dispatch) => {
  try {
    dispatch({ type: CONTACT_US_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/auth/contact-us`,

      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    dispatch({ type: CONTACT_US_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: CONTACT_US_SUCCESS, payload: err.response?.data });
    // dispatch({ type: CONTACT_US_FAILURE });
  }
};


export const ResetContactFormState = () => async (dispatch) => {
    dispatch({ type: STORE_CONTACT_FORM_RESET });
  };