// import React from "react";
// import { useParams } from "react-router";
// import { useNavigate } from "react-router-dom";
// import axios from "axios";

// const AccountActivationPage = () => {
//   const navigate = useNavigate();
//   const { vUniqueCode } = useParams();

//   React.useEffect(() => {
//     const fetchData = async () => {
//       const values = {
//         vUniqueCode: vUniqueCode,
//       };
//       try {
//         const response = await axios.post(
//           `${process.env.REACT_APP_API_URL}/api/auth/account-activation`,
//           values
//         );

//         console.log("*************8",response)

//         if (response.status === 200) {
//           navigate("/account-verified");
//         }
//         if (response.status === 409) {
//           navigate("/account-verified");
//         } else {
//           navigate("/error");
//         }
//       } catch (error) {
//         navigate("/error");
//       }
//     };
//     fetchData();
//   }, [vUniqueCode, navigate]);

//   return <React.Fragment></React.Fragment>;
// };

// export default AccountActivationPage;

// import React from "react";
// import { useParams } from "react-router";
// import { useNavigate } from "react-router-dom";
// import axios from "axios";

// const AccountActivationPage = () => {
//   const navigate = useNavigate();
//   const { vUniqueCode } = useParams();

//   React.useEffect(() => {
//     const fetchData = async () => {
//       const values = { vUniqueCode };
//       try {
//         const response = await axios.post(
//           `${process.env.REACT_APP_API_URL}/api/auth/account-activation`,
//           values
//         );

//         console.log("response", response);

//         const { code, message } = response.data;

//         console.log("message-------------------->", message);

//         if (code === 200) {
//           navigate("/account-verified", { state: { message, success: true } });
//         } else if (code === 409) {
//           navigate("/account-verified", { state: { message, success: false } });
//         } else {
//           navigate("/error");
//         }
//       } catch (error) {
//         navigate("/error");
//       }
//     };

//     fetchData();
//   }, [vUniqueCode, navigate]);

//   return <React.Fragment></React.Fragment>;
// };

// export default AccountActivationPage;

import React from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const AccountActivationPage = () => {
  const navigate = useNavigate();
  const { vUniqueCode } = useParams();

  React.useEffect(() => {
    const fetchData = async () => {
      const values = { vUniqueCode };
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/auth/account-activation`,
          values
        );

        // const { status, data } = response;  // Extracting status and data

        if (response.status === 200) {
          navigate("/account-verified", {
            state: {
              message: response?.data?.message,
              code: response?.data?.code,
            },
          });
        } else if (response.status === 409) {
          navigate("/account-verified", {
            state: {
              message: response?.data?.message,
              code: response?.data?.code,
            },
          });
        } else {
          navigate("/error");
        }
      } catch (error) {
        // console.error("Activation error:", error);
        navigate("/error");
      }
    };

    fetchData();
  }, [vUniqueCode, navigate]);

  return <React.Fragment></React.Fragment>;
};

export default AccountActivationPage;
