import React from "react";
import Header from "../Layouts/Header";

const Organisation5 = () => {
  return (
    <>
      <Header />
      <div className="questions-wrp survey-category questions2 survey-page">
        <div className="container">
          <h2 className="main-title" style={{ fontWeight: "bold" }}>SUPPORTING YOUR RESEARCH INTERESTS</h2>
          <div className="questions2-body questions4 survey-content">
            <div class="main-category">
              <h2 class="qus-title">
                What are the barriers to your organisation getting involved in
                research?
              </h2>
              <div class="category-list border-0 pt-0">
                <div class="form-check">
                  <input
                    type="checkbox"
                    id="specific-349"
                    class="form-check-input"
                  />
                  <label for="specific-349" class="category-title">
                    Lack of expertise in carrying out research
                  </label>
                </div>
                <div class="form-check">
                  <input
                    type="checkbox"
                    id="specific-348"
                    class="form-check-input"
                  />
                  <label for="specific-348" class="category-title">
                    Lack of time
                  </label>
                </div>
                <div class="form-check">
                  <input
                    type="checkbox"
                    id="specific-349"
                    class="form-check-input"
                  />
                  <label for="specific-349" class="category-title">
                    Lack of funding
                  </label>
                </div>
                <div class="form-check">
                  <input
                    type="checkbox"
                    id="specific-349"
                    class="form-check-input"
                  />
                  <label for="specific-349" class="category-title">
                    Knowing where to look for opportunities
                  </label>
                </div>
                <div class="form-check">
                  <input
                    type="checkbox"
                    id="specific-349"
                    class="form-check-input"
                  />
                  <label for="specific-349" class="category-title">
                    Lack of opportunities
                  </label>
                </div>
                <div class="form-check">
                  <input
                    type="checkbox"
                    id="specific-349"
                    class="form-check-input"
                  />
                  <label for="specific-349" class="category-title">
                    Not interested in ‘formal’ research
                  </label>
                </div>
                <div class="form-check">
                  <input
                    type="checkbox"
                    id="specific-349"
                    class="form-check-input"
                  />
                  <label for="specific-349" class="category-title">
                    Other
                  </label>
                </div>
              </div>
            </div>

            <div class="main-category">
              <h2 class="qus-title">
                How interested is your organisation in getting involved in
                health and care research in the future?
              </h2>
              <div class="category-list border-0 pt-0">
                <div class="form-check ">
                  <input
                    type="radio"
                    className="form-check-input radio-check"
                    id="specific-351"
                    name="question-35"
                  />
                  <label for="specific-351" class="category-title">
                    Very interested
                  </label>
                </div>
                <div class="form-check ">
                  <input
                    type="radio"
                    className="form-check-input radio-check"
                    id="specific-350"
                    name="question-35"
                  />
                  <label for="specific-350" class="category-title">
                    Somewhat interested
                  </label>
                </div>
                <div class="form-check ">
                  <input
                    type="radio"
                    className="form-check-input radio-check"
                    id="specific-350"
                    name="question-35"
                  />
                  <label for="specific-350" class="category-title">
                    Neutral
                  </label>
                </div>
                <div class="form-check ">
                  <input
                    type="radio"
                    className="form-check-input radio-check"
                    id="specific-350"
                    name="question-35"
                  />
                  <label for="specific-350" class="category-title">
                    Not very interested
                  </label>
                </div>
                <div class="form-check ">
                  <input
                    type="radio"
                    className="form-check-input radio-check"
                    id="specific-350"
                    name="question-35"
                  />
                  <label for="specific-350" class="category-title">
                    Not at all interested
                  </label>
                </div>
                <div class="form-check ">
                  <input
                    type="radio"
                    className="form-check-input radio-check"
                    id="specific-350"
                    name="question-35"
                  />
                  <label for="specific-350" class="category-title">
                    Prefer not to say
                  </label>
                </div>
              </div>
            </div>
            <div class="main-category">
              <h2 class="qus-title">
                How confident do you feel in speaking to the communities you
                work with about health and care research at the moment?
              </h2>
              <div class="category-list border-0 pt-0">
                <div class="form-check ">
                  <input
                    type="radio"
                    className="form-check-input radio-check"
                    id="specific-351"
                    name="question-35"
                  />
                  <label for="specific-351" class="category-title">
                    Very interested
                  </label>
                </div>
                <div class="form-check ">
                  <input
                    type="radio"
                    className="form-check-input radio-check"
                    id="specific-350"
                    name="question-35"
                  />
                  <label for="specific-350" class="category-title">
                    Somewhat interested
                  </label>
                </div>
                <div class="form-check ">
                  <input
                    type="radio"
                    className="form-check-input radio-check"
                    id="specific-350"
                    name="question-35"
                  />
                  <label for="specific-350" class="category-title">
                    Neutral
                  </label>
                </div>
                <div class="form-check ">
                  <input
                    type="radio"
                    className="form-check-input radio-check"
                    id="specific-350"
                    name="question-35"
                  />
                  <label for="specific-350" class="category-title">
                    Not very interested
                  </label>
                </div>
                <div class="form-check ">
                  <input
                    type="radio"
                    className="form-check-input radio-check"
                    id="specific-350"
                    name="question-35"
                  />
                  <label for="specific-350" class="category-title">
                    Not at all interested
                  </label>
                </div>
                <div class="form-check ">
                  <input
                    type="radio"
                    className="form-check-input radio-check"
                    id="specific-350"
                    name="question-35"
                  />
                  <label for="specific-350" class="category-title">
                    Prefer not to say
                  </label>
                </div>
              </div>
            </div>
            <div class="main-category">
              <h2 class="qus-title">
                How would you like to use research in the future?
              </h2>
              <div class="category-list border-0 pt-0">
                <div class="form-check ">
                  <input
                    type="radio"
                    className="form-check-input radio-check"
                    id="specific-351"
                    name="question-35"
                  />
                  <label for="specific-351" class="category-title">
                    To inform programme/service improvements
                  </label>
                </div>
                <div class="form-check ">
                  <input
                    type="radio"
                    className="form-check-input radio-check"
                    id="specific-350"
                    name="question-35"
                  />
                  <label for="specific-350" class="category-title">
                    To advocate for policy change
                  </label>
                </div>
                <div class="form-check ">
                  <input
                    type="radio"
                    className="form-check-input radio-check"
                    id="specific-350"
                    name="question-35"
                  />
                  <label for="specific-350" class="category-title">
                    To raise awareness of social issues
                  </label>
                </div>
                <div class="form-check ">
                  <input
                    type="radio"
                    className="form-check-input radio-check"
                    id="specific-350"
                    name="question-35"
                  />
                  <label for="specific-350" class="category-title">
                    To collaborate with academic institutions
                  </label>
                </div>
                <div class="form-check ">
                  <input
                    type="radio"
                    className="form-check-input radio-check"
                    id="specific-350"
                    name="question-35"
                  />
                  <label for="specific-350" class="category-title">
                    To secure research funding
                  </label>
                </div>
                <div class="form-check ">
                  <input
                    type="radio"
                    className="form-check-input radio-check"
                    id="specific-350"
                    name="question-35"
                  />
                  <label for="specific-350" class="category-title">
                    To increase community engagement
                  </label>
                </div>
                <div class="form-check ">
                  <input
                    type="radio"
                    className="form-check-input radio-check"
                    id="specific-350"
                    name="question-35"
                  />
                  <label for="specific-350" class="category-title">
                    To increase fundraising efforts
                  </label>
                </div>
                <div class="form-check ">
                  <input
                    type="radio"
                    className="form-check-input radio-check"
                    id="specific-350"
                    name="question-35"
                  />
                  <label for="specific-350" class="category-title">
                    None of the above
                  </label>
                </div>
              </div>
            </div>
            <div class="main-category survey-category-list">
              <h2 class="qus-title">
                In the future, we would like to provide opportunities to help
                VCSE organizations feel more confident about research. Which of
                these areas would you be interested in?
              </h2>
              <div class="category-list border-0 pt-0">
                <div class="form-check">
                  <input
                    type="checkbox"
                    id="specific-39"
                    class="form-check-input"
                  />
                  <label for="specific-39" class="category-title">
                    Data collection & analysis
                  </label>
                </div>
                <div class="sub-category-list">
                  <div class="row g-3">
                    <div class="col-md-6 col-lg-4">
                      <div class="form-check">
                        <input
                          type="checkbox"
                          id="sub-39-208"
                          class="form-check-input"
                        />
                        <label for="sub-39-208">
                          Training in data collection techniques
                        </label>
                      </div>
                    </div>
                    <div class="col-md-6 col-lg-4">
                      <div class="form-check">
                        <input
                          type="checkbox"
                          id="sub-39-209"
                          class="form-check-input"
                        />
                        <label for="sub-39-209">
                          Support in data interpretation and visualisation
                        </label>
                      </div>
                    </div>
                    <div class="col-md-6 col-lg-4">
                      <div class="form-check">
                        <input
                          type="checkbox"
                          id="sub-39-210"
                          class="form-check-input"
                        />
                        <label for="sub-39-210">
                          Assistance with data quality assurance
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="category-list border-0 pt-0">
                <div class="form-check">
                  <input
                    type="checkbox"
                    id="specific-39"
                    class="form-check-input"
                  />
                  <label for="specific-39" class="category-title">
                    Research & funding
                  </label>
                </div>
                <div class="sub-category-list">
                  <div class="row g-3">
                    <div class="col-md-6 col-lg-4">
                      <div class="form-check">
                        <input
                          type="checkbox"
                          id="sub-39-208"
                          class="form-check-input"
                        />
                        <label for="sub-39-208">
                          Identifying grant oppotunities
                        </label>
                      </div>
                    </div>
                    <div class="col-md-6 col-lg-4">
                      <div class="form-check">
                        <input
                          type="checkbox"
                          id="sub-39-209"
                          class="form-check-input"
                        />
                        <label for="sub-39-209">
                          Assistance in writing grant proposals
                        </label>
                      </div>
                    </div>
                    <div class="col-md-6 col-lg-4">
                      <div class="form-check">
                        <input
                          type="checkbox"
                          id="sub-39-210"
                          class="form-check-input"
                        />
                        <label for="sub-39-210">
                          Assistance with data quality assurance
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button class="next-page login-btn">Submit</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Organisation5;
