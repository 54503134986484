import {
  CONTACT_US_FAILURE,
  CONTACT_US_REQUEST,
  CONTACT_US_SUCCESS,
  STORE_CONTACT_FORM_RESET,
} from "../Constatnts/ContactConstant";

export function contactUsReducer(state = {}, action) {
  switch (action.type) {
    case CONTACT_US_REQUEST:
      return {
        loading: true,
      };
    case CONTACT_US_SUCCESS:
      return {
        loading: false,
        contactUsData: action.payload,
      };
    case CONTACT_US_FAILURE:
      return {
        loading: false,
      };
      case STORE_CONTACT_FORM_RESET:
      return {
        loading: false,
        contactUsData: {},
      };
    default:
      return state;
  }
}
