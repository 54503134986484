import React from "react";
import DashboardMenu from "../Dashboard/DashboardMenu";
import {
  getChangePassword,
  ResetChangePasswordState,
} from "../../store/Action/UserAction";
import { useDispatch, useSelector } from "react-redux";
import { Toast } from "primereact/toast";
import { Link } from "react-router-dom";

const ChangePassword = () => {
  const dispatch = useDispatch();
  const toast = React.useRef(null);
  const [currentPassword, setCurrenetPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");

  const [currentPasswordError, setCurrentPasswodError] = React.useState("");
  const [newPasswordError, setNewPasswordError] = React.useState("");
  const [ConfirmPasswordError, setConfirmPasswordError] = React.useState("");

  const [showPassword, setShowPassword] = React.useState(false);
  const [showPassword1, setShowPassword1] = React.useState(false);
  const [showPassword2, setShowPassword2] = React.useState(false);

  const [loading, setLoading] = React.useState(false);

  const { changePasswordData } = useSelector((state) => state.changePassword);

  const vUniqueCode = localStorage.getItem("vUniqueCode");

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleNewPasswordVisibility = () => {
    setShowPassword1(!showPassword1);
  };
  const toggleConfirmwPasswordVisibility = () => {
    setShowPassword2(!showPassword2);
  };

  const handleCurrentPasswordChange = (event) => {
    const currentPasswordValue = event.target.value;
    event.target.value.trim() !== "" && setCurrentPasswodError("");
    setCurrenetPassword(currentPasswordValue);
    if (
      currentPasswordValue.trim() === "" &&
      currentPasswordValue.trim().length === 0
    ) {
      setCurrentPasswodError("Current Password is Required.");
    } else if (currentPasswordValue.trim().length < 6) {
      setCurrentPasswodError(
        "Current Password must be at least 6 characters long."
      );
    } else {
      setCurrentPasswodError("");
    }
  };

  const handleNewPasswordChange = (event) => {
    const newPasswordValue = event.target.value;
    event.target.value.trim() !== "" && setNewPasswordError("");
    setNewPassword(newPasswordValue);
    if (
      newPasswordValue.trim() === "" &&
      newPasswordValue.trim().length === 0
    ) {
      setNewPasswordError("New Password is Required.");
    } else if (newPasswordValue.trim().length < 6) {
      setNewPasswordError("New Password must be at least 6 characters long.");
    } else if (
      confirmPassword.length > 0 &&
      newPasswordValue !== confirmPassword
    ) {
      setConfirmPasswordError(
        "New Password and Confirm Password do not match."
      );
    } else {
      setNewPasswordError("");
      setConfirmPasswordError("");
    }
  };

  const handleConfirmPasswordChange = (event) => {
    const confirmPasswordValue = event.target.value;
    event.target.value.trim() !== "" && setConfirmPasswordError("");
    setConfirmPassword(confirmPasswordValue);
    if (
      confirmPasswordValue.trim() === "" &&
      confirmPasswordValue.trim().length === 0
    ) {
      setConfirmPasswordError("Confirm Password is Required.");
    } else if (confirmPasswordValue.trim().length < 6) {
      setConfirmPasswordError(
        "Confirm Password must be at least 6 characters long."
      );
    } else if (newPassword.length > 0 && confirmPasswordValue !== newPassword) {
      setConfirmPasswordError(
        "New Password and Confirm Password do not match."
      );
    } else {
      setConfirmPasswordError("");
    }
  };

  const handleChangePassword = React.useCallback(() => {
    let isValid = true;
    if (currentPassword.length === 0) {
      setCurrentPasswodError("Current Password is Required.");
      isValid = false;
    } else if (currentPassword.length < 6) {
      setCurrentPasswodError(
        "Current Password must be at least 6 characters long."
      );
      isValid = false;
    } else {
      setCurrentPasswodError("");
    }
    if (newPassword.length === 0) {
      setNewPasswordError("New Password is Required.");
      isValid = false;
    } else if (newPassword.length < 6) {
      setNewPasswordError("New Password must be at least 6 characters long.");
      isValid = false;
    } else {
      setNewPasswordError("");
    }

    if (confirmPassword.length === 0) {
      setConfirmPasswordError("Confirm Password is Required.");
      isValid = false;
    } else if (confirmPassword.length < 6) {
      setConfirmPasswordError(
        "Confirm Password must be at least 6 characters long."
      );
      isValid = false;
    } else {
      setConfirmPasswordError("");
    }

    if (newPassword !== confirmPassword) {
      setConfirmPasswordError(
        "New Password and Confirm Password do not match."
      );
      isValid = false;
    }

    if (!isValid) {
      return false;
    }
    return true;
  }, [
    newPassword,
    confirmPassword,
    setNewPasswordError,
    setCurrentPasswodError,
    currentPassword,
    setConfirmPasswordError,
  ]);

  const changePassword = React.useCallback(
    (e) => {
      e.preventDefault();

      const isValidData = handleChangePassword();

      if (isValidData) {
        dispatch(
          getChangePassword({
            vUniqueCode: vUniqueCode,
            vCurrentPassword: currentPassword,
            vPassword: newPassword,
            vConfirmPassword: confirmPassword,
          })
        );
      }
    },
    [
      dispatch,
      vUniqueCode,
      currentPassword,
      newPassword,
      confirmPassword,
      handleChangePassword,
    ]
  );

  React.useEffect(() => {
    if (changePasswordData && changePasswordData.code === 200) {
      setLoading(true);
      dispatch(ResetChangePasswordState());
      toast.current.show({
        severity: "success",
        summary: "Password Reset successfully.",
        life: 2000,
        className: "custom-toast",
      });

      setTimeout(() => {
        setConfirmPassword("");
        setNewPassword("");
        setCurrenetPassword("");
        setLoading(false);
      }, 2000);
    } else if (changePasswordData && changePasswordData.code === 404) {
      setLoading(true);
      dispatch(ResetChangePasswordState());
      toast.current.show({
        severity: "error",
        summary: changePasswordData.message,
        life: 2000,
        className: "custom-toast-error",
      });

      setTimeout(() => {
        setConfirmPassword("");
        setNewPassword("");
        setCurrenetPassword("");
        setLoading(false);
      }, 2000);
    } else if (changePasswordData && changePasswordData.code === 400) {
      setLoading(true);
      dispatch(ResetChangePasswordState());

      toast.current.show({
        severity: "error",
        summary: changePasswordData.message,
        life: 2000,
        className: "custom-toast-error",
      });
      setTimeout(() => {
        setConfirmPassword("");
        setNewPassword("");
        setCurrenetPassword("");
        setLoading(false);
      }, 2000);
    }
  }, [changePasswordData, dispatch]);

  return (
    <section className="my-profile">
      <Toast ref={toast} />
      <div className="dashboard-main-content">
        <DashboardMenu />
        <div className="profile-right-view-bg change-pass-right-view">
          <div className="row g-0 w-100">
            <div className="col-lg-12 col-xl-8 m-auto">
              <div className="change-pass-view">
                <div className="change-pass-heading">
                  <h1 className="title">Change Password</h1>
                </div>
                <div>
                  <form className="change-pass-form" onSubmit={changePassword}>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="input-box">
                          <label
                            htmlFor="exampleInputPassword1"
                            className="form-label"
                          >
                            Current Password
                          </label>
                          <input
                            type={showPassword ? "text" : "password"}
                            className="form-control"
                            placeholder="Enter Passowrd"
                            value={currentPassword}
                            onChange={handleCurrentPasswordChange}
                          />
                          <i
                            className={`toggle-password far ${
                              showPassword ? "fa-eye" : "fa-eye-slash"
                            }`}
                            onClick={togglePasswordVisibility}
                          ></i>
                          {currentPasswordError && (
                            <div
                              className="error-msg"
                              style={{
                                color: "red",
                                fontSize: "14px",
                              }}
                            >
                              {currentPasswordError}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="input-box">
                          <label
                            htmlFor="exampleInputPassword1"
                            className="form-label"
                          >
                            New Password
                          </label>
                          <input
                            type={showPassword1 ? "text" : "password"}
                            className="form-control"
                            placeholder="Enter New Passowrd"
                            value={newPassword}
                            onChange={handleNewPasswordChange}
                          />
                          <i
                            className={`toggle-password far ${
                              showPassword1 ? "fa-eye" : "fa-eye-slash"
                            }`}
                            onClick={toggleNewPasswordVisibility}
                          ></i>
                          {newPasswordError && (
                            <div
                              className="error-msg"
                              style={{
                                color: "red",
                                fontSize: "14px",
                              }}
                            >
                              {newPasswordError}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="input-box">
                          <label
                            htmlFor="exampleInputPassword1"
                            className="form-label"
                          >
                            Confirm Password
                          </label>
                          <input
                            type={showPassword2 ? "text" : "password"}
                            className="form-control"
                            placeholder="Enter Confirm Passowrd"
                            value={confirmPassword}
                            onChange={handleConfirmPasswordChange}
                          />
                          <i
                            className={`toggle-password far ${
                              showPassword2 ? "fa-eye" : "fa-eye-slash"
                            }`}
                            onClick={toggleConfirmwPasswordVisibility}
                          ></i>
                          {ConfirmPasswordError && (
                            <div
                              className="error-msg"
                              style={{
                                color: "red",
                                fontSize: "14px",
                              }}
                            >
                              {ConfirmPasswordError}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="login-btn-view profile-btn">
                          {loading ? (
                            <>
                              {" "}
                              <button
                                className="login-btn"
                                type="button"
                                disabled
                              >
                                <span
                                  className="spinner-border spinner-border-sm me-1"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                                Loading...
                              </button>
                            </>
                          ) : (
                            <>
                              {" "}
                              <button type="submit" className="login-btn">
                                Submit
                              </button>
                            </>
                          )}

                          <Link
                            to="/dashboard"
                            className="login-btn cancel-btn"
                          >
                            Cancel
                          </Link>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ChangePassword;
