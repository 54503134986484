import React from "react";
import Register from "../components/Common/Register";

const RegisterPage = () => {
  return (
    <React.Fragment>
      <div>
        <Register />
      </div>
    </React.Fragment>
  );
};

export default RegisterPage;
