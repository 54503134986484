import React from "react";
import DashboardMenu from "../Dashboard/DashboardMenu";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserProfile,
  getUserProfileUpdate,
  ResetProfileUpdateState,
} from "../../store/Action/UserAction";
import { Link } from "react-router-dom";
import { Toast } from "primereact/toast";

const Profile = () => {
  const dispatch = useDispatch();
  const toast = React.useRef(null);
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [contact, setContact] = React.useState("");
  const [bio, setBio] = React.useState("");

  const [selectedImage, setSelectedImage] = React.useState(null);

  const [contactError, setContactError] = React.useState("");
  const [firstNameError, setFirstNameError] = React.useState("");

  const [lastNameError, setLastNameError] = React.useState("");
  const [emailError, setEmailError] = React.useState("");

  const [loading, setLoading] = React.useState(false);

  const [profileLoading, setProfileLoading] = React.useState(true);

  const { userProfileData } = useSelector((state) => state.userProfile);
  const { userProfileUpdateData } = useSelector(
    (state) => state.userProfileUpdate
  );
  const vUniqueCode = localStorage.getItem("vUniqueCode");

  const noImage = "../assets/image/profile.jpg";

  const [profilePictureUrl, setProfilePictureUrl] = React.useState(
    userProfileData?.data?.vWebpImage
      ? userProfileData?.data?.vWebpImage
      : noImage
  );

  // handle image selection
  const allowedImageTypes = ["image/jpeg", "image/png", "image/jpg"];
  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      if (allowedImageTypes.includes(file.type)) {
        setSelectedImage(file);
        const reader = new FileReader();
        reader.onload = (e) => {
          // setImagePreview(e.target.result);
          setProfilePictureUrl(e.target.result); // Update the profile picture URL
          setSelectedImage(file); // Update selected image
        };
        reader.readAsDataURL(file);
      } else {
        alert(
          "Unsupported file type. Please select a .jpg, .jpeg, or .png file."
        );
        e.target.value = null;
      }
    }
  };

  const handleFirstNameChange = (event) => {
    const firstNameValue = event.target.value.trim();
    event.target.value.trim() !== "" && setFirstNameError("");
    setFirstName(event.target.value);
    if (firstNameValue.length === 0 && firstNameValue === "") {
      setFirstNameError("FirstName is Required");
    }
  };

  const handleLastNameChange = (event) => {
    const lastNameValue = event.target.value.trim();
    event.target.value.trim() !== "" && setLastNameError("");
    setLastName(event.target.value);

    if (lastNameValue.length === 0 && lastNameValue === "") {
      setLastNameError("LastName is Required");
    }
  };

  const handleEmailChange = (event) => {
    const enteredEmail = event.target.value.trim();
    setEmail(enteredEmail);

    event.target.value.trim() !== "" && setEmailError("");

    // Regular expression for basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (enteredEmail.trim() === "") {
      setEmailError("Email is required");
    } else if (!emailRegex.test(enteredEmail)) {
      setEmailError("Invalid email format");
    } else {
      setEmailError("");
    }
  };

  const handleContactChange = (event) => {
    const contactNumber = event.target.value.trim();

    setContact(contactNumber);

    event.target.value.trim() !== "" && setContactError("");

    // Check if the contact number has a valid length
    if (contactNumber.trim() === "") {
      setContactError("Phone Number is Required.");
    } else if (+contactNumber.length >= 10 && +contactNumber.length <= 12) {
      setContactError("");
    } else {
      // setContact("");
      setContactError("Phone Number must be between 10 and 12 digits.");
    }
  };

  const handleBioChange = (event) => {
    setBio(event.target.value);
  };

  React.useEffect(() => {
    if (
      userProfileData &&
      userProfileData.code === 200 &&
      userProfileData.data
    ) {
      let data = userProfileData.data;
      setFirstName(data.vFirstName || "");
      setLastName(data.vLastName || "");
      setEmail(data.vEmail || "");
      setContact(data.vPhone || "");
      setSelectedImage(data.vImage || "");
      setProfilePictureUrl(data.vImage || "");

      setBio(data && data.tAbout);
    }
  }, [userProfileData]);

  React.useEffect(() => {
    if (!userProfileData) {
      dispatch(
        getUserProfile({
          vUniqueCode: vUniqueCode,
        })
      );
    }
  }, [userProfileData, dispatch, vUniqueCode]);

  React.useEffect(() => {
    if (userProfileData) {
      setProfileLoading(false);
    }
  }, [userProfileData]);

  const HandleUpdateProfoile = React.useCallback(() => {
    let isValid = true;
    // Regular expression for basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    setFirstNameError("");
    setLastNameError("");
    setContactError("");
    setEmailError("");

    if (firstName.length === 0 && firstName === "") {
      setFirstNameError("FirstName is Required");
      isValid = false;
    }
    if (lastName.length === 0 && lastName === "") {
      setLastNameError("LastName is Required");
      isValid = false;
    }
    if (email.length === 0 && email === "") {
      setEmailError("Email is required");
      isValid = false;
    } else if (!emailRegex.test(email)) {
      setEmailError("Invalid email format");
      isValid = false;
    }
    if (contact.length === 0 && contact === "") {
      setContactError("Contact is Required.");
      isValid = false;
    } else if (+contact.length >= 10 && +contact.length <= 12) {
      setContactError("");
    } else {
      setContactError("Contact number must be between 10 and 12 digits.");
      isValid = false;
    }
    if (!isValid) {
      return false;
    }
    return true;
  }, [
    firstName,
    setFirstNameError,
    lastName,
    setLastNameError,
    email,
    setEmailError,
    contact,
    setContactError,
  ]);

  const updateProfile = React.useCallback(
    async (e) => {
      e.preventDefault();

      const formData = new FormData();
      formData.append("vFirstName", firstName);
      formData.append("vLastName", lastName);
      formData.append("vEmail", email);
      formData.append("vPhone", contact);
      formData.append("tAbout", bio);
      formData.append("vImage", selectedImage);
      // for (const item of formData) {
      //   console.log("item", item);
      // }

      const isValidData = HandleUpdateProfoile();

      if (isValidData) {
        dispatch(getUserProfileUpdate(formData));
      }
    },
    [
      dispatch,
      firstName,
      lastName,
      contact,
      email,
      bio,
      selectedImage,
      HandleUpdateProfoile,
    ]
  );

  React.useEffect(() => {
    if (userProfileUpdateData && userProfileUpdateData.code === 200) {
      setLoading(true);
      dispatch(ResetProfileUpdateState());
      toast.current.show({
        severity: "success",
        summary: "User Profile Updated Successfully.",
        life: 2000,
        className: "custom-toast",
      });

      setTimeout(() => {
        window.location.reload();
        setLoading(false);
      }, 2000);
    }
  }, [dispatch, userProfileUpdateData]);

  return (
    // After Login Header
    <>
      <section className="my-profile">
        <Toast ref={toast} />
        <div className="dashboard-main-content">
          <DashboardMenu />

          <div className="profile-right-view-bg">
            <div className="row g-0">
              <div className="col-lg-10 col-xxl-7 m-auto">
                <div className="profile-right-view">
                  <h1 className="title">My Profile</h1>
                  {profileLoading ? (
                    <div style={{ height: "200px" }}></div>
                  ) : userProfileData &&
                    userProfileData.code === 200 &&
                    userProfileData.data ? (
                    <div className="profile-box">
                      <div className="profile-box-bg">
                        <img
                          src="assets/image/profile-box-bg.png"
                          alt="profile"
                          width="100"
                          height="150"
                        />
                      </div>
                      <div className="form-box">
                        <div className="profile-form">
                          <form onSubmit={updateProfile}>
                            <div className="row">
                              <div className="dash-edit-profile">
                                <div className="edit-profile-pic">
                                  <img
                                    src={
                                      profilePictureUrl
                                        ? profilePictureUrl
                                        : noImage
                                    }
                                    alt={userProfileData.data.vImageAlt}
                                    onError={({ currentTarget }) => {
                                      currentTarget.onerror = null;
                                      currentTarget.src = noImage;
                                    }}
                                    height="145"
                                    width="145"
                                  />
                                  <input
                                    type="file"
                                    id="myFile"
                                    name="filename"
                                    className="file-input__input"
                                    onChange={handleImageChange}
                                  />
                                  <label
                                    className="file-input__label"
                                    htmlFor="myFile"
                                  >
                                    <i className="fas fa-pen"></i>
                                  </label>
                                </div>
                                <h2 className="dash-title m-0">
                                  {userProfileData?.data?.vFirstName === null ||
                                  userProfileData?.data?.vLastName === null
                                    ? email
                                    : `${userProfileData.data.vFirstName} ${userProfileData.data.vLastName}`}
                                </h2>
                              </div>
                              <div className="col-lg-12 col-xl-6">
                                <div className="input-box">
                                  <label htmlFor="name" className="form-label">
                                    First Name
                                  </label>
                                  <input
                                    type="name"
                                    className="form-control"
                                    placeholder="Enter First Name"
                                    value={firstName}
                                    onChange={handleFirstNameChange}
                                  />
                                  {firstNameError && (
                                    <div
                                      className="error-msg"
                                      style={{
                                        color: "red",
                                        fontSize: "14px",
                                        marginLeft: "8px",
                                      }}
                                    >
                                      {firstNameError}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="col-lg-12 col-xl-6">
                                <div className="input-box">
                                  <label htmlFor="name" className="form-label">
                                    Last Name
                                  </label>
                                  <input
                                    type="name"
                                    className="form-control"
                                    placeholder="Enter Last Name"
                                    value={lastName}
                                    onChange={handleLastNameChange}
                                  />
                                  {lastNameError && (
                                    <div
                                      className="error-msg"
                                      style={{
                                        color: "red",
                                        fontSize: "14px",
                                        marginLeft: "8px",
                                      }}
                                    >
                                      {lastNameError}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="col-lg-12 col-xl-6">
                                <div className="input-box">
                                  <label
                                    htmlFor="exampleInputEmail1"
                                    className="form-label"
                                  >
                                    Email address
                                  </label>
                                  <input
                                    type="email"
                                    className="form-control"
                                    placeholder="Enter Email Address"
                                    value={email}
                                    onChange={handleEmailChange}
                                  />
                                  {emailError && (
                                    <div
                                      className="error-msg"
                                      style={{
                                        color: "red",
                                        fontSize: "14px",
                                        marginLeft: "8px",
                                      }}
                                    >
                                      {emailError}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="col-lg-12 col-xl-6">
                                <div className="input-box">
                                  <label
                                    htmlFor="enumber"
                                    className="form-label"
                                  >
                                    Phone Number
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Phone Number"
                                    value={contact}
                                    onChange={handleContactChange}
                                    maxLength={12}
                                    onKeyPress={(e) => {
                                      // Allow only digits and the plus sign
                                      const validCharacters = /^[0-9+]+$/;
                                      if (!validCharacters.test(e.key)) {
                                        e.preventDefault();
                                      }
                                    }}
                                  />
                                  {contactError && (
                                    <div
                                      className="error-msg"
                                      style={{
                                        color: "red",
                                        fontSize: "14px",
                                        marginLeft: "8px",
                                      }}
                                    >
                                      {contactError}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="input-box">
                                  <label htmlFor="text" className="form-label">
                                    About Me
                                  </label>
                                  <textarea
                                    type="text"
                                    className="form-control"
                                    rows="4"
                                    cols="50"
                                    placeholder="Type Something Here..."
                                    value={bio === "null" ? "" : bio}
                                    onChange={handleBioChange}
                                  ></textarea>
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="login-btn-view profile-btn">
                                  {loading ? (
                                    <>
                                      {" "}
                                      <button
                                        className="login-btn"
                                        type="button"
                                        disabled
                                      >
                                        <span
                                          className="spinner-border spinner-border-sm me-1"
                                          role="status"
                                          aria-hidden="true"
                                        ></span>
                                        Loading...
                                      </button>
                                    </>
                                  ) : (
                                    <>
                                      {" "}
                                      <button
                                        type="submit"
                                        className="login-btn"
                                      >
                                        Save
                                      </button>
                                    </>
                                  )}

                                  <Link
                                    to="/dashboard"
                                    className="login-btn cancel-btn"
                                  >
                                    Cancel
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      {" "}
                      <div className="col-12">
                        <p
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "10px 0",
                          }}
                        >
                          No Data Found
                        </p>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Profile;
