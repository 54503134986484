import React from "react";
import Dashboard from "../components/Dashboard/Dashboard";

import { useNavigate } from "react-router-dom";
import Header from "../components/Layouts/Header";

const DashboardPage = () => {
  const navigate = useNavigate();
  const vUniqueCode = localStorage.getItem("vUniqueCode");

  React.useEffect(() => {
    if (!vUniqueCode) {
      navigate("/");
    }
  }, [vUniqueCode, navigate]);
  return (
    <React.Fragment>
      <div>
        <Header />
        <Dashboard />
      </div>
    </React.Fragment>
  );
};

export default DashboardPage;
