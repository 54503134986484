import React, { useState } from "react";
import Header from "../Layouts/Header";
import { useLocation, useNavigate } from "react-router-dom";

const Survey = () => {
  const navigate = useNavigate();
  const [agree, setAgree] = useState("");
  const [disagree, setDisagree] = useState("");
  const [loading, setLoading] = React.useState(false);
  const { state } = useLocation();
  const stateData = (state && state?.path) || state;
  const stateDataString = JSON.stringify(stateData);

  const parts = stateDataString?.split("/survey/")[1];
  const uniqueCodes = parts?.split("/");
  const firstUniqueCode = uniqueCodes ? uniqueCodes[0] : "";
  const secondUniqueCode = uniqueCodes ? uniqueCodes[1] : "";

  const participantvUniqueCode = localStorage.getItem(
    "participantvUniqueCodeNew"
  );

  const surveyId = state?.surveyId;
  const surveyName = state?.surveyName;
  const SurveyType = state?.SurveyType;




  React.useEffect(() => {
    // const surveyId = state?.surveyId;
    // const surveyName = state?.surveyName;
    // const SurveyType = state?.SurveyType;

    const storedAgree = localStorage.getItem(`agree_${SurveyType}`);
    const storedDisagree = localStorage.getItem(`disagree_${SurveyType}`);

    if (storedAgree) setAgree(storedAgree);
    if (storedDisagree) setDisagree(storedDisagree);
  }, [surveyId]);

  const handleAgreeClick = (Agree) => {
    setAgree(Agree);
    setDisagree("");
    localStorage.setItem(`agree_${SurveyType}`, Agree);
    localStorage.removeItem(`disagree_${SurveyType}`);
  };

  const handleDisagreeClick = (Disagree) => {
    setDisagree(Disagree);
    setAgree("");

    localStorage.setItem(`disagree_${SurveyType}`, Disagree);
    localStorage.removeItem(`agree_${SurveyType}`);
  };

  const handleSubmit = () => {
    setLoading(true);
    setTimeout(() => {
      if (agree && SurveyType === "Academic") {
        navigate("/general-info", {
          state: {
            vUniqueCode:
              state?.surveyCode || state?.vUniqueCode || secondUniqueCode,
            SurveyId: state?.surveyId,
            participantvUniqueCode: participantvUniqueCode,
            SurveyType: SurveyType,
          },
        });
      } else if (agree && SurveyType === "Organisation") {
        navigate("/survey", {
          state: {
            vUniqueCode:
              state?.surveyCode || state?.vUniqueCode || secondUniqueCode,
            SurveyId: state?.surveyId,
            participantvUniqueCode: participantvUniqueCode,

            SurveyType: SurveyType,
          },
        });
      } else if (disagree && SurveyType === "Academic") {
        navigate("/survey/thank-you");
      } else if (disagree && SurveyType === "Organisation") {
        navigate("/organisation/questions/page-6");
      } else if (!agree && !disagree && SurveyType === "Academic") {
        navigate("/survey/thank-you");
      }
      setLoading(false);
    }, 1000);
  };

  return (
    <React.Fragment>
      <Header />

      <div className="survey-page">
        <div className="container">
          <h1 className="main-title text-start">
            Research Ready Nottingham and Nottinghamshire: Building a Dashboard
            to Increase Diversity of Research Engagement.
          </h1>
          <div className="survey-content">
            <h3>About the project</h3>
            <p>
              You are invited to take part in this research study. This study
              focuses on making more visible and discoverable the VCSE
              organisations that are interested in and are open to engagement
              with academic research. The ultimate objective of this project is
              the creation of a digital platform to enhance the research
              capabilities and understanding of Nottinghamshire’s communities.
            </p>
            <ul>
              <li>
                You have been provided with information about the project.
              </li>
              <li>
                You have been told who to contact if you have questions before,
                during or after your participation.
              </li>
              <li>
                You understand what participation in this project involves.
              </li>
              <li>You are 18 years of age or older.</li>
              <li>You voluntarily agree to participate.</li>
            </ul>
            <div className="form-check">
              <input
                type="radio"
                className="form-check-input"
                id="Agree"
                name="radio1"
                value="Agree"
                onChange={() => handleAgreeClick("Agree")}
                checked={agree === "Agree"}
              />
              <label htmlFor="Agree">Agree</label>
            </div>
            <div className="form-check">
              <input
                type="radio"
                className="form-check-input"
                id="Disagree"
                name="radio1"
                value="Disagree"
                onChange={() => handleDisagreeClick("Disagree")}
                checked={disagree === "Disagree"}
              />
              <label htmlFor="Disagree">Disagree</label>
            </div>
            {/* 
            {error && <div style={{ color: "red" }}>{error}</div>} */}
            {loading ? (
              <>
                {" "}
                <button className="login-btn mt-3" type="button" disabled>
                  <span
                    className="spinner-border spinner-border-sm me-1"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Loading...
                </button>
              </>
            ) : (
              <button
                className="login-btn mt-3"
                type="submit"
                onClick={handleSubmit}
              >
                Next
              </button>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Survey;

// // import React from "react";
// // import Header from "../Layouts/Header";
// // import { useDispatch, useSelector } from "react-redux";
// // import {
// //   getSurveyAnswerList,
// //   getSurveyQuestionList,
// // } from "../../store/Action/SurveyAction";
// // import { useLocation, useNavigate } from "react-router-dom";

// // const QuestionThree = () => {
// //   const dispatch = useDispatch();
// //   const location = useLocation();
// //   const navigate = useNavigate();
// //   const { state } = location;
// //   const vUniqueCode = state?.vUniqueCode || null;

// //   const SurveyId = state?.SurveyId || null;

// //   const participantvUniqueCodeNew = localStorage.getItem(
// //     "participantvUniqueCodeNew"
// //   );

// //   const [buttonLoading, setButtonLoading] = React.useState(false);

// //   const [currentQuestionIndex, setCurrentQuestionIndex] = React.useState(() => {
// //     const savedIndex = localStorage.getItem("currentQuestionIndex");
// //     return savedIndex ? parseInt(savedIndex, 10) : 0;
// //   });

// //   const [errors, setErrors] = React.useState({});
// //   const [email, setEmail] = React.useState("");
// //   const [pageNumber, setPageNumber] = React.useState(1);
// //   const [emailError, setEmailError] = React.useState("");
// //   const [selectedCategories, setSelectedCategories] = React.useState(
// //     JSON.parse(localStorage.getItem("selectedCategories")) || {}
// //   );

// //   const { surveyQuestionData, loading } = useSelector(
// //     (state) => state.surveyQuestion
// //   );

// //   const participantSurveyId = surveyQuestionData?.data?.Data?.[0]?.iSurveyId;

// //   const { surveyAnswerData } = useSelector((state) => state.surveyAnswer);

// //   const userUniqueCode = localStorage.getItem("vUniqueCode");

// //   const { participantUniqueCode } = useSelector((state) => state.participant);

// //   // Save currentQuestionIndex to localStorage when it changes
// //   React.useEffect(() => {
// //     localStorage.setItem("currentQuestionIndex", currentQuestionIndex);
// //   }, [currentQuestionIndex]);

// //   // Retrieve email from localStorage when the component mounts
// //   React.useEffect(() => {
// //     const savedEmail = localStorage.getItem("email");
// //     if (savedEmail) {
// //       setEmail(savedEmail);
// //     }
// //   }, []);

// //   const handleEmailChange = (event) => {
// //     const emailValue = event.target.value.trim();
// //     setEmail(emailValue);
// //     event.target.value.trim() !== "" && setEmailError("");

// //     // Regular expsion for basic email validation
// //     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
// //     if (!emailRegex.test(emailValue)) {
// //       setEmailError("Invalid Email format.");
// //     } else {
// //       setEmailError("");
// //       localStorage.setItem("email", emailValue);
// //     }
// //   };

// //   React.useEffect(() => {
// //     const cleanedUniqueCode = vUniqueCode && vUniqueCode.replace(/^"|"$/g, "");
// //     dispatch(
// //       getSurveyQuestionList({
// //         vUniqueCode: cleanedUniqueCode,
// //         // iPageNo:1 ,
// //       })
// //     );
// //   }, [dispatch, vUniqueCode]);

// //   React.useEffect(() => {
// //     localStorage.setItem(
// //       "selectedCategories",
// //       JSON.stringify(selectedCategories)
// //     );
// //   }, [selectedCategories]);

// //   const handleCategorySelection = (categoryId) => {
// //     setSelectedCategories((prevSelected) => {
// //       const updatedCategories = {
// //         ...prevSelected,
// //         [categoryId]: {
// //           ...prevSelected[categoryId],
// //           selected: !prevSelected[categoryId]?.selected,
// //           subCategories: prevSelected[categoryId]?.subCategories || {},
// //           isValid: prevSelected[categoryId]?.isValid || false,
// //         },
// //       };

// //       const isAnyCategorySelected = Object.values(updatedCategories).some(
// //         (category) => category.selected
// //       );

// //       // Update errors for current question index
// //       setErrors((prevErrors) => ({
// //         ...prevErrors,
// //         [currentQuestionIndex]: isAnyCategorySelected
// //           ? ""
// //           : "Please select at least one category.",
// //       }));

// //       return updatedCategories;
// //     });
// //   };

// //   const handleSubCategorySelection = (categoryId, subCategoryId) => {
// //     setSelectedCategories((prevSelected) => {
// //       const updatedSubCategories = {
// //         ...prevSelected[categoryId],
// //         subCategories: {
// //           ...prevSelected[categoryId]?.subCategories,
// //           [subCategoryId]:
// //             !prevSelected[categoryId]?.subCategories?.[subCategoryId],
// //         },
// //       };

// //       const isSubCategorySelected = Object.values(
// //         updatedSubCategories.subCategories
// //       ).some((isSelected) => isSelected);

// //       const updatedCategories = {
// //         ...prevSelected,
// //         [categoryId]: {
// //           ...updatedSubCategories,
// //           isValid: isSubCategorySelected,
// //         },
// //       };

// //       const isAllSelectedCategoriesValid = Object.values(
// //         updatedCategories
// //       ).every((category) => !category.selected || category.isValid);

// //       // Update errors for current question index
// //       setErrors((prevErrors) => ({
// //         ...prevErrors,
// //         [currentQuestionIndex]: isAllSelectedCategoriesValid
// //           ? ""
// //           : "Please select at least one subcategory for each selected category.",
// //       }));

// //       return updatedCategories;
// //     });
// //   };

// //   // Handle the next question navigation
// //   const handleNextQuestion = async () => {
// //     const currentQuestion =
// //       surveyQuestionData?.data?.Data[currentQuestionIndex];

// //     const currentQuestionId =
// //       surveyQuestionData?.data?.Data[currentQuestionIndex].iQuestionId;
// //     const currentQuestionType =
// //       surveyQuestionData?.data?.Data[currentQuestionIndex].eType;

// //     const isAnyCategorySelected = currentQuestion?.categories?.some(
// //       (category) => selectedCategories[category.iCategoryId]?.selected
// //     );

// //     if (!isAnyCategorySelected) {
// //       setErrors((prevErrors) => ({
// //         ...prevErrors,
// //         [currentQuestionIndex]: "Please select at least one category.",
// //       }));
// //       return;
// //     }

// //     const isAllSelectedCategoriesValid = currentQuestion?.categories?.every(
// //       (category) => {
// //         if (selectedCategories[category.iCategoryId]?.selected) {
// //           const hasSelectedSubCategory = Object.values(
// //             selectedCategories[category.iCategoryId]?.subCategories || {}
// //           ).some((isSelected) => isSelected);
// //           return hasSelectedSubCategory;
// //         }
// //         return true;
// //       }
// //     );

// //     if (!isAllSelectedCategoriesValid) {
// //       setErrors((prevErrors) => ({
// //         ...prevErrors,
// //         [currentQuestionIndex]:
// //           "Please select at least one subcategory for each selected category.",
// //       }));
// //       return;
// //     }

// //     // Clear error for the current question when moving to the next
// //     setErrors((prevErrors) => ({
// //       ...prevErrors,
// //       [currentQuestionIndex]: "",
// //     }));

// //     const iOptionId = currentQuestion?.categories
// //       .map((category) => {
// //         if (selectedCategories[category.iCategoryId]?.selected) {
// //           const selectedSubCategoryIds = Object.keys(
// //             selectedCategories[category.iCategoryId]?.subCategories || {}
// //           ).filter(
// //             (subCatId) =>
// //               selectedCategories[category.iCategoryId].subCategories[subCatId]
// //           );

// //           return {
// //             [category.iCategoryId]: selectedSubCategoryIds.map(Number),
// //           };
// //         }
// //         return null;
// //       })
// //       .filter(Boolean);

// //     const payload = {
// //       vUniqueCode:
// //         participantUniqueCode || userUniqueCode || participantvUniqueCodeNew,
// //       iQuestionId: [{ [currentQuestionId]: currentQuestionType }],
// //       iOptionId: [iOptionId],
// //       iSurveyId: participantSurveyId || SurveyId,
// //       iPageNo: pageNumber,
// //     };

// //     try {
// //       setButtonLoading(true);
// //       const response = await fetch(
// //         `${process.env.REACT_APP_API_URL}/api/question/answer`,
// //         {
// //           method: "POST",
// //           headers: {
// //             "Content-Type": "application/json",
// //           },
// //           body: JSON.stringify(payload),
// //         }
// //       );

// //       const responseData = await response.json();
// //       if (responseData && responseData.status === 200) {
// //         setButtonLoading(false);
// //         if (currentQuestionIndex < surveyQuestionData?.data?.Data.length - 1) {
// //           setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
// //           window.scroll(0, 0);
// //         } else {
// //           // Final submission or next step logic here
// //         }
// //       }
// //       setPageNumber(pageNumber + 1);

// //       if (
// //         responseData &&
// //         responseData.data &&
// //         responseData.data.survey_status === "Completed"
// //       ) {
// //         navigate("/survey/thank-you");
// //         localStorage.removeItem("selectedCategories");
// //         localStorage.removeItem("education");
// //         localStorage.removeItem("researchOption");
// //         localStorage.removeItem("Other");
// //         localStorage.removeItem("agree");
// //         localStorage.removeItem("disagree");
// //         localStorage.removeItem("currentQuestionIndex");
// //         localStorage.removeItem("participantvUniqueCodeNew");
// //         localStorage.removeItem("email");
// //       }
// //     } catch (err) {
// //       // Handle error
// //     }
// //   };

// //   // Handle the back question navigation
// //   const handleBackQuestion = () => {
// //     if (currentQuestionIndex > 0) {
// //       // Clear error for the previous question when going back
// //       setErrors((prevErrors) => ({
// //         ...prevErrors,
// //         [currentQuestionIndex - 1]: "",
// //       }));
// //       setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
// //     }
// //     setPageNumber(pageNumber - 1);
// //   };

// //   // Handle the back navigation to a previous page
// //   const handleBack = () => {
// //     navigate("/general-info", {
// //       state: { vUniqueCode, SurveyId, participantvUniqueCodeNew },
// //     });
// //   };

// //   return (
// //     <>
// //       <Header />
// //       <div className="questions-wrp survey-category survey-page">
// //         {loading ? (
// //           <>
// //             <div
// //               style={{
// //                 display: "flex",
// //                 justifyContent: "center",
// //                 alignItems: "center",
// //                 marginTop: "50px",
// //                 marginBottom: "50px",
// //               }}
// //             >
// //               {loading && (
// //                 <img
// //                   src="/assets/gif/350.gif"
// //                   className="loading-gif"
// //                   alt="Loading..."
// //                 />
// //               )}
// //             </div>
// //           </>
// //         ) : (
// //           <>
// //             {surveyQuestionData &&
// //             surveyQuestionData?.code === 200 &&
// //             surveyQuestionData?.data?.Data &&
// //             surveyQuestionData?.data?.Data.length > 0 ? (
// //               <>
// //                 <div className="container">
// //                   <div className="main-category">
// //                     <h2 className="qus-title">
// //                       {
// //                         surveyQuestionData?.data?.Data[currentQuestionIndex]
// //                           .vQuestion
// //                       }
// //                     </h2>
// //                     {surveyQuestionData?.data?.Data[
// //                       currentQuestionIndex
// //                     ]?.categories?.map((category) => (
// //                       <div
// //                         className="category-list border-0 pt-0"
// //                         key={category.iCategoryId}
// //                       >
// //                         <div className="form-check">
// //                           <input
// //                             type="checkbox"
// //                             id={`specific-${category.iCategoryId}`}
// //                             className="form-check-input"
// //                             onChange={() =>
// //                               handleCategorySelection(category.iCategoryId)
// //                             }
// //                             checked={
// //                               selectedCategories[category.iCategoryId]
// //                                 ?.selected || false
// //                             }
// //                           />
// //                           <label
// //                             htmlFor={`specific-${category.iCategoryId}`}
// //                             className="category-title"
// //                           >
// //                             {category.vCategory}
// //                           </label>
// //                         </div>

// //                         {selectedCategories[category.iCategoryId]?.selected && (
// //                           <div className="sub-category-list">
// //                             <div className="row g-3">
// //                               {category?.subCategories?.map((subCategory) => (
// //                                 <div
// //                                   className="col-md-6 col-lg-4"
// //                                   key={subCategory.iSubCategoryId}
// //                                 >
// //                                   <div className="form-check">
// //                                     <input
// //                                       type="checkbox"
// //                                       id={`sub-${category.iCategoryId}-${subCategory.iSubCategoryId}`}
// //                                       className="form-check-input"
// //                                       onChange={() =>
// //                                         handleSubCategorySelection(
// //                                           category.iCategoryId,
// //                                           subCategory.iSubCategoryId
// //                                         )
// //                                       }
// //                                       checked={
// //                                         selectedCategories[category.iCategoryId]
// //                                           ?.subCategories?.[
// //                                           subCategory.iSubCategoryId
// //                                         ] || false
// //                                       }
// //                                     />
// //                                     <label
// //                                       htmlFor={`sub-${category.iCategoryId}-${subCategory.iSubCategoryId}`}
// //                                     >
// //                                       {subCategory.vSubCategory}
// //                                     </label>
// //                                   </div>
// //                                 </div>
// //                               ))}
// //                             </div>
// //                           </div>
// //                         )}
// //                       </div>
// //                     ))}
// //                     {/* {error && (
// //                       <div style={{ color: "red", marginBottom: "10px" }}>
// //                         {error}
// //                       </div>
// //                     )} */}
// //                     {errors[currentQuestionIndex] && (
// //                       <div
// //                         style={{ color: "red", marginBottom: "10px" }}
// //                         className="error-message"
// //                       >
// //                         {errors[currentQuestionIndex]}
// //                       </div>
// //                     )}
// //                   </div>

// //                   {currentQuestionIndex === 1 && (
// //                     <div>
// //                       <h3>
// //                         If you are interested in gaining access to updates on
// //                         this research, please enter your contact information
// //                         below. We will contact you concerning publication of the
// //                         results:
// //                       </h3>
// //                       <div className="row">
// //                         <div className="col-md-6 col-lg-4">
// //                           <div className="form-group">
// //                             <input
// //                               type="text"
// //                               className="form-control"
// //                               placeholder="Email Address"
// //                               value={email}
// //                               onChange={handleEmailChange}
// //                             />
// //                             {emailError && (
// //                               <div
// //                                 className="error-msg"
// //                                 style={{
// //                                   color: "red",
// //                                   marginLeft: "5px",
// //                                   fontSize: "14px",
// //                                 }}
// //                               >
// //                                 {" "}
// //                                 {emailError}
// //                               </div>
// //                             )}
// //                           </div>
// //                         </div>
// //                       </div>
// //                     </div>
// //                   )}

// //                   {buttonLoading ? (
// //                     <button
// //                       className="next-page login-btn"
// //                       type="button"
// //                       disabled
// //                     >
// //                       <span
// //                         className="spinner-border spinner-border-sm me-1"
// //                         role="status"
// //                         aria-hidden="true"
// //                       ></span>
// //                       {currentQuestionIndex ===
// //                       surveyQuestionData.data.Data.length - 1
// //                         ? "Submitting..."
// //                         : "Loading..."}
// //                     </button>
// //                   ) : (
// //                     <button
// //                       className="next-page login-btn"
// //                       onClick={handleNextQuestion}
// //                     >
// //                       {currentQuestionIndex <
// //                       surveyQuestionData.data.Data.length - 1
// //                         ? "Next"
// //                         : "Submit"}
// //                     </button>
// //                   )}

// //                   {/* Conditionally show the Back button */}
// //                   {currentQuestionIndex > 0 &&
// //                     currentQuestionIndex <
// //                       surveyQuestionData.data.Data.length - 1 && (
// //                       <button
// //                         style={{ marginLeft: "20px" }}
// //                         className="next-page back-btn"
// //                         onClick={handleBackQuestion}
// //                       >
// //                         Back
// //                       </button>
// //                     )}

// //                   {currentQuestionIndex === 0 &&
// //                     surveyQuestionData.data.Data.length > 1 && (
// //                       <button
// //                         className="next-page back-btn"
// //                         type="submit"
// //                         style={{ marginLeft: "20px" }}
// //                         onClick={handleBack}
// //                       >
// //                         Back
// //                       </button>
// //                     )}
// //                 </div>
// //               </>
// //             ) : (
// //               <>
// //                 <div
// //                   className="no-data"
// //                   style={{
// //                     display: "flex",
// //                     justifyContent: "center",
// //                     alignItems: "center",
// //                     padding: "10px 0",
// //                     marginTop: "50px",
// //                   }}
// //                 >
// //                   No Data Found
// //                 </div>
// //               </>
// //             )}
// //           </>
// //         )}
// //       </div>
// //     </>
// //   );
// // };

// // export default QuestionThree;

// import React from "react";
// import Header from "../Layouts/Header";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   getSurveyAnswerList,
//   getSurveyQuestionList,
// } from "../../store/Action/SurveyAction";
// import { useLocation, useNavigate } from "react-router-dom";

// const QuestionThree = () => {
//   const dispatch = useDispatch();
//   const location = useLocation();
//   const navigate = useNavigate();
//   const { state } = location;
//   const vUniqueCode = state?.vUniqueCode || null;
//   const SurveyId = state?.SurveyId || null;
//   const participantvUniqueCodeNew = localStorage.getItem(
//     "participantvUniqueCodeNew"
//   );

//   const [buttonLoading, setButtonLoading] = React.useState(false);
//   const [pageNumber, setPageNumber] = React.useState(
//     // Initialize with the value from localStorage or default to 1
//     Number(localStorage.getItem("pageNumber")) || 1
//   );
//   const [errors, setErrors] = React.useState({});
//   const [email, setEmail] = React.useState("");
//   const [emailError, setEmailError] = React.useState("");

//   const [inputValues, setInputValues] = React.useState({});
//   const [textInputValueError, setTextInputValueError] = React.useState("");
//   const [selectedCategories, setSelectedCategories] = React.useState(
//     JSON.parse(localStorage.getItem("selectedCategories")) || {}
//   );

//   const { surveyQuestionData, loading } = useSelector(
//     (state) => state.surveyQuestion
//   );
//   const participantSurveyId = surveyQuestionData?.data?.Data?.[0]?.iSurveyId;
//   const { surveyAnswerData } = useSelector((state) => state.surveyAnswer);
//   const userUniqueCode = localStorage.getItem("vUniqueCode");
//   // const { participantUniqueCode } = useSelector((state) => state.participant);
//   const participantUniqueCode = localStorage.getItem("participantUniqueCode");
//   // Save selected categories to local storage
//   React.useEffect(() => {
//     localStorage.setItem(
//       "selectedCategories",
//       JSON.stringify(selectedCategories)
//     );
//   }, [selectedCategories]);

//   // Load email from local storage
//   React.useEffect(() => {
//     const savedEmail = localStorage.getItem("email");
//     if (savedEmail) {
//       setEmail(savedEmail);
//     }
//   }, []);

//   React.useEffect(() => {
//     localStorage.setItem("pageNumber", pageNumber);
//   }, [pageNumber]);

//   //new code //
//   const renderQuestionContent = (item) => {
//     switch (item.eType) {
//       case "Table":
//         return renderTableQuestion(item);
//       case "Multi Select":
//         return renderMultiSelectQuestion(item);
//       case "Single Select":
//         return renderSingleSelectQuestion(item);
//       case "Dropdown":
//         return <div>Shital.....Dropdown</div>;
//       case "Description":
//         return renderDescriptionQuestion(item);
//       case "Range":
//         return renderRangeQuestion(item);
//       default:
//         return renderTextInputQuestion(item);
//     }
//   };

//   // For Showing Error Message//
//   const renderErrorMessages = (questionId, questioneType) => {
//     return (
//       errors[questionId] &&
//       Array.isArray(errors[questionId]) && (
//         <div>
//           {errors[questionId].map((error, index) => (
//             <div
//               key={index}
//               style={{ color: "red", marginBottom: "10px" }}
//               className="error-message"
//             >
//               {error}
//             </div>
//           ))}
//         </div>
//       )
//     );
//   };

//   const handleOptionSelection = (questionId, optionId) => {
//     setSelectedCategories({
//       ...selectedCategories,
//       [optionId]: {
//         ...selectedCategories[optionId],
//         selected: !selectedCategories[optionId]?.selected,
//       },
//     });
//   };

//   console.log("SelectedCategories------------------------>",selectedCategories)
//   // for eType = Table Question//
//   const renderTableQuestion = (item) => (
//     <>
//       <h2 className="qus-title">{item.vQuestion}</h2>
//       {item.categories.map((category) => (
//         <div className="category-list border-0 pt-0" key={category.iCategoryId}>
//           <div className="form-check">
//             <input
//               type="checkbox"
//               id={`specific-${category.iCategoryId}`}
//               className="form-check-input"
//               onChange={() =>
//                 handleCategorySelection(item.iQuestionId, category.iCategoryId)
//               }
//               checked={
//                 selectedCategories[category.iCategoryId]?.selected || false
//               }
//             />
//             <label
//               htmlFor={`specific-${category.iCategoryId}`}
//               className="category-title"
//             >
//               {category.vCategory}
//             </label>
//           </div>
//           {selectedCategories[category.iCategoryId]?.selected && (
//             <div className="sub-category-list">
//               <div className="row g-3">
//                 {category.subCategories.map((subCategory) => (
//                   <div
//                     className="col-md-6 col-lg-4"
//                     key={subCategory.iSubCategoryId}
//                   >
//                     <div className="form-check">
//                       <input
//                         type="checkbox"
//                         id={`sub-${category.iCategoryId}-${subCategory.iSubCategoryId}`}
//                         className="form-check-input"
//                         onChange={() =>
//                           handleSubCategorySelection(
//                             item.iQuestionId,
//                             category.iCategoryId,
//                             subCategory.iSubCategoryId
//                           )
//                         }
//                         checked={
//                           selectedCategories[category.iCategoryId]
//                             ?.subCategories?.[subCategory.iSubCategoryId] ||
//                           false
//                         }
//                       />
//                       <label
//                         htmlFor={`sub-${category.iCategoryId}-${subCategory.iSubCategoryId}`}
//                       >
//                         {subCategory.vSubCategory}
//                       </label>
//                     </div>
//                   </div>
//                 ))}
//               </div>
//             </div>
//           )}
//         </div>
//       ))}
//     </>
//   );

//   // for eType = Multi Select Question//
//   const renderMultiSelectQuestion = (item) => (
//     <>
//       <h2 className="qus-title">{item.vQuestion}</h2>
//       {item.options.map((option) => (
//         <div className="category-list border-0 pt-0" key={option.iOptionId}>
//           <div className="form-check">
//             <input
//               type="checkbox"
//               id={`specific-${option.iOptionId}`}
//               className="form-check-input"
//               onChange={() =>
//                 handleOptionSelection(item.iQuestionId, option.iOptionId)
//               }
//               checked={selectedCategories[option.iOptionId]?.selected || false}
//             />
//             <label
//               htmlFor={`specific-${option.iOptionId}`}
//               className="category-title"
//             >
//               {option.vOption}
//             </label>
//           </div>
//         </div>
//       ))}
//     </>
//   );

//   // for eType = single select //
//   const renderSingleSelectQuestion = (item) => (
//     <>
//       <h2 className="qus-title">{item.vQuestion}</h2>
//       {item.options.map((option) => (
//         <div className="category-list border-0 pt-0" key={option.iOptionId}>
//           <div className="form-check">
//             <input
//               type="radio"
//               id={`specific-${option.iOptionId}`}
//               name={`radio-${item.iQuestionId}`} // Name should be unique for each question
//               onChange={() =>
//                 handleOptionSelection(item.iQuestionId, option.iOptionId)
//               }
//               checked={selectedCategories[option.iOptionId]?.selected || false}
//             />
//             <label
//               htmlFor={`specific-${option.iOptionId}`}
//               className="category-title"
//             >
//               {option.vOption}
//             </label>
//           </div>
//         </div>
//       ))}
//     </>
//   );

//   // for eType = Description  Question//
//   const renderDescriptionQuestion = (item) => (
//     <>
//       <h2 className="qus-title">{item.vQuestion}</h2>
//       <div className="col-md-12">
//         <div className="input-box">
//           <textarea
//             type="text"
//             className="form-control"
//             rows={4}
//             cols={50}
//             placeholder="Type Answer Here..."
//             value={inputValues[item.iQuestionId] || ""}
//             onChange={(e) =>
//               handleInputChange(item.iQuestionId, e.target.value, item.eType)
//             }
//           ></textarea>
//         </div>
//       </div>
//     </>
//   );

//   // for eType = Range Question//
//   const renderRangeQuestion = (item) => (
//     <>
//       <h2 className="qus-title">{item.vQuestion}</h2>
//       <div>
//         <input
//           type="range"
//           id="volume"
//           name="volume"
//           min="0"
//           max="12"
//           value={inputValues[item.iQuestionId] || 0}
//           onChange={(e) =>
//             handleInputChange(item.iQuestionId, e.target.value, item.eType)
//           }
//         />
//       </div>
//     </>
//   );

//   // for eType = Text Question//
//   const renderTextInputQuestion = (item) => (
//     <>
//       <h2 className="qus-title">{item.vQuestion}</h2>
//       <div className="col-md-12">
//         <div className="input-box">
//           <input
//             type="text"
//             className="form-control"
//             placeholder="Type Answer Here..."
//             value={inputValues[item.iQuestionId] || ""}
//             onChange={(e) =>
//               handleInputChange(item.iQuestionId, e.target.value, item.eType)
//             }
//           />
//         </div>
//       </div>
//     </>
//   );

//   // Fetch survey questions on page load or when pageNumber changes
//   React.useEffect(() => {
//     const cleanedUniqueCode = vUniqueCode && vUniqueCode.replace(/^"|"$/g, "");
//     dispatch(
//       getSurveyQuestionList({
//         vUniqueCode: cleanedUniqueCode,
//         iPageNo: pageNumber,
//         // vPage: 1,
//         // limit:2
//       })
//     );
//   }, [dispatch, vUniqueCode, pageNumber]);

//   const handleEmailChange = (event) => {
//     const emailValue = event.target.value.trim();
//     setEmail(emailValue);
//     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

//     if (!emailRegex.test(emailValue)) {
//       setEmailError("Invalid Email format.");
//     } else {
//       setEmailError("");
//       localStorage.setItem("email", emailValue);
//     }
//   };

//   const handleCategorySelection = (questionId, categoryId) => {
//     setSelectedCategories((prevSelected) => {
//       // Determine if the category is currently selected
//       const isCategoryCurrentlySelected = prevSelected[categoryId]?.selected;

//       // If unselecting the category, reset subcategories as well
//       const updatedCategories = {
//         ...prevSelected,
//         [categoryId]: {
//           ...prevSelected[categoryId],
//           selected: !isCategoryCurrentlySelected,
//           subCategories: isCategoryCurrentlySelected
//             ? {} // Clear subcategories if the category is unselected
//             : prevSelected[categoryId]?.subCategories || {},
//         },
//       };

//       const isAnyCategorySelected = Object.values(updatedCategories).some(
//         (category) => category.selected
//       );

//       // Handle errors for the specific question
//       setErrors((prevErrors) => ({
//         ...prevErrors,
//         [questionId]: {
//           ...prevErrors[questionId],
//           general: isAnyCategorySelected
//             ? ""
//             : "Please select at least one category.",
//         },
//       }));

//       return updatedCategories;
//     });
//   };

//   const handleSubCategorySelection = (
//     questionId,
//     categoryId,
//     subCategoryId
//   ) => {
//     setSelectedCategories((prevSelected) => {
//       const updatedSubCategories = {
//         ...prevSelected[categoryId],
//         subCategories: {
//           ...prevSelected[categoryId]?.subCategories,
//           [subCategoryId]:
//             !prevSelected[categoryId]?.subCategories?.[subCategoryId],
//         },
//       };

//       const isSubCategorySelected = Object.values(
//         updatedSubCategories.subCategories
//       ).some((isSelected) => isSelected);

//       const updatedCategories = {
//         ...prevSelected,
//         [categoryId]: {
//           ...updatedSubCategories,
//           isValid: isSubCategorySelected,
//         },
//       };

//       const isAllSelectedCategoriesValid = Object.values(
//         updatedCategories
//       ).every((category) => !category.selected || category.isValid);

//       // Update errors per question
//       setErrors((prevErrors) => ({
//         ...prevErrors,
//         [questionId]: {
//           ...prevErrors[questionId],
//           general: isAllSelectedCategoriesValid
//             ? ""
//             : "Please select at least one subcategory for each selected category.",
//         },
//       }));

//       return updatedCategories;
//     });
//   };

//   const handleNextQuestion = async () => {
//     // Validate all questions on the current page
//     let isValid = true;
//     let newErrors = {};
//     let iOptionId = [];

//     surveyQuestionData?.data?.Data.forEach((question) => {
//       let questionErrors = [];
//       let questionOptionArray = [];

//       // Check if any category is selected for the question
//       const isAnyCategorySelected = question?.categories?.some(
//         (category) => selectedCategories[category.iCategoryId]?.selected
//       );

//       if (!isAnyCategorySelected) {
//         isValid = false;
//         questionErrors.push("Please select at least one category.");
//       }

//       // Check if all selected categories have at least one subcategory selected
//       const isAllSelectedCategoriesValid = question?.categories?.every(
//         (category) => {
//           if (selectedCategories[category.iCategoryId]?.selected) {
//             const hasSelectedSubCategory = Object.values(
//               selectedCategories[category.iCategoryId]?.subCategories || {}
//             ).some((isSelected) => isSelected);
//             return hasSelectedSubCategory;
//           }
//           return true;
//         }
//       );

//       if (!isAllSelectedCategoriesValid) {
//         isValid = false;
//         questionErrors.push(
//           "Please select at least one subcategory for each selected category."
//         );
//       }

//       // Add question-specific errors to the `newErrors` object
//       if (questionErrors.length > 0) {
//         newErrors[question.iQuestionId] = questionErrors;
//       }

//       // Prepare the payload for the question
//       question?.categories?.forEach((category) => {
//         if (selectedCategories[category.iCategoryId]?.selected) {
//           const selectedSubCategoryIds = Object.keys(
//             selectedCategories[category.iCategoryId]?.subCategories || {}
//           ).filter(
//             (subCatId) =>
//               selectedCategories[category.iCategoryId].subCategories[subCatId]
//           );

//           if (selectedSubCategoryIds.length > 0) {
//             questionOptionArray.push({
//               [category.iCategoryId]: selectedSubCategoryIds.map(Number),
//             });
//           }
//         }
//       });

//       // Only add to `iOptionId` if there are selected categories and subcategories
//       if (questionOptionArray.length > 0) {
//         iOptionId.push(questionOptionArray);
//       }
//     });

//     // If there are any errors, update the state
//     if (!isValid) {
//       setErrors(newErrors);
//       return;
//     }

//     // Clear all errors if validation passes
//     setErrors({});

//     // Prepare the final payload
//     const payload = {
//       vUniqueCode:
//         participantUniqueCode || userUniqueCode || participantvUniqueCodeNew,
//       iQuestionId: surveyQuestionData?.data?.Data.map((question) => ({
//         [question.iQuestionId]: question.eType,
//       })),
//       iOptionId: iOptionId, // This now has the correct structure
//       iSurveyId: participantSurveyId || SurveyId,
//       iPageNo: pageNumber,
//     };

//     try {
//       setButtonLoading(true);
//       const response = await fetch(
//         `${process.env.REACT_APP_API_URL}/api/question/answer`,
//         {
//           method: "POST",
//           headers: { "Content-Type": "application/json" },
//           body: JSON.stringify(payload),
//         }
//       );

//       const responseData = await response.json();
//       setButtonLoading(false);

//       if (responseData && responseData.status === 200) {
//         if (responseData.data?.survey_status === "Completed") {
//           navigate("/survey/thank-you");
//           clearLocalStorage();
//         } else {
//           setPageNumber(responseData.data?.NextPageNo);
//           window.scrollTo(0, 0);
//         }
//       }
//     } catch (error) {
//       setButtonLoading(false);
//       // Handle error
//     }
//   };

//   const handleBackQuestion = () => {
//     if (pageNumber > 1) {
//       setPageNumber((prevPageNumber) => prevPageNumber - 1);
//       window.scrollTo(0, 0);
//     } else {
//       navigate("/general-info", {
//         state: { vUniqueCode, SurveyId, participantvUniqueCodeNew },
//       });
//     }
//   };

//   // Helper function to clear local storage
//   const clearLocalStorage = () => {
//     localStorage.removeItem("pageNumber");
//     localStorage.removeItem("selectedCategories");
//     localStorage.removeItem("education");
//     localStorage.removeItem("researchOption");
//     localStorage.removeItem("Other");
//     localStorage.removeItem("agree");
//     localStorage.removeItem("disagree");
//     localStorage.removeItem("participantvUniqueCodeNew");
//     localStorage.removeItem("participantUniqueCode");
//     localStorage.removeItem("email");
//   };

//   const TotalPage = surveyQuestionData?.data?.totalPages;

//   // Handle changes in text inputs or other form inputs
//   const handleInputChange = (questionId, value, questioneType) => {
//     if (questioneType === "Text" && value === "") {
//       setErrors("Answer is Requred");
//     }
//     setInputValues({
//       ...inputValues,
//       [questionId]: value,
//     });
//   };

//   return (
//     // <>
//     //   <Header />
//     //   <div className="questions-wrp survey-category survey-page">
//     //     {loading ? (
//     //       <>
//     //         <div
//     //           style={{
//     //             display: "flex",
//     //             justifyContent: "center",
//     //             alignItems: "center",
//     //             marginTop: "50px",
//     //             marginBottom: "50px",
//     //           }}
//     //         >
//     //           {loading && (
//     //             <img
//     //               src="/assets/gif/350.gif"
//     //               className="loading-gif"
//     //               alt="Loading..."
//     //             />
//     //           )}
//     //         </div>
//     //       </>
//     //     ) : (
//     //       <>
//     //         {surveyQuestionData &&
//     //         surveyQuestionData?.code === 200 &&
//     //         surveyQuestionData?.data?.Data &&
//     //         surveyQuestionData?.data?.Data.length > 0 ? (
//     //           <>
//     //             <div className="container">
//     //               {surveyQuestionData?.data?.Data.map((item, itemIndex) => (
//     //                 <div className="main-category" key={itemIndex}>
//     //                   {item && item.eType === "Table" && (
//     //                     <>
//     //                       <h2 className="qus-title">{item.vQuestion}</h2>
//     //                       {item?.categories?.map((category) => (
//     //                         <div
//     //                           className="category-list border-0 pt-0"
//     //                           key={category.iCategoryId}
//     //                         >
//     //                           <div className="form-check">
//     //                             <input
//     //                               type="checkbox"
//     //                               id={`specific-${category.iCategoryId}`}
//     //                               className="form-check-input"
//     //                               onChange={() =>
//     //                                 handleCategorySelection(
//     //                                   item.iQuestionId,
//     //                                   category.iCategoryId
//     //                                 )
//     //                               }
//     //                               checked={
//     //                                 selectedCategories[category.iCategoryId]
//     //                                   ?.selected || false
//     //                               }
//     //                             />
//     //                             <label
//     //                               htmlFor={`specific-${category.iCategoryId}`}
//     //                               className="category-title"
//     //                             >
//     //                               {category.vCategory}
//     //                             </label>
//     //                           </div>

//     //                           {selectedCategories[category.iCategoryId]
//     //                             ?.selected && (
//     //                             <div className="sub-category-list">
//     //                               <div className="row g-3">
//     //                                 {category?.subCategories?.map(
//     //                                   (subCategory) => (
//     //                                     <div
//     //                                       className="col-md-6 col-lg-4"
//     //                                       key={subCategory.iSubCategoryId}
//     //                                     >
//     //                                       <div className="form-check">
//     //                                         <input
//     //                                           type="checkbox"
//     //                                           id={`sub-${category.iCategoryId}-${subCategory.iSubCategoryId}`}
//     //                                           className="form-check-input"
//     //                                           onChange={() =>
//     //                                             handleSubCategorySelection(
//     //                                               item.iQuestionId,
//     //                                               category.iCategoryId,
//     //                                               subCategory.iSubCategoryId
//     //                                             )
//     //                                           }
//     //                                           checked={
//     //                                             selectedCategories[
//     //                                               category.iCategoryId
//     //                                             ]?.subCategories?.[
//     //                                               subCategory.iSubCategoryId
//     //                                             ] || false
//     //                                           }
//     //                                         />
//     //                                         <label
//     //                                           htmlFor={`sub-${category.iCategoryId}-${subCategory.iSubCategoryId}`}
//     //                                         >
//     //                                           {subCategory.vSubCategory}
//     //                                         </label>
//     //                                       </div>
//     //                                     </div>
//     //                                   )
//     //                                 )}
//     //                               </div>
//     //                             </div>
//     //                           )}
//     //                         </div>
//     //                       ))}
//     //                       {/* Display Errors for Each Question */}
//     //                       {errors[item.iQuestionId] &&
//     //                         Array.isArray(errors[item.iQuestionId]) && (
//     //                           <div>
//     //                             {errors[item.iQuestionId].map(
//     //                               (error, index) => (
//     //                                 <div
//     //                                   key={index}
//     //                                   style={{
//     //                                     color: "red",
//     //                                     marginBottom: "10px",
//     //                                   }}
//     //                                   className="error-message"
//     //                                 >
//     //                                   {error}
//     //                                 </div>
//     //                               )
//     //                             )}
//     //                           </div>
//     //                         )}
//     //                     </>
//     //                   )}

//     //                   {/* Handle Multi Select Type */}
//     //                   {item?.eType === "Multi Select" && (
//     //                     <>
//     //                       <h2 className="qus-title">{item.vQuestion}</h2>
//     //                       {item?.options?.map((option) => (
//     //                         <div
//     //                           className="category-list border-0 pt-0"
//     //                           key={option.iOptionId}
//     //                         >
//     //                           <div className="form-check">
//     //                             <input
//     //                               type="checkbox"
//     //                               id={`specific-${option.iOptionId}`}
//     //                               className="form-check-input"
//     //                               onChange={() =>
//     //                                 handleOptionSelection(
//     //                                   item.iQuestionId,
//     //                                   option.iOptionId
//     //                                 )
//     //                               }
//     //                               checked={
//     //                                 selectedCategories[option.iOptionId]
//     //                                   ?.selected || false
//     //                               }
//     //                             />
//     //                             <label
//     //                               htmlFor={`specific-${option.iOptionId}`}
//     //                               className="category-title"
//     //                             >
//     //                               {option.vOption}
//     //                             </label>
//     //                           </div>
//     //                         </div>
//     //                       ))}
//     //                     </>
//     //                   )}

//     //                   {/* Handle Single Select Type */}
//     //                   {item?.eType === "Single Select" && (
//     //                     <>
//     //                       <h2 className="qus-title">{item.vQuestion}</h2>
//     //                       {item?.options?.map((option) => (
//     //                         <div
//     //                           className="category-list border-0 pt-0"
//     //                           key={option.iOptionId}
//     //                         >
//     //                           <div className="form-check">
//     //                             <input
//     //                               type="radio"
//     //                               id={`specific-${option.iOptionId}`}
//     //                               className="form-check-input"
//     //                               name={`question-${item.iQuestionId}`}
//     //                               onChange={() =>
//     //                                 handleOptionSelection(
//     //                                   item.iQuestionId,
//     //                                   option.iOptionId
//     //                                 )
//     //                               }
//     //                               checked={
//     //                                 selectedCategories[option.iOptionId]
//     //                                   ?.selected || false
//     //                               }
//     //                             />
//     //                             <label
//     //                               htmlFor={`specific-${option.iOptionId}`}
//     //                               className="category-title"
//     //                             >
//     //                               {option.vOption}
//     //                             </label>
//     //                           </div>
//     //                         </div>
//     //                       ))}
//     //                     </>
//     //                   )}

//     //                   {/* Handle Dropdown Type */}
//     //                   {item?.eType === "Dropdown" && (
//     //                     <>
//     //                       <h2 className="qus-title">{item.vQuestion}</h2>
//     //                       <select
//     //                         className="form-control"
//     //                         // onChange={(e) =>
//     //                         //   handleDropdownSelection(
//     //                         //     item.iQuestionId,
//     //                         //     e.target.value
//     //                         //   )
//     //                         // }
//     //                         value={
//     //                           selectedCategories[item.iQuestionId]?.selected ||
//     //                           ""
//     //                         }
//     //                       >
//     //                         {item?.options?.map((option) => (
//     //                           <option
//     //                             key={option.iOptionId}
//     //                             value={option.iOptionId}
//     //                           >
//     //                             {option.vOption}
//     //                           </option>
//     //                         ))}
//     //                       </select>
//     //                     </>
//     //                   )}

//     //                   {/* Handle Textarea Type */}
//     //                   {item?.eType === "Description" && (
//     //                     <>
//     //                       <h2 className="qus-title">{item.vQuestion}</h2>
//     //                       <div className="col-md-12">
//     //                         <div className="input-box">
//     //                           <textarea
//     //                             className="form-control"
//     //                             rows={4}
//     //                             cols={50}
//     //                             placeholder="Type Answer Here..."
//     //                             // onChange={(e) =>
//     //                             //   handleTextAreaChange(
//     //                             //     item.iQuestionId,
//     //                             //     e.target.value
//     //                             //   )
//     //                             // }
//     //                           ></textarea>
//     //                         </div>
//     //                       </div>
//     //                     </>
//     //                   )}
//     //                   {item?.eType === "Text" && (
//     //                     <>
//     //                       <h2 className="qus-title">{item.vQuestion}</h2>
//     //                       <div className="col-md-12">
//     //                         <div className="input-box">
//     //                           <input
//     //                             type="text"
//     //                             className="form-control"
//     //                             placeholder="Enter Your Answer"
//     //                             value={inputValues[item.iQuestionId] || ""}
//     //                             onChange={(e) =>
//     //                               handleInputChange(
//     //                                 item.iQuestionId,
//     //                                 e.target.value
//     //                               )
//     //                             }
//     //                           />
//     //                         </div>
//     //                       </div>
//     //                     </>
//     //                   )}

//     //                   {/* Handle Range Type */}
//     //                   {item?.eType === "Range" && (
//     //                     <>
//     //                       <h2 className="qus-title">{item.vQuestion}</h2>
//     //                       <div>
//     //                         <input
//     //                           type="range"
//     //                           id="volume"
//     //                           name="volume"
//     //                           min="0"
//     //                           max="12"
//     //                           // onChange={(e) =>
//     //                           //   handleRangeChange(
//     //                           //     item.iQuestionId,
//     //                           //     e.target.value
//     //                           //   )
//     //                           // }
//     //                         />
//     //                       </div>
//     //                     </>
//     //                   )}
//     //                 </div>
//     //               ))}

//     //               <div>
//     //                 {pageNumber === 2 && (
//     //                   <div>
//     //                     <h3>
//     //                       If you are interested in gaining access to updates on
//     //                       this research, please enter your contact information
//     //                       below. We will contact you concerning publication of
//     //                       the results:
//     //                     </h3>
//     //                     <div className="row">
//     //                       <div className="col-md-6 col-lg-4">
//     //                         <div className="form-group">
//     //                           <input
//     //                             type="text"
//     //                             className="form-control"
//     //                             placeholder="Email Address"
//     //                             value={email}
//     //                             onChange={handleEmailChange}
//     //                           />
//     //                           {emailError && (
//     //                             <div
//     //                               className="error-msg"
//     //                               style={{
//     //                                 color: "red",
//     //                                 marginLeft: "5px",
//     //                                 fontSize: "14px",
//     //                               }}
//     //                             >
//     //                               {" "}
//     //                               {emailError}
//     //                             </div>
//     //                           )}
//     //                         </div>
//     //                       </div>
//     //                     </div>
//     //                   </div>
//     //                 )}
//     //               </div>
//     //               <div>
//     //                 {buttonLoading ? (
//     //                   <button
//     //                     className="next-page login-btn"
//     //                     type="button"
//     //                     disabled
//     //                   >
//     //                     <span
//     //                       className="spinner-border spinner-border-sm me-1"
//     //                       role="status"
//     //                       aria-hidden="true"
//     //                     ></span>
//     //                     {pageNumber === TotalPage
//     //                       ? "Submitting..."
//     //                       : "Loading..."}
//     //                   </button>
//     //                 ) : (
//     //                   <button
//     //                     className="next-page login-btn"
//     //                     onClick={handleNextQuestion}
//     //                   >
//     //                     {pageNumber === TotalPage ? "Submit" : "Next"}
//     //                   </button>
//     //                 )}

//     //                 {/* Conditionally show the Back button */}
//     //                 {/* {surveyQuestionData.data.Data.length - 1 && (
//     //                   <button
//     //                     style={{ marginLeft: "20px" }}
//     //                     className="next-page back-btn"
//     //                     onClick={handleBackQuestion}
//     //                   >
//     //                     Back
//     //                   </button>
//     //                 )} */}
//     //                 {pageNumber === TotalPage ? (
//     //                   ""
//     //                 ) : (
//     //                   <button
//     //                     className="next-page back-btn"
//     //                     type="submit"
//     //                     style={{ marginLeft: "20px" }}
//     //                     onClick={handleBackQuestion}
//     //                   >
//     //                     Back
//     //                   </button>
//     //                 )}
//     //               </div>
//     //             </div>
//     //           </>
//     //         ) : (
//     //           <>
//     //             <div
//     //               className="no-data"
//     //               style={{
//     //                 display: "flex",
//     //                 justifyContent: "center",
//     //                 alignItems: "center",
//     //                 padding: "10px 0",
//     //                 marginTop: "50px",
//     //               }}
//     //             >
//     //               No Data Found
//     //             </div>
//     //           </>
//     //         )}
//     //       </>
//     //     )}
//     //   </div>
//     // </>

//     <>
//       <Header />
//       <div className="questions-wrp survey-category survey-page">
//         {loading ? (
//           <div
//             style={{
//               display: "flex",
//               justifyContent: "center",
//               alignItems: "center",
//               marginTop: "50px",
//               marginBottom: "50px",
//             }}
//           >
//             <img
//               src="/assets/gif/350.gif"
//               className="loading-gif"
//               alt="Loading..."
//             />
//           </div>
//         ) : (
//           <>
//             {surveyQuestionData?.code === 200 &&
//             surveyQuestionData?.data?.Data?.length > 0 ? (
//               <div className="container">
//                 {surveyQuestionData.data.Data.map((item, itemIndex) => (
//                   <div className="main-category" key={itemIndex}>
//                     {renderQuestionContent(item)}
//                     {renderErrorMessages(item.iQuestionId, item.eType)}
//                   </div>
//                 ))}
//                 {pageNumber === 2 && (
//                   <div>
//                     <h3>
//                       If you are interested in gaining access to updates on this
//                       research, please enter your contact information below. We
//                       will contact you concerning publication of the results:
//                     </h3>
//                     <div className="row">
//                       <div className="col-md-6 col-lg-4">
//                         <div className="form-group">
//                           <input
//                             type="text"
//                             className="form-control"
//                             placeholder="Email Address"
//                             value={email}
//                             onChange={handleEmailChange}
//                           />
//                           {emailError && (
//                             <div
//                               className="error-msg"
//                               style={{
//                                 color: "red",
//                                 marginLeft: "5px",
//                                 fontSize: "14px",
//                               }}
//                             >
//                               {emailError}
//                             </div>
//                           )}
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 )}
//                 <div>
//                   {buttonLoading ? (
//                     <button
//                       className="next-page login-btn"
//                       type="button"
//                       disabled
//                     >
//                       <span
//                         className="spinner-border spinner-border-sm me-1"
//                         role="status"
//                         aria-hidden="true"
//                       ></span>
//                       {pageNumber === TotalPage
//                         ? "Submitting..."
//                         : "Loading..."}
//                     </button>
//                   ) : (
//                     <button
//                       className="next-page login-btn"
//                       onClick={handleNextQuestion}
//                     >
//                       {pageNumber === TotalPage ? "Submit" : "Next"}
//                     </button>
//                   )}
//                   {pageNumber === TotalPage ? (
//                     ""
//                   ) : (
//                     <button
//                       className="next-page back-btn"
//                       style={{ marginLeft: "20px" }}
//                       onClick={handleBackQuestion}
//                     >
//                       Back
//                     </button>
//                   )}
//                 </div>
//               </div>
//             ) : (
//               <div
//                 className="no-data"
//                 style={{
//                   display: "flex",
//                   justifyContent: "center",
//                   alignItems: "center",
//                   padding: "10px 0",
//                   marginTop: "50px",
//                 }}
//               >
//                 No Data Found
//               </div>
//             )}
//           </>
//         )}
//       </div>
//     </>
//   );
// };

// export default QuestionThree;
