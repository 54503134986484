import React from "react";
import Header from "../components/Layouts/Header";
import Invitation from "../components/Survey/Invitation";
import { useNavigate } from "react-router-dom";

const InvitationPage = () => {
  const navigate = useNavigate();
  const vUniqueCode = localStorage.getItem("vUniqueCode");
  React.useEffect(() => {
    if (!vUniqueCode) {
      navigate("/");
    }
  }, [vUniqueCode, navigate]);
  return (
    <React.Fragment>
      <div>
        <Header />
        <Invitation />
      </div>
    </React.Fragment>
  );
};

export default InvitationPage;
