export const SURVEY_LIST_REQUEST = "SURVEY_LIST_REQUEST";
export const SURVEY_LIST_SUCCESS = "SURVEY_LIST_SUCCESS";
export const SURVEY_LIST_FAILURE = "SURVEY_LIST_FAILURE";

export const INVITATION_LIST_REQUEST = "INVITATION_LIST_REQUEST";
export const INVITATION_LIST_SUCCESS = "INVITATION_LIST_SUCCESS";
export const INVITATION_LIST_FAILURE = "INVITATION_LIST_FAILURE";

export const TOTAL_SURVEY_LIST_REQUEST = "TOTAL_SURVEY_LIST_REQUEST";
export const TOTAL_SURVEY_LIST_SUCCESS = "TOTAL_SURVEY_LIST_SUCCESS";
export const TOTAL_SURVEY_LIST_FAILURE = "TOTAL_SURVEY_LIST_FAILURE";

export const TOTAL_INVITATION_LIST_REQUEST = "TOTAL_INVITATION_LIST_REQUEST";
export const TOTAL_INVITATION_LIST_SUCCESS = "TOTAL_INVITATION_LIST_SUCCESS";
export const TOTAL_INVITATION_LIST_FAILURE = "TOTAL_INVITATION_LIST_FAILURE";

export const TOTAL_ORGANISATION_COUNT_LIST_REQUEST =
  "TOTAL_ORGANISATION_COUNT_REQUEST";
export const TOTAL_ORGANISATION_COUNT_LIST_SUCCESS =
  "TOTAL_ORGANISATION_COUNT_SUCCESS";
export const TOTAL_ORGANISATION_COUNT_LIST_FAILURE =
  "TOTAL_ORGANISATION_COUNT_FAILURE";

export const TOTAL_RESEARCHER_COUNT_LIST_REQUEST =
  "TOTAL_RESEARCHER_COUNT_REQUEST";
export const TOTAL_RESEARCHER_COUNT_LIST_SUCCESS =
  "TOTAL_RESEARCHER_COUNT_SUCCESS";
export const TOTAL_RESEARCHER_COUNT_LIST_FAILURE =
  "TOTAL_RESEARCHER_COUNT_FAILURE";

export const SURVEY_PUBLIC_INVITE_LIST_REQUEST =
  "SURVEY_PUBLIC_INVITE_LIST_REQUEST";
export const SURVEY_PUBLIC_INVITE_LIST_SUCCESS =
  "SURVEY_PUBLIC_INVITE_LIST_SUCCESS";
export const SURVEY_PUBLIC_INVITE_LIST_FAILURE =
  "SURVEY_PUBLIC_INVITE_LIST_FAILURE";

export const PARTICIPANT_ENTRY_LIST_REQUEST = "PARTICIPANT_ENTRY_LIST_REQUEST";
export const PARTICIPANT_ENTRY_LIST_SUCCESS = "PARTICIPANT_ENTRY_LIST_SUCCESS";
export const PARTICIPANT_ENTRY_LIST_FAILURE = "PARTICIPANT_ENTRY_LIST_FAILURE";

export const PARTICIPANT_SKIP_ENTRY_LIST_REQUEST =
  "PARTICIPANT_SKIP_ENTRY_LIST_REQUEST";
export const PARTICIPANT_SKIP_ENTRY_LIST_SUCCESS =
  "PARTICIPANT_SKIP_ENTRY_LIST_SUCCESS";
export const PARTICIPANT_SKIP_ENTRY_LIST_FAILURE =
  "PARTICIPANT_SKIP_ENTRY_LIST_FAILURE";

export const STORE_SURVEY_FORM_RESET = "STORE_SURVEY_FORM_RESET";

export const SURVEY_QUESTION_LIST_REQUEST = "SURVEY_QUESTION_LIST_REQUEST";
export const SURVEY_QUESTION_LIST_SUCCESS = "SURVEY_QUESTION_LIST_SUCCESS";
export const SURVEY_QUESTION_LIST_FAILURE = "SURVEY_QUESTION_LIST_FAILURE";

export const SURVEY_ANSWER_LIST_REQUEST = "SURVEY_ANSWER_LIST_REQUEST";
export const SURVEY_ANSWER_LIST_SUCCESS = "SURVEY_ANSWER_LIST_SUCCESS";
export const SURVEY_ANSWER_LIST_FAILURE = "SURVEY_ANSWER_LIST_FAILURE";


export const SET_UNIQUE_CODE = 'SET_UNIQUE_CODE';
export const CLEAR_UNIQUE_CODE = 'CLEAR_UNIQUE_CODE';


export const CHECK_INVITATION_TYPE_LIST_REQUEST = "CHECK_INVITATION_TYPE_LIST_REQUEST";
export const CHECK_INVITATION_TYPE_LIST_SUCCESS = "CHECK_INVITATION_TYPE_LIST_SUCCESS";
export const CHECK_INVITATION_TYPE_LIST_FAILURE = "CHECK_INVITATION_TYPE_LIST_FAILURE";