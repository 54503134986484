import {
  CHECK_INVITATION_TYPE_LIST_FAILURE,
  CHECK_INVITATION_TYPE_LIST_REQUEST,
  CHECK_INVITATION_TYPE_LIST_SUCCESS,
  CLEAR_UNIQUE_CODE,
  INVITATION_LIST_FAILURE,
  INVITATION_LIST_REQUEST,
  INVITATION_LIST_SUCCESS,
  PARTICIPANT_ENTRY_LIST_FAILURE,
  PARTICIPANT_ENTRY_LIST_REQUEST,
  PARTICIPANT_ENTRY_LIST_SUCCESS,
  PARTICIPANT_SKIP_ENTRY_LIST_FAILURE,
  PARTICIPANT_SKIP_ENTRY_LIST_REQUEST,
  PARTICIPANT_SKIP_ENTRY_LIST_SUCCESS,
  SET_UNIQUE_CODE,
  STORE_SURVEY_FORM_RESET,
  SURVEY_ANSWER_LIST_FAILURE,
  SURVEY_ANSWER_LIST_REQUEST,
  SURVEY_ANSWER_LIST_SUCCESS,
  SURVEY_LIST_FAILURE,
  SURVEY_LIST_REQUEST,
  SURVEY_LIST_SUCCESS,
  SURVEY_PUBLIC_INVITE_LIST_FAILURE,
  SURVEY_PUBLIC_INVITE_LIST_REQUEST,
  SURVEY_PUBLIC_INVITE_LIST_SUCCESS,
  SURVEY_QUESTION_LIST_FAILURE,
  SURVEY_QUESTION_LIST_REQUEST,
  SURVEY_QUESTION_LIST_SUCCESS,
  TOTAL_INVITATION_LIST_FAILURE,
  TOTAL_INVITATION_LIST_REQUEST,
  TOTAL_INVITATION_LIST_SUCCESS,
  TOTAL_ORGANISATION_COUNT_LIST_FAILURE,
  TOTAL_ORGANISATION_COUNT_LIST_REQUEST,
  TOTAL_ORGANISATION_COUNT_LIST_SUCCESS,
  TOTAL_RESEARCHER_COUNT_LIST_FAILURE,
  TOTAL_RESEARCHER_COUNT_LIST_REQUEST,
  TOTAL_RESEARCHER_COUNT_LIST_SUCCESS,
  TOTAL_SURVEY_LIST_FAILURE,
  TOTAL_SURVEY_LIST_REQUEST,
  TOTAL_SURVEY_LIST_SUCCESS,
} from "../Constatnts/SurveyConstant";

export function surveyReducer(state = {}, action) {
  switch (action.type) {
    case SURVEY_LIST_REQUEST:
      return {
        loading: true,
      };
    case SURVEY_LIST_SUCCESS:
      return {
        loading: false,
        surveyListData: action.payload,
      };
    case SURVEY_LIST_FAILURE:
      return {
        loading: false,
      };
    default:
      return state;
  }
}

export function invitationReducer(state = {}, action) {
  switch (action.type) {
    case INVITATION_LIST_REQUEST:
      return {
        loading: true,
      };
    case INVITATION_LIST_SUCCESS:
      return {
        loading: false,
        invitationListData: action.payload,
      };
    case INVITATION_LIST_FAILURE:
      return {
        loading: false,
      };
    default:
      return state;
  }
}

export function totalSurveyCountReducer(state = {}, action) {
  switch (action.type) {
    case TOTAL_SURVEY_LIST_REQUEST:
      return {
        loading: true,
      };
    case TOTAL_SURVEY_LIST_SUCCESS:
      return {
        loading: false,
        totalSurveyCountListData: action.payload,
      };
    case TOTAL_SURVEY_LIST_FAILURE:
      return {
        loading: false,
      };
    default:
      return state;
  }
}

export function totalInvitationCountReducer(state = {}, action) {
  switch (action.type) {
    case TOTAL_INVITATION_LIST_REQUEST:
      return {
        loading: true,
      };
    case TOTAL_INVITATION_LIST_SUCCESS:
      return {
        loading: false,
        totalInvitationCountListData: action.payload,
      };
    case TOTAL_INVITATION_LIST_FAILURE:
      return {
        loading: false,
      };
    default:
      return state;
  }
}

export function totalOrganisationCountReducer(state = {}, action) {
  switch (action.type) {
    case TOTAL_ORGANISATION_COUNT_LIST_REQUEST:
      return {
        loading: true,
      };
    case TOTAL_ORGANISATION_COUNT_LIST_SUCCESS:
      return {
        loading: false,
        totalOrganisationCountListData: action.payload,
      };
    case TOTAL_ORGANISATION_COUNT_LIST_FAILURE:
      return {
        loading: false,
      };
    default:
      return state;
  }
}

export function totalReasercherCountReducer(state = {}, action) {
  switch (action.type) {
    case TOTAL_RESEARCHER_COUNT_LIST_REQUEST:
      return {
        loading: true,
      };
    case TOTAL_RESEARCHER_COUNT_LIST_SUCCESS:
      return {
        loading: false,
        totalResearcherCountListData: action.payload,
      };
    case TOTAL_RESEARCHER_COUNT_LIST_FAILURE:
      return {
        loading: false,
      };
    default:
      return state;
  }
}

export function SurveyPublicInviteReducer(state = {}, action) {
  switch (action.type) {
    case SURVEY_PUBLIC_INVITE_LIST_REQUEST:
      return {
        loading: true,
      };
    case SURVEY_PUBLIC_INVITE_LIST_SUCCESS:
      return {
        loading: false,
        surveyPublicInviteData: action.payload,
      };
    case SURVEY_PUBLIC_INVITE_LIST_FAILURE:
      return {
        loading: false,
      };
    default:
      return state;
  }
}

export function participantEnrtyReducer(state = {}, action) {
  switch (action.type) {
    case PARTICIPANT_ENTRY_LIST_REQUEST:
      return {
        loading: true,
      };
    case PARTICIPANT_ENTRY_LIST_SUCCESS:
      return {
        loading: false,
        participantEntryData: action.payload,
      };
    case PARTICIPANT_ENTRY_LIST_FAILURE:
      return {
        loading: false,
      };
    case STORE_SURVEY_FORM_RESET:
      return {
        loading: false,
        participantEntryData: {},
      };
    default:
      return state;
  }
}

export function participantSkipEnrtyReducer(state = {}, action) {
  switch (action.type) {
    case PARTICIPANT_SKIP_ENTRY_LIST_REQUEST:
      return {
        loading: true,
      };
    case PARTICIPANT_SKIP_ENTRY_LIST_SUCCESS:
      return {
        loading: false,
        participantSkipEntryData: action.payload,
      };
    case PARTICIPANT_SKIP_ENTRY_LIST_FAILURE:
      return {
        loading: false,
      };
    default:
      return state;
  }
}

export function surveyQuestionReducer(state = {}, action) {
  switch (action.type) {
    case SURVEY_QUESTION_LIST_REQUEST:
      return {
        loading: true,
      };
    case SURVEY_QUESTION_LIST_SUCCESS:
      return {
        loading: false,
        surveyQuestionData: action.payload,
      };
    case SURVEY_QUESTION_LIST_FAILURE:
      return {
        loading: false,
      };
    default:
      return state;
  }
}

export function surveyAnswerReducer(state = {}, action) {
  switch (action.type) {
    case SURVEY_ANSWER_LIST_REQUEST:
      return {
        loading: true,
      };
    case SURVEY_ANSWER_LIST_SUCCESS:
      return {
        loading: false,
        surveyAnswerData: action.payload,
      };
    case SURVEY_ANSWER_LIST_FAILURE:
      return {
        loading: false,
      };
    default:
      return state;
  }
}

export function participantReducer(state = {}, action) {
  switch (action.type) {
    case SET_UNIQUE_CODE:
      return {
        ...state,
        participantUniqueCode: action.payload,
      };
    case CLEAR_UNIQUE_CODE:
      return {
        ...state,
        participantUniqueCode: null,
      };
    default:
      return state;
  }
}

export function checkInvitationTypeReducer(state = {}, action) {
  switch (action.type) {
    case CHECK_INVITATION_TYPE_LIST_REQUEST:
      return {
        loading: true,
      };
    case CHECK_INVITATION_TYPE_LIST_SUCCESS:
      return {
        loading: false,
        checkInvitationTypeData: action.payload,
      };
    case CHECK_INVITATION_TYPE_LIST_FAILURE:
      return {
        loading: false,
      };
    default:
      return state;
  }
}
